<template>
  <div class="select-bank-account-overview">
    <div class="select-bank-account-title">
      <div class="select-bank-account-header">
        {{ $t('addFundsToReserve.tabs.selectBankAccount') }}
      </div>
      <div class="select-bank-account-sub-header">
        {{ $t('addFundsToReserve.desc.selectBankAccount') }}
      </div>
    </div>
    <div class="divider"></div>
    <div class="bank-account-details-overview">
      <div :key="paymentInst.id" v-for="paymentInst in merchantPaymentInstruments" class="bank-account-details-list">
        <RadioOption
          :id="paymentInst.bank_account.name" :option-name="paymentInst.bank_account.name"
          :value="paymentInst.id === selectedPaymentInstrument.id"
          :select="() => chooseMerchantPaymentInstrument(paymentInst)"
          :option-instruction="getBankAccountDesc(paymentInst.bank_account)"
        />
      </div>
      <c-inline-error
        v-if="Boolean(getSelectBankAccountError)"
        :message="getSelectBankAccountError"
      />
      <c-button v-if="allowAddingBankAccount" variant="action" @click="() => openAddBankAccountModal = true">
        {{ '+' }}
        {{ $t('bankAccountForPayouts.action.addBankAccount') }}
      </c-button>
    </div>
    <div class="switch-to-next">
      <c-button variant="primary" @click="next">
        {{ $t('global.action.next') }}
      </c-button>
    </div>

    <ModalPopup class="add-bank-account-modal" :show="openAddBankAccountModal">
      <div slot="body">
        <AddBankAccount
          :on-dismiss="() => openAddBankAccountModal = false"
          @merchantPaymentInstrumentUpdated="(value) => $emit('merchantPaymentInstrumentUpdated', value)"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>

import RadioOption from "@/components/common/RadioOption.vue";
import ModalPopup from "@/components/common/ModalPopup.vue";
import AddBankAccount from "@/components/deposits/AddBankAccount.vue";
import {mapActions, mapGetters} from "vuex";
import {BankTransferType} from "@/app/utils/common/constants";
import {CountryCode} from "@/api/paymentApi";

export default {
  name: 'SelectBankAccount',
  components: {
    AddBankAccount,
    ModalPopup,
    RadioOption
  },
  props: {
    next: {
      type: Function,
      required: true
    },
    merchantPaymentInstruments: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      openAddBankAccountModal : false,
      selectedPaymentInstrument: {},
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
    ...mapGetters('merchantBalanceReserve', [
        'getSelectedPaymentInstrumentForDirectDebitFundTransfer',
        'isValidSelectBankAccount',
        'getSelectBankAccountError'
    ]),
    ...mapGetters('businessAddress', ['getCountry']),
    allowAddingBankAccount() {
      return this.getCountry !== CountryCode.GBR;
    }
  },
  mounted() {
    this.initialise();
  },
  methods: {
    ...mapActions('merchantBalanceReserve', ['setPaymentInstrumentForDirectDebitFundTransfer']),
     initialise() {
      if (this.isValidSelectBankAccount) {
        this.selectedPaymentInstrument = this.getSelectedPaymentInstrumentForDirectDebitFundTransfer;
      }
    },
    getBankAccountDesc(paymentInstrument) {
      let accLastFour = `xxxxxxxx${paymentInstrument.last_four} `;
      if (paymentInstrument.type === BankTransferType.ACH_DEBIT) {
        const achAccountType = paymentInstrument.account_type ?? '';
        let accType = achAccountType.charAt(0).toUpperCase()
            + achAccountType.slice(1).toLowerCase();
        accLastFour += `(${accType})`;
      }
      return accLastFour;
    },
    chooseMerchantPaymentInstrument(paymentInstrument) {
      this.selectedPaymentInstrument = paymentInstrument;
      this.setPaymentInstrumentForDirectDebitFundTransfer(paymentInstrument);
    }
  },
}
</script>

<style lang="scss">

.select-bank-account-overview {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;

  & .select-bank-account-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    & .select-bank-account-header {
      font-size: 24px;
      font-weight: $weight_bold;
    }

    & .select-bank-account-sub-header {
      font-size: 12px;
      font-weight: $weight_normal;
    }
  }

  & .divider {
    width: 100%;
    background: $neutral_200;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  & .bank-account-details-overview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    & .bank-account-details-list {
      width: 100%;

      & .checkbox-container {
        border-radius: 6px;
        border: 1px solid $neutral_100;
      }
    }

    & .add-bank-account-text {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $primary_600;
      font-weight: $weight_bold;
    }

    & .add-bank-account-text:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    & .c-button__label {
      padding: 0;
    }

  }

  & .switch-to-next {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 60px;
    max-width: 120px;

    & .c-button--primary {
      margin-bottom: 0;
      width: 140px;
    }
  }

  & .add-bank-account-modal {
    & .modal-container {
      max-width: 500px;

      & .footer {
        padding: 0;
      }
    }
  }
}
</style>