import { render, staticRenderFns } from "./FundsReserveDetailsCard.vue?vue&type=template&id=06422ac8&scoped=true&"
import script from "./FundsReserveDetailsCard.vue?vue&type=script&lang=js&"
export * from "./FundsReserveDetailsCard.vue?vue&type=script&lang=js&"
import style0 from "./FundsReserveDetailsCard.vue?vue&type=style&index=0&id=06422ac8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06422ac8",
  null
  
)

export default component.exports