<template>
  <div class="upload-doc-container">
    <div class="header">
      {{ $t('uploadDocument.title') }}
    </div>
    <div class="subtitle">
      {{ $t('uploadDocument.subTitle') }}
    </div>
    <div class="separator"></div>
    <div class="error-container">
      <div class="red-info">
        <img src="@/app/assets/images/red-info.svg" alt="chargebee information icon" />
      </div>
      <div class="error-title-container">
        <div class="error-header">
          {{ $t('uploadDocument.errorTitle') }} {{ getControlPersonName }}
        </div>
        <div class="error-subtitle">
          {{ $t('uploadDocument.errorSubtitle') }}
        </div>
      </div>
    </div>
    <div class="user-container">
      <div class="user-name">
        {{ getControlPersonName }}
      </div>
      <div class="user-designation">
        {{ getControlPerson.jobTitle.value }}
      </div>
      <div class="user-details-container">
        <div class="user-details">
          {{ getControlPerson.emailAddress.value }}
        </div>
        <div class="user-details">
          {{ getControlPhoneNumber }}
        </div>
      </div>
      <div class="user-details-container">
        <div class="user-details">
          {{ $t('uploadDocument.dob') }}
        </div>
        <div class="user-details">
          {{ $t('uploadDocument.maskedDob', {year: getControlPerson.dateOfBirth.value.substring(0, 4)}) }}
        </div>
      </div>
      <div class="user-details-container">
        <div class="user-details">
          {{ getControlPerson.addressLine1.value }}, {{ getControlPerson.addressLine2.value }},
          {{ getControlPerson.addressCity.value }}, {{ getControlPerson.addressState.value }},
          {{ getControlPerson.addressZip.value }}, {{ getControlPerson.addressCountry.value }}
        </div>
      </div>
      <div class="ownership-header">
        {{ $t('uploadDocument.ownershipDetails') }}
      </div>
      <div class="user-details-container">
        <div class="user-details">
          {{ $t('uploadDocument.ownershipPercentage') }}
        </div>
        <div class="user-details">
          {{ getControlPerson.ownershipPercentage.value }}%
        </div>
      </div>
      <div class="user-details-container">
        <div class="user-details">
          {{ $t('uploadDocument.principleTaxID') }}
        </div>
        <div class="user-details">
          {{ getTaxId() }}
        </div>
      </div>
    </div>
    <div class="already-uploaded">
      <div class="already-uploaded-title">
        {{ $t('uploadDocument.previouslyUploaded') }}
      </div>
      <div v-if="loadingAlreadyUploadedFiles" class="loading-already-uploaded">
        {{ $t('uploadDocument.previouslyUploaded') }}
      </div>

      <div v-if="!loadingAlreadyUploadedFiles && !merchantFiles.length" class="loading-already-uploaded">
        {{ $t('uploadDocument.noFilesUploaded') }}
      </div>

      <div class="merchant-file-list" v-if="!!merchantFiles.length">
        <c-table :variant="variant">
          <c-tr>
            <c-th>
              File Type
            </c-th>
            <c-th>
              File Name
            </c-th>
            <c-th>
              Status
            </c-th>
          </c-tr>
          <template v-for="(value, key) in merchantFiles">
            <c-tr :key="key">
              <c-td>
                {{ $t('uploadDocument.' + value.file_type) }}
              </c-td>
              <c-td>
                {{ value.file_name }}
              </c-td>
              <c-td>
                <div class="upload-status">
                  <img src="@/app/assets/images/green-tick.svg" alt="uploaded" v-if="value.status === 'UPLOADED'" />
                  <img
                    src="@/app/assets/images/orange-exclamation.svg" alt="warning!"
                    v-if="value.status === 'PENDING'"
                  />
                  <img src="@/app/assets/images/error.svg" alt="error!" v-if="value.status === 'INVALID'" />
                  {{ $t('uploadDocument.status.' + value.status) }}
                </div>
              </c-td>
            </c-tr>
          </template>
        </c-table>
      </div>
    </div>
    <div class="upload-container">
      <div class="upload-details-container">
        <div class="upload-header">
          {{ $t('uploadDocument.uploadHeader') }}
        </div>
        <div class="data-security">
          <div class="lock">
            <img src="@/app/assets/images/lock.svg" alt="chargebee lock icon" />
          </div>
          <div>
            {{ $t('uploadDocument.dataSecurity') }}
          </div>
        </div>
      </div>
    </div>
    <div class="upload-subtitle">
      {{ $t('uploadDocument.selectDocument') }}
    </div>
    <SelectField
      :label="$t('Select Document')"
      :searchable="false"
      :clearable="false"
      :value="selectedDocumentType"
      :options="getDocumentList(failureCode)"
      :on-update="onDocumentTypeChange"
      select-classname="document-select"
    />
    <div class="upload-box-container">
      <div
        class="upload-box-sub-container"
        v-for="uploadOption in uploadOptions"
        :key="uploadOption"
      >
        <div class="upload-box-header">
          {{ $t(`uploadDocument.${uploadOption}`) }}
        </div>
        <UploadBox
          :upload-type="uploadOption"
          :upload-complete="() => uploadCompleteHandler(uploadOption)"
          :failure-code="failureCode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UploadBox from "@/components/UploadBox";
import SelectField from "@/components/common/SelectField";
import {getAndStoreMerchantFiles} from "@/service/sessionService";

export default {
  name: 'UploadDocument',
  components: {SelectField, UploadBox},
  props: {
    failureCode: {
      type: String,
      required: true,
    },
    allUploadComplete: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    uploadOptions: [],
    uploadedFiles: [],
    merchantFiles: [],
    loadingAlreadyUploadedFiles: true,
  }),
  computed: {
    ...mapGetters('stakeholdersDetails', [
      'getControlPerson',
      'getControlPersonName',
      'getControlPhoneNumber',
    ]),
    ...mapGetters('app', [
      'getMerchantId',
      'getPayfacErrorCodeMapper',
    ]),
    selectedDocumentType() {
      return this.getDocumentList(this.failureCode)[0]?.value;
    },
  },
  mounted() {
    this.onDocumentTypeChange(this.getDocumentList(this.failureCode)[0]?.value)
    this.getFileList()
  },
  methods: {
    ...mapActions('onboarding', ['clearMerchantUploadedDocuments']),
    getDocumentList: function (failureCode) {
      return this.getPayfacErrorCodeMapper.ErrorCodeToDocumentType[
        this.getPayfacErrorCodeMapper.getRawErrorCode(failureCode)
      ]?.map(x => {
        return {
          displayText: this.$t(`documentType.${x}`),
          value: x,
        }
      }) || [];
    },
    onDocumentTypeChange: function (documentType) {
      this.selectedDocumentType = documentType
      this.uploadOptions = this.getPayfacErrorCodeMapper.DocumentTypePages[documentType] || []
      this.clearMerchantUploadedDocuments([])
    },
    uploadCompleteHandler: function (uploadedFile) {
      this.uploadedFiles.push(uploadedFile);
      if (this.uploadedFiles.length === this.uploadOptions.length) {
        this.allUploadComplete()
      }
    },
    getFileList: function () {
      getAndStoreMerchantFiles(this.getMerchantId).then(result => {
        this.loadingAlreadyUploadedFiles = false
        this.merchantFiles = result
      })
    },
    getTaxId: function () {
      if (this.getControlPerson?.taxId?.value != null && this.getControlPerson.taxId.value >4) {
        return "xxx-xx-" + this.getControlPerson.taxId.value.slice(-4)
      }
      return null
    }
  },
}

</script>

<style lang="scss">
.upload-doc-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: auto;
  margin-top: 40px;
  padding-bottom: 200px;

  & .header {
    font-weight: $weight_bold;
    font-size: 24px;
    color: $text-color;
    text-align: left;
    margin-bottom: 8px;
  }

  & .subtitle {
    font-size: 12px;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin: 20px 0 20px 0;
    width: 100%;
  }

  & .error-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    background: #FEF2F2;
    border-radius: 8px;
    width: 100%;
  }

  & .error-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .red-info {
    margin: 5px 15px 0 0;
  }

  & .error-header {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1F2937;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 4px 0px;
  }

  & .error-subtitle {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #1F2937;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 4px 0px;
  }

  & .user-container {
    background: #F3F4F6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px 20px 16px;
    width: 100%;
    margin-top: 30px;
  }

  & .user-name {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: $neutral_800;
  }

  & .user-designation {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $neutral_400;
    margin-bottom: 12px;
    margin-top: 5px;
  }

  & .user-details-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 3px 0;
  }

  & .user-details {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $neutral_400;
    margin-right: 15px;
  }

  & .ownership-header {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $neutral_800;
    margin: 24px 0 12px 0;
  }

  & .upload-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    width: 100%;
  }

  & .upload-details-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  & .upload-header {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $neutral_800;
    flex: 1;
  }

  & .data-security {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $neutral_400;
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;

    & .lock {
      flex: 1;
      text-align: right;
      align-items: end;
      margin-right: 4px;

      & img {
        width: 12px;
      }
    }
  }

  & .upload-subtitle {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $neutral_400;
    margin-top: 5px;
  }

  & .document-select {
    width: 300px;
    display: block;
  }

  & .upload-box-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }

  & .upload-box-sub-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 600px) {
      &:first-child {
        margin-bottom: 20px;
      }
    }

    @media screen and (min-width: 600px) {
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  & .upload-box-header {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $neutral_400;
    margin-bottom: 10px;
  }

  & .loading-already-uploaded {
    margin: auto;
    margin-top: 10px;
    font-size: 14px;
  }

  & .already-uploaded {
    margin-top: 20px;
    background: #F3F4F6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 20px 16px 20px 16px;
    width: 100%;
  }

  & .already-uploaded-title {
    font-size: 16px;
    font-weight: $weight-bolder;
  }

  & .merchant-file-list {
    display: flex;
    width: 100%;
    margin: 20px 0 10px 0;

    & .upload-status {
      display: flex;
      & img {
        margin-right: 10px;
      }
    }
  }

}


</style>
