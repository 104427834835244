/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/
import {validateAndSetValid, validateAndGenerateError} from "@/store/validationFromStore.js"
import {AppDefaults} from "@/app/utils/common/constants"
import {copyFromApiResponse, copyFromErrorResponse} from "@/store/storeMappers";
import {unHyphenify} from "@/app/utils/common/functions/functions";
import {OnboardingSectionData} from "@/store/modules/onboarding/onboardingTypes";
import { stateCode } from "@/app/validators/countrySpecificValidationSpec";

const countrySpecificFields = {
    state: {
        apiPath: "business_info.address.state_code",
        value: null,
        valid: false,
        validationSpec: stateCode
    },
}
export const businessAddressDefaultState: OnboardingSectionData = {
    api: "createMerchant",
    errors: {},
    data: {
        country: {
            apiPath: "business_info.address.country",
            value: AppDefaults.country,
            valid: false,
        },
        line1: {
            apiPath: "business_info.address.line1",
            value: null,
            valid: false
        },
        line2: {
            apiPath: "business_info.address.line2",
            value: null,
            valid: false
        },
        city: {
            apiPath: "business_info.address.city",
            value: null,
            valid: false
        },
        zip: {
            apiPath: "business_info.address.postal_code",
            formattedValue: undefined,
            value: null,
            valid: false
        },
        ...countrySpecificFields
    }
};

const getters = {
    isValid: (state) => Object.keys(state.data).every(key => state.data[key].valid),
    getErrors: s => s.errors,
    getCountry: s => s.data.country.value,
    getLine1: s => s.data.line1.value,
    getLine2: s => s.data.line2.value,
    getState: s => s.data.state.value,
    getCity: s => s.data.city.value,
    getZip: s => s.data.zip.value,
    getFormattedZip: s => s.data.zip.formattedValue,
    getLine1ApiPath: s => s.data.line1.apiPath,
    getLine2ApiPath: s => s.data.line2.apiPath,
    getStateApiPath: s => s.data.state.apiPath,
    getCityApiPath: s => s.data.city.apiPath,
    getZipApiPath: s => s.data.zip.apiPath
}

const actions = {
    validate({commit, state, rootGetters}) {
        commit('resetErrors')
        return validateAndGenerateError(commit, state.api, rootGetters, state.data.country.value, state.data)
    },
    validateField({commit, state}, key) {
        validateAndSetValid(commit, businessAddressDefaultState.api, state.data, state.data.country.value, key)
    },
    validateAllFields({state, dispatch}) {
        Object.keys(state.data).forEach(key => dispatch('validateField', key))
    },
    resetErrors({commit}) {
        commit('resetErrors');
    },
    setErrors({commit}, {key, errors}) {
        commit('setErrors', {key, errors});
    },
    updateCountry({commit, dispatch}, country) {
        commit('updateCountry', country);
        dispatch('validateField', 'country')
    },
    updateLine1({commit, dispatch}, event) {
        commit('updateLine1', event.target.value);
        dispatch('validateField', 'line1')
    },
    updateLine2({commit, dispatch}, event) {
        commit('updateLine2', event.target.value);
        dispatch('validateField', 'line2')
    },
    updateCity({commit, dispatch}, event) {
        commit('updateCity', event.target.value);
        dispatch('validateField', 'city')
    },
    updateState({commit, dispatch}, event) {
        commit('updateState', event);
        dispatch('validateField', 'state')
    },
    updateZip({commit, dispatch}, event) {
        commit('updateZip', event.target.value);
        dispatch('validateField', 'zip')
    },
    copyFromFieldErrorsResponse({commit}, subErrors) {
        commit('copyFromFieldErrorsResponse', subErrors)
    },
    copyFromMerchantApiResponse({commit}, merchantApiResponse) {
        commit('copyFromMerchantApiResponse', merchantApiResponse)
    }
};

const mutations = {
    resetErrors(state): void {
        state.errors = {}
    },
    setInvalid(state, key) {
        state.data[key].valid = false;
    },
    setValid(state, key) {
        state.data[key].valid = true;
    },
    setErrors(state, {key, errors}): void {
        state.errors[key] = errors;
    },
    updateCountry(state, country: string): void {
        state.data.country.value = country;
        delete state.errors.country;
    },
    updateLine1(state, line1: string): void {
        state.data.line1.value = line1;
        delete state.errors.line1;
    },
    updateLine2(state, line2: string): void {
        state.data.line2.value = line2;
        delete state.errors.line2;
    },
    updateCity(state, city: string): void {
        state.data.city.value = city;
        delete state.errors.city;
    },
    updateState(state, stateName: string): void {
        state.data['state'].value = stateName;
        delete state.errors['state'];
    },
    updateZip(state, zip: string): void {
        state.data.zip.value = unHyphenify(zip);
        state.data.zip.formattedValue = unHyphenify(zip);
        delete state.errors.zip;
    },
    copyFromFieldErrorsResponse: function (state, subErrors): void {
        state.errors = copyFromErrorResponse(subErrors, state.data)
    },
    copyFromMerchantApiResponse(state, merchantApiResponse): void {
        state.data = copyFromApiResponse(merchantApiResponse, state.data);
        state.data.zip.formattedValue = unHyphenify(state.data.zip.value);
    }
};

export const businessAddress = {
    namespaced: true,
    state:businessAddressDefaultState,
    getters,
    actions,
    mutations
};