import {isSoleProprietor} from "@/data/type-of-business-list";
import { Country } from "@/app/utils/common/constants";

export const assertBusinessCountryFromStore = (rootGetters, ...country) => {
    return country.includes(rootGetters['businessAddress/getCountry']);
}

export const assertSoloProprietorshipFromStore = (rootGetters) => {
    return isSoleProprietor(rootGetters['businessStructure/getTypeOfBusiness'])
}

export const assertBusinessCountryFromApiResponse = (rootState, flatApiResponse, ...country) => {
    return country.includes(flatApiResponse[rootState.businessAddress.data.country.apiPath]);
}

export const assertSoloProprietorshipFromApiResponse = (rootState, flatApiResponse) => {
    return isSoleProprietor(flatApiResponse[rootState.businessStructure.data.typeOfBusiness.apiPath]);
}

export const assertBankAccountFromStore = (rootGetters, ...type) => {
    return type.includes(rootGetters['bankAccountForPayouts/getBankTransferType']);
}

export const assertShouldShowTaxIdfield = (country) => {
    return Object.keys(Country).includes(country);
}

