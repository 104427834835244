/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import {validateAndSetValid, validateAndGenerateError} from "@/store/validationFromStore.js";
import i18n from '@/i18n'
import {copyFromApiResponse, copyFromErrorResponse} from "@/store/storeMappers";
import { DraftMerchantResponseDto, MerchantResponseDto } from "@/api/paymentApi";
import {AppDefaults} from "@/app/utils/common/constants"
import {OnboardingSectionData} from "@/store/modules/onboarding/onboardingTypes";
import countrySpecificBankAccountFields
    from "@/store/modules/onboarding/conditionalApiFields/countrySpecificBankAccountFields";
import { bankTransferType } from "@/store/modules/onboarding/countrySpecificFieldsUtil";

const accountNumberNotMatchingError = {
    key: 'reenterAccountNumber',
    errors: i18n.t('errors.validation_error.accountNumberNotMatching')
};

export const bankAccountForPayoutsDefaultState: OnboardingSectionData = {
    api: "createMerchant",
    errors: {
        name: null,
        accountType: null,
        bankCode: null,
        accountNumber: null,
        reenterAccountNumber: null,
    },
    data: {
        type: {
            apiPath: "payment_instrument.type",
            value: AppDefaults.paymentInstrumentType,
            valid: true,
        },
        country: {
            apiPath: "payment_instrument.bank_account.country",
            value: AppDefaults.country,
            valid: true,
        },
        bankTransferType: {
            apiPath: "payment_instrument.bank_account.type",
            value: AppDefaults.bankAccountTransfer,
            valid: true,
        },
        ...countrySpecificBankAccountFields.apiFields
    }
};

const businessProfileGetters = {
    isReenterAccountNumberSuccess: (state) => {
        if(!state.data.reenterAccountNumber.value) return false;
        return state.data.accountNumber.value === state.data.reenterAccountNumber.value ||
        state.data.iban.value === state.data.reenterAccountNumber.value
    },
    getReenteredAccountNumber: s => s.data.reenterAccountNumber.value,
    isValid: (state, getters, rootState, rootGetters) =>
           Object.keys(state.data).every(key => {
               if (state.data[key].shouldShowInView) {
                   if(state.data[key].shouldShowInView(rootGetters)) {
                       return state.data[key].valid;
                   } else {
                       return true;
                   }
               } else {
                   return state.data[key].valid;
               }
           }) && getters.isReenterAccountNumberSuccess,
    getAccountTypeError: (state) => state.errors.accountType,
    getErrors: (state) => state.errors,
    getBankTransferType: s => s.data.bankTransferType.value,
    ...countrySpecificBankAccountFields.getters,
    getCountrySpecificBankAccountApiFields: () => countrySpecificBankAccountFields.apiFields,
}

const validateReenteredAccountNumber = (getters, commit): boolean => {
    if (!getters.isReenterAccountNumberSuccess) {
        commit('setErrors', accountNumberNotMatchingError)
        return false
    } else {
        commit('setErrors', {key: 'reenterAccountNumber', errors: null})
        return true;
    }
};

const actions = {
    validate({commit, state, getters, rootGetters}) {
        commit('resetErrors')
        const valid = validateAndGenerateError(commit, state.api, rootGetters, rootGetters["businessAddress/getCountry"], state.data)
        const reenteredAccountNumberValid = validateReenteredAccountNumber(getters, commit)
        return valid && reenteredAccountNumberValid
    },
    validateField({commit, rootGetters}, key) {
        validateAndSetValid(commit, bankAccountForPayoutsDefaultState.api, bankAccountForPayoutsDefaultState.data, rootGetters["businessAddress/getCountry"], key)
    },
    validateAllFields({state, dispatch}) {
        Object.keys(state.data).forEach(key => dispatch('validateField', key))
    },
    resetErrors({commit}) {
        commit('resetErrors');
    },
    setErrors({commit}, {key, errors}) {
        commit('setErrors', {key, errors});
    },
    updateName({commit, dispatch}, event) {
        commit('updateName', event.target.value);
        dispatch('validateField', 'name')
    },
    updateReenterAccountNumber({commit, dispatch, getters}, event) {
        commit('updateReenterAccountNumber', event.target.value);
        validateReenteredAccountNumber(getters, commit)
        dispatch('validateField', 'reenterAccountNumber')
    },
    updateAccountType({commit, dispatch}, accountType) {
        commit('updateAccountType', accountType);
        dispatch('validateField', 'accountType')
    },
    updateCountry({commit}, value) {
        commit('updateCountry', value);
    },
    updateBankTransferType({commit}, value) {
        commit('updateBankTransferType', value);
    },
    updateCountrySpecificBankField({commit, dispatch, getters}, {field, event}) {
        commit('updateCountrySpecificBankField', {field, value: event?.target?.value});
        if(field === 'iban' || field === 'accountNumber') {
            validateReenteredAccountNumber(getters, commit)
        }
        dispatch('validateField', field)
    },
    copyFromFieldErrorsResponse({commit}, subErrors) {
        commit('copyFromFieldErrorsResponse', subErrors)
    },
    copyFromMerchantApiResponse({commit}, merchantApiResponse) {
        commit('copyFromMerchantApiResponse', merchantApiResponse)
    }
};

const mutations = {
    resetErrors(state): void {
        state.errors = {}
    },
    setErrors(state, {key, errors}): void {
        state.errors[key] = errors;
    },
    setInvalid(state, key) {
        state.data[key].valid = false;
    },
    setValid(state, key) {
        state.data[key].valid = true;
    },
    updateName(state, name: string): void {
        state.data.name.value = name;
        state.errors.name = null;
    },
    updateAccountType(state, accountType: string): void {
        state.data.accountType.value = accountType;
        state.errors.accountType = null;
    },
    updateReenterAccountNumber(state, accountNumber: string): void {
        state.data.reenterAccountNumber.value = accountNumber;
        state.errors.reenterAccountNumber = null;
    },
    updateCountry(state, country: string): void {
        state.data.country.value = country;
        state.data.bankTransferType.value = bankTransferType(country);
        //resetting bank fields when country is changed
        ['iban', 'sortCode', 'accountNumber', 'bankCode', 'accountType', 'reenterAccountNumber'].forEach(key => {
            state.data[key].value = null;
            state.data[key].valid = false;
            delete state.errors[key];
        });
    },
    updateBankTransferType(state, type: string): void {
        state.data.bankTransferType.value = type;
    },
    updateCountrySpecificBankField(state, {field, value}): void {
        state.data[field].value = value;
        delete state.errors[field];
    },
    copyFromFieldErrorsResponse: function (state, subErrors): void {
        state.errors = copyFromErrorResponse(subErrors, state.data)
    },
    copyFromMerchantApiResponse(state, merchantApiResponse: MerchantResponseDto | DraftMerchantResponseDto): void {
        const paymentInstrument = merchantApiResponse['payment_instruments']?.[0]
        state.data = copyFromApiResponse({payment_instrument: paymentInstrument}, state.data);
    }
};

export const bankAccountForPayouts = {
    namespaced: true,
    state:bankAccountForPayoutsDefaultState,
    getters:businessProfileGetters,
    actions,
    mutations
};