import {isNullOrEmpty} from "@/app/utils/common/functions/functions";
import {Currency} from "@/api/paymentApi";

export class FeeSplit {
    private readonly txnAmount: number;
    private readonly txnCurrency: Currency;
    private readonly totalFee: number;

    constructor(txnAmount: number, totalFee: number, txnCurrency: Currency) {
        this.txnAmount = isNullOrEmpty(txnAmount) ? 0 : txnAmount;
        this.totalFee = isNullOrEmpty(totalFee) ? 0 : totalFee;
        this.txnCurrency = txnCurrency;
    }

    getTotalFee(): number {
        return this.totalFee;
    }
}