<template>
  <div class="payment-instrument-container">
    <div class="title">
      {{ $t('paymentInstrument.title') }}
    </div>

    <div class="payment-instrument-info">
      <template v-if="isBankAccount">
        <img src="@/app/assets/images/account_balance.svg" alt="Chargebee bank account Icon" />
        <div class="instrument-icon">
          {{ $t('paymentInstrument.' + paymentInstrument.bank_account.type) }}
        </div>
      </template>
      <template v-else>
        <component :is="cardLogoForCardBrand" />
        <div class="instrument-icon">
          {{ cardPaymentInstrumentInfo }}
        </div>
        <template v-if="cardExpiry">
          <div class="border"></div>
          <div class="expiry-label">
            {{ $t('paymentInstrument.card.expiresOn') }}
          </div>
          {{ cardExpiry }}
        </template>
        <template v-if="zipCode">
          <div class="border"></div>
          <div class="expiry-label">
            {{ $t('paymentInstrument.card.zipCode') }}
          </div>
          {{ zipCode }}
        </template>
      </template>
    </div>
    <div class="fields">
      <div class="column">
        <FieldValue :field-value="paymentInstrument.id" :field-name="$t('fieldNames.paymentInstrumentId')" />
        <template v-if="isBankAccount">
          <FieldValue
            :field-value="bankAccountNumber"
            :field-name="$t('fieldNames.bankAccount')"
          />
        </template>
        <template v-else>
          <FieldValue
            :field-value="getCardHolderName"
            :field-name="$t('fieldNames.cardholder')"
          />
          <FieldValue
            :field-value="originCountry"
            :field-name="$t('fieldNames.origin')"
            :tooltip="$t('paymentInstrument.tooltip.origin')"
          />
        </template>
      </div>
      <div class="column">
        <template v-if="isBankAccount">
          <FieldValue
            v-if="isBankAccount"
            :field-value="bankAccountHolderName"
            :field-name="$t('fieldNames.accountHolderName')"
          />
        </template>
        <template v-else>
          <FieldValue
            v-if="paymentInstrument.card.avs"
            :field-value="avsCheck"
            :field-name="$t('fieldNames.avsCheck')"
            :show-status-icon="paymentInstrument.card.avs !=='UNKNOWN'"
            :show-passed-icon="paymentInstrument.card.avs === 'VALID'"
            :tooltip="$t('paymentInstrument.tooltip.avs')"
          />
          <FieldValue
            v-if="paymentInstrument.card.cvc"
            :field-value="cvcCheck"
            :field-name="$t('fieldNames.cvcCheck')"
            :show-status-icon="paymentInstrument.card.cvc !=='UNKNOWN'"
            :show-passed-icon="paymentInstrument.card.cvc === 'VALID'"
            :tooltip="$t('paymentInstrument.tooltip.cvc')"
          />
          <FieldValue
            v-if="fullAddress !== null && $route.name === 'dispute_details'"
            :field-value="fullAddress"
            :field-name="$t('fieldNames.address')"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import {PaymentInstrumentType, PaymentInstrumentsResponseDto, CvcStatus, AvsStatus} from "@/api/paymentApi";
import {cardBrandLogo} from "@/app/utils/common/helpers/cardBrandLogo";

export default {
  name: "PaymentInstrument",
  components: {FieldValue},
  props: {
    paymentInstrument: {
      type: PaymentInstrumentsResponseDto,
      required: true,
    },
  },
  computed: {
    cardLogoForCardBrand() {
      return cardBrandLogo[this.getCardBrand] || cardBrandLogo['UNKNOWN'];
    },
    bankAccountNumber() {
      return "**** " + this.paymentInstrument.bank_account?.last_four;
    },
    bankAccountHolderName() {
      return this.paymentInstrument.bank_account?.name;
    },
    avsCheck() {
      switch (this.paymentInstrument.card?.avs) {
        case AvsStatus.VALID:
          return "Passed";
        case AvsStatus.INVALID:
          return "Failed";
        default:
          return null;
      }
    },
    cvcCheck() {
      switch (this.paymentInstrument.card?.cvc) {
        case CvcStatus.VALID:
          return "Passed";
        case CvcStatus.INVALID:
          return "Failed";
        default:
          return null;
      }
    },
    originCountry() {
      const address = this.paymentInstrument.card?.card_holder_address;
      return (!address) ? "": address.country;
    },
    fullAddress() {
      const address = this.paymentInstrument.card?.card_holder_address;
      return (!address) ? "" : [address.line1, address.line2, address.city, address.state_code, address.country, address.postal_code]
          .filter(x => x).join(", ");
    },
    zipCode() {
      const address = this.paymentInstrument.card?.card_holder_address;
      return (!address) ? "" : address.postal_code;
    },
    getCardHolderName() {
      return this.paymentInstrument.card?.card_holder_name;
    },
    isBankAccount() {
      return this.paymentInstrument.type === PaymentInstrumentType.BankAccount;
    },
    cardPaymentInstrumentInfo() {
      const card = this.paymentInstrument.card;
      return (!card) ? "" : this.$t('cards.brands.' + card.brand) + this.$t('cards.cardMasked') + card.last_four;
    },
    getCardBrand() {
      return this.paymentInstrument.card?.brand;
    },
    cardExpiry() {
      if (this.paymentInstrument.type === PaymentInstrumentType.Card) {
        const card = this.paymentInstrument.card;
        return String(card.expiration_month).padStart(2, '0') + " / " + card.expiration_year
      }
      return ""
    },
  },
}
</script>

<style lang="scss">
.payment-instrument-container {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .payment-instrument-info {
    text-align: left;
    margin-top: 20px;
    display: flex;
    align-items: center;

    & .expiry-label {
      color: $neutral_400;
      margin-right: 4px;
    }

    & .border {
      width: 1px;
      height: 16px;
      background: #CDD2DB;
      margin-left: 15px;
      margin-right: 15px;
    }

    & .instrument-icon {
      padding-left: 7px;
    }
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }
  }
}
</style>