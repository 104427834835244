/**
 * Chargebee Payments Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/* eslint-disable */

import cbClient, { unSegmentedCbClient } from "@/app/utils/common/core/cb-client";
import { fixedEncodeURIComponent } from "@/app/utils/common/functions/url-helper";
const BASE_PATH = "".replace(/\/+$/, "");
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: "RequiredError" = "RequiredError";
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  headers: any;
  queryParams: any;
  body?: any
}

/**
 * Type of bank accounts. You can use `CHECKING` or `SAVINGS` as an account type.
 * @export
 * @enum {string}
 */
export enum AccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS'
}

/**
 * ACH Pricing Model of merchant
 * @export
 * @interface AchPricing
 */
export interface AchPricing {
    /**
     * percent charge on every transaction
     * @type {number}
     * @memberof AchPricing
     */
    'basis_points'?: number;
    /**
     * minimum fee on basis points
     * @type {number}
     * @memberof AchPricing
     */
    'min_fee'?: number;
    /**
     * maximum fee on basis points
     * @type {number}
     * @memberof AchPricing
     */
    'max_fee'?: number;
    /**
     * fixed fee on every transaction
     * @type {number}
     * @memberof AchPricing
     */
    'fixed_rate'?: number;
    /**
     * surcharge for international transactions
     * @type {number}
     * @memberof AchPricing
     */
    'cross_border_basis_points'?: number;
    /**
     * forex charge if applicable
     * @type {number}
     * @memberof AchPricing
     */
    'forex_basis_points'?: number;
}

/**
 * 
 * @export
 * @interface AddFundRequestDto
 */
export interface AddFundRequestDto {
    /**
     * A unique identifier of the payment instrument that is used for fund transaction for reserve account.
     * @type {string}
     * @memberof AddFundRequestDto
     */
    'merchant_payment_instrument_id': string;
    /**
     * 
     * @type {Array<ReserveSplitDto>}
     * @memberof AddFundRequestDto
     */
    'split': Array<ReserveSplitDto>;
}

/**
 * 
 * @export
 * @interface Additional3DSData
 */
export interface Additional3DSData {
    /**
     * The email address of the shopper.
     * @type {string}
     * @memberof Additional3DSData
     */
    'shopper_email'?: string;
    /**
     * The IP address of the shopper.
     * @type {string}
     * @memberof Additional3DSData
     */
    'shopper_IP'?: string;
    /**
     * The origin of the transaction.
     * @type {string}
     * @memberof Additional3DSData
     */
    'origin'?: string;
    /**
     * The URL to which the shopper should be redirected after the 3DS authentication.
     * @type {string}
     * @memberof Additional3DSData
     */
    'return_url'?: string;
    /**
     * 
     * @type {MobilePhone}
     * @memberof Additional3DSData
     */
    'mobile_phone'?: MobilePhone;
    /**
     * 
     * @type {BrowserInfo}
     * @memberof Additional3DSData
     */
    'browser_info'?: BrowserInfo;
}

/**
 * 
 * @export
 * @interface AdditionalPurchaseData
 */
export interface AdditionalPurchaseData {
    /**
     * The reference number of the customer who initiated the transaction
     * @type {string}
     * @memberof AdditionalPurchaseData
     */
    'customer_reference': string;
    /**
     * The total tax amount in cents.
     * @type {number}
     * @memberof AdditionalPurchaseData
     */
    'total_tax_amount'?: number;
    /**
     * Additional information about the items included in the transaction
     * @type {Array<ItemData>}
     * @memberof AdditionalPurchaseData
     */
    'items_data'?: Array<ItemData>;
}

/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * Address line 1.
     * @type {string}
     * @memberof AddressDto
     */
    'line1'?: string;
    /**
     * Address line 2.
     * @type {string}
     * @memberof AddressDto
     */
    'line2'?: string;
    /**
     * The name of the city.
     * @type {string}
     * @memberof AddressDto
     */
    'city'?: string;
    /**
     * The <a href= \"https://www.iso.org/obp/ui/#search/code\" target=\"_blank\">two-letter state code</a>.
     * @type {string}
     * @memberof AddressDto
     */
    'state_code'?: string;
    /**
     * Zip or postal code. The number of characters is validated according to the rules <a href= \"https://i18napis.appspot.com/address\" target=\"_blank\">specified here</a>.
     * @type {string}
     * @memberof AddressDto
     */
    'postal_code'?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof AddressDto
     */
    'country'?: CountryCode;
}

/**
 * 
 * @export
 * @interface AuditDtoList
 */
export interface AuditDtoList {
    /**
     * List of audits fetched.
     * @type {Array<AuditResponseDto>}
     * @memberof AuditDtoList
     */
    'audits'?: Array<AuditResponseDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof AuditDtoList
     */
    'cursor'?: string;
    /**
     * Total number of audits raised for the given merchant.
     * @type {number}
     * @memberof AuditDtoList
     */
    'count'?: number;
}

/**
 * 
 * @export
 * @interface AuditResponseDto
 */
export interface AuditResponseDto {
    /**
     * A unique identifier of the dispute.
     * @type {string}
     * @memberof AuditResponseDto
     */
    'id'?: string;
    /**
     * Name of the event.
     * @type {string}
     * @memberof AuditResponseDto
     */
    'event'?: string;
    /**
     * Type of the resource.
     * @type {string}
     * @memberof AuditResponseDto
     */
    'resource_type'?: string;
    /**
     * ID of the resource.
     * @type {string}
     * @memberof AuditResponseDto
     */
    'resource_id'?: string;
    /**
     * content of the audit.
     * @type {string}
     * @memberof AuditResponseDto
     */
    'content'?: string;
    /**
     * The date-time of event.
     * @type {Date}
     * @memberof AuditResponseDto
     */
    'created_at'?: Date;
}

/**
 * 
 * @export
 * @interface AuthorizationFilter
 */
export interface AuthorizationFilter extends PaginationFilter {
    /**
     * Status
     * @type {Array<AuthorizationStatus>}
     * @memberof AuthorizationFilter
     */
    'status'?: Array<AuthorizationStatus>;
    /**
     * Page from which this export is requested
     * @type {string}
     * @memberof AuthorizationFilter
     */
    'requested_from'?: string;
}

/**
 * 
 * @export
 * @interface AuthorizationResponseDto
 */
export interface AuthorizationResponseDto {
    /**
     * The unique identifier of the authorization.
     * @type {string}
     * @memberof AuthorizationResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {ConsumerResponseDto}
     * @memberof AuthorizationResponseDto
     */
    'consumer'?: ConsumerResponseDto;
    /**
     * The unique identifier of the merchant.
     * @type {string}
     * @memberof AuthorizationResponseDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {PaymentInstrumentsResponseDto}
     * @memberof AuthorizationResponseDto
     */
    'payment_instrument'?: PaymentInstrumentsResponseDto;
    /**
     * 
     * @type {AuthorizationStatus}
     * @memberof AuthorizationResponseDto
     */
    'status'?: AuthorizationStatus;
    /**
     * The authorization transaction amount will be debited in cents.
     * @type {number}
     * @memberof AuthorizationResponseDto
     */
    'amount'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof AuthorizationResponseDto
     */
    'currency'?: Currency;
    /**
     * The reason for sale failure or cancellation.
     * @type {string}
     * @memberof AuthorizationResponseDto
     */
    'error_reason'?: string;
    /**
     * The reason code for sale failure or cancellation.
     * @type {string}
     * @memberof AuthorizationResponseDto
     */
    'error_code'?: string;
    /**
     * The date of transaction creation.
     * @type {Date}
     * @memberof AuthorizationResponseDto
     */
    'created_at'?: Date;
    /**
     * The date of transaction updation.
     * @type {Date}
     * @memberof AuthorizationResponseDto
     */
    'updated_at'?: Date;
}

/**
 * Status of the authorization.  - PENDING  - SUCCEEDED  - FAILED  - CANCELED  - UNAVAILABLE 
 * @export
 * @enum {string}
 */
export enum AuthorizationStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  UNAVAILABLE = 'UNAVAILABLE'
}

/**
 * 
 * @export
 * @interface AuthorizationsResponseDto
 */
export interface AuthorizationsResponseDto {
    /**
     * Total number of consumer authorizations available for the given merchant and filters.
     * @type {number}
     * @memberof AuthorizationsResponseDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<AuthorizationResponseDto>}
     * @memberof AuthorizationsResponseDto
     */
    'authorizations'?: Array<AuthorizationResponseDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof AuthorizationsResponseDto
     */
    'cursor'?: string;
}

/**
 * Avs status of the card. - VALID - INVALID - UNKNOWN 
 * @export
 * @enum {string}
 */
export enum AvsStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN'
}

/**
 * BACS Pricing Model of merchant
 * @export
 * @interface BacsPricing
 */
export interface BacsPricing {
    /**
     * percent charge on every transaction
     * @type {number}
     * @memberof BacsPricing
     */
    'basis_points'?: number;
    /**
     * minimum fee on basis points
     * @type {number}
     * @memberof BacsPricing
     */
    'min_fee'?: number;
    /**
     * maximum fee on basis points
     * @type {number}
     * @memberof BacsPricing
     */
    'max_fee'?: number;
    /**
     * fixed fee on every transaction
     * @type {number}
     * @memberof BacsPricing
     */
    'fixed_rate'?: number;
    /**
     * surcharge for international transactions
     * @type {number}
     * @memberof BacsPricing
     */
    'cross_border_basis_points'?: number;
    /**
     * forex charge if applicable
     * @type {number}
     * @memberof BacsPricing
     */
    'forex_basis_points'?: number;
}

/**
 * 
 * @export
 * @interface BalanceAccountAdditionalInfo
 */
export interface BalanceAccountAdditionalInfo {
    /**
     * minimum required amount
     * @type {number}
     * @memberof BalanceAccountAdditionalInfo
     */
    'amount_to_be_held'?: number;
    /**
     * the date when we release fund of balance account
     * @type {Date}
     * @memberof BalanceAccountAdditionalInfo
     */
    'fund_release_date'?: Date;
    /**
     * The date when we can recover negative balance account
     * @type {Date}
     * @memberof BalanceAccountAdditionalInfo
     */
    'fund_recovery_date'?: Date;
    /**
     * cursor of scheduler
     * @type {string}
     * @memberof BalanceAccountAdditionalInfo
     */
    'transactionReserveCursor'?: string;
}

/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * A unique identifier generated for the bank account.
     * @type {string}
     * @memberof BankAccount
     */
    'id'?: string;
    /**
     * Full name of the account holder.
     * @type {string}
     * @memberof BankAccount
     */
    'name': string;
    /**
     * Bank account number of the consumer.
     * @type {string}
     * @memberof BankAccount
     */
    'account_number'?: string;
    /**
     * 
     * @type {AccountType}
     * @memberof BankAccount
     */
    'account_type'?: AccountType;
    /**
     * A code to identify the bank.
     * @type {string}
     * @memberof BankAccount
     */
    'bank_code'?: string;
    /**
     * A bank code to identify the bank for BACS_DEBIT payment method type
     * @type {string}
     * @memberof BankAccount
     */
    'sort_code'?: string;
    /**
     * A numeric code to identify the bank for SEPA_DEBIT payment method type
     * @type {string}
     * @memberof BankAccount
     */
    'iban'?: string;
    /**
     * 
     * @type {BankAccountType}
     * @memberof BankAccount
     */
    'type'?: BankAccountType;
    /**
     * 
     * @type {CountryCode}
     * @memberof BankAccount
     */
    'country'?: CountryCode;
}

/**
 * 
 * @export
 * @interface BankAccountDto
 */
export interface BankAccountDto {
    /**
     * A code to identify the bank.
     * @type {string}
     * @memberof BankAccountDto
     */
    'bank_code'?: string;
    /**
     * Type of bank account.
     * @type {string}
     * @memberof BankAccountDto
     */
    'account_type'?: string;
    /**
     * Last four digit of the account number.
     * @type {string}
     * @memberof BankAccountDto
     */
    'last_four'?: string;
    /**
     * 
     * @type {BankAccountType}
     * @memberof BankAccountDto
     */
    'type'?: BankAccountType;
    /**
     * Name of the account holder.
     * @type {string}
     * @memberof BankAccountDto
     */
    'name'?: string;
}

/**
 * Type of bank account
 * @export
 * @enum {string}
 */
export enum BankAccountType {
  ACHDEBIT = 'ACH_DEBIT',
  BACSDEBIT = 'BACS_DEBIT',
  SEPADEBIT = 'SEPA_DEBIT'
}

/**
 * 
 * @export
 * @interface BeneficiaryDto
 */
export interface BeneficiaryDto {
    /**
     * Unique identifier generated for the beneficiary
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'id'?: string;
    /**
     * Unique identifier generated for the beneficiary by the payfac
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'id_at_payfac'?: string;
    /**
     * Full legal first name of the merchant’s principal representative
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'first_name': string;
    /**
     * Full legal last name of the merchant’s principal representative
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'last_name': string;
    /**
     * Principal’s corporate title or role. Eg Cheif Executive Officer
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'title': string;
    /**
     * Percentage of company owned by the principal. Eg 30
     * @type {number}
     * @memberof BeneficiaryDto
     */
    'principal_percentage_ownership': number;
    /**
     * 
     * @type {TaxInfo}
     * @memberof BeneficiaryDto
     */
    'tax_info': TaxInfo;
    /**
     * Principal’s date of birth
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'dob': string;
    /**
     * Principal’s phone number
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'phone': string;
    /**
     * Principal’s email address where they can be reached
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'email': string;
    /**
     * 
     * @type {BusinessOwnerType}
     * @memberof BeneficiaryDto
     */
    'business_owner_type'?: BusinessOwnerType;
    /**
     * Name of the Business. This is applicable only when the business owner type is ENTITY.
     * @type {string}
     * @memberof BeneficiaryDto
     */
    'business_name'?: string;
    /**
     * 
     * @type {MerchantAddressDto}
     * @memberof BeneficiaryDto
     */
    'personal_address': MerchantAddressDto;
    /**
     * Created timestamp of beneficiary creation.
     * @type {Date}
     * @memberof BeneficiaryDto
     */
    'created_at'?: Date;
    /**
     * Updated timestamp of beneficiary creation.
     * @type {Date}
     * @memberof BeneficiaryDto
     */
    'updated_at'?: Date;
}

/**
 * 
 * @export
 * @interface BrowserInfo
 */
export interface BrowserInfo {
    /**
     * The user agent of the shopper's browser.
     * @type {string}
     * @memberof BrowserInfo
     */
    'user_agent'?: string;
    /**
     * The color depth of the shopper's browser.
     * @type {number}
     * @memberof BrowserInfo
     */
    'color_depth'?: number;
    /**
     * The Java enabled status of the shopper's browser.
     * @type {boolean}
     * @memberof BrowserInfo
     */
    'java_enabled'?: boolean;
    /**
     * The language of the shopper's browser.
     * @type {string}
     * @memberof BrowserInfo
     */
    'language'?: string;
    /**
     * The screen height of the shopper's browser.
     * @type {number}
     * @memberof BrowserInfo
     */
    'screen_height'?: number;
    /**
     * The screen width of the shopper's browser.
     * @type {number}
     * @memberof BrowserInfo
     */
    'screen_width'?: number;
    /**
     * The time zone offset of the shopper's browser.
     * @type {number}
     * @memberof BrowserInfo
     */
    'time_zone_offset'?: number;
    /**
     * The HTTP accept header of the shopper's browser.
     * @type {string}
     * @memberof BrowserInfo
     */
    'accept_header'?: string;
}

/**
 * 
 * @export
 * @interface BusinessInfo
 */
export interface BusinessInfo {
    /**
     * The legal business name of the merchant. If the business is `INDIVIDUAL_SOLE_PROPRIETORSHIP`, please input first name, last name, and middle initial of the proprietor.
     * @type {string}
     * @memberof BusinessInfo
     */
    'name': string;
    /**
     * The alternate name of the business. If there is no alternate name, use the value from business `name` parameter.
     * @type {string}
     * @memberof BusinessInfo
     */
    'doing_business_as'?: string;
    /**
     * 
     * @type {BusinessType}
     * @memberof BusinessInfo
     */
    'type': BusinessType;
    /**
     * 
     * @type {TaxInfo}
     * @memberof BusinessInfo
     */
    'tax_info': TaxInfo;
    /**
     * The public website URL of the merchant.
     * @type {string}
     * @memberof BusinessInfo
     */
    'url': string;
    /**
     * Description of the merchant’s business.
     * @type {string}
     * @memberof BusinessInfo
     */
    'description': string;
    /**
     * Merchant Category Code ([MCC](https://en.wikipedia.org/wiki/Merchant_category_code)) is a four digit number that classifies a business by the types of goods or services it provides.
     * @type {string}
     * @memberof BusinessInfo
     */
    'mcc': string;
    /**
     * The customer service phone number of the merchant.
     * @type {string}
     * @memberof BusinessInfo
     */
    'phone': string;
    /**
     * Date of company's foundation.
     * @type {string}
     * @memberof BusinessInfo
     */
    'incorporation_date': string;
    /**
     * 
     * @type {MerchantAddressDto}
     * @memberof BusinessInfo
     */
    'address': MerchantAddressDto;
    /**
     * 
     * @type {OwnershipType}
     * @memberof BusinessInfo
     */
    'ownership_type': OwnershipType;
}

/**
 * Type of the business owner - INDIVIDUAL - ENTITY 
 * @export
 * @enum {string}
 */
export enum BusinessOwnerType {
  INDIVIDUAL = 'INDIVIDUAL',
  ENTITY = 'ENTITY'
}

/**
 * Type of business. Here are the type of business. - INDIVIDUAL_SOLE_PROPRIETOR - CORPORATION - LIMITED_LIABILITY_COMPANY - PARTNERSHIP - ASSOCIATIONS_AND_TRUSTS - TAX_EXEMPT - INTERNATIONAL - GOVERNMENT_ENTITY 
 * @export
 * @enum {string}
 */
export enum BusinessType {
  INDIVIDUALSOLEPROPRIETOR = 'INDIVIDUAL_SOLE_PROPRIETOR',
  CORPORATION = 'CORPORATION',
  LIMITEDLIABILITYCOMPANY = 'LIMITED_LIABILITY_COMPANY',
  PARTNERSHIP = 'PARTNERSHIP',
  ASSOCIATIONSANDTRUSTS = 'ASSOCIATIONS_AND_TRUSTS',
  TAXEXEMPT = 'TAX_EXEMPT',
  INTERNATIONAL = 'INTERNATIONAL',
  GOVERNMENTENTITY = 'GOVERNMENT_ENTITY'
}

/**
 * Cards Pricing Model of merchant
 * @export
 * @interface CardPricing
 */
export interface CardPricing {
    /**
     * Type of card pricing model. - BLENDED_PRICING_FLAT_RATE 
     * @type {string}
     * @memberof CardPricing
     */
    'pricing_type': CardPricing.PricingTypeEnum;
    /**
     * fixed fee for every card transaction
     * @type {number}
     * @memberof CardPricing
     */
    'fixed_rate': number;
    /**
     * percent charge on every card transaction
     * @type {number}
     * @memberof CardPricing
     */
    'basis_points': number;
    /**
     * extra charge for non-US card transaction
     * @type {number}
     * @memberof CardPricing
     */
    'cross_border_basis_points': number;
    /**
     * 
     * @type {number}
     * @memberof CardPricing
     */
    'cross_border_basis_points_uk'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardPricing
     */
    'cross_border_basis_points_eea'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardPricing
     */
    'forex_basis_points': number;
    /**
     * 
     * @type {number}
     * @memberof CardPricing
     */
    'three_ds_fixed_rate'?: number;
}

/**
 * @export
 * @namespace CardPricing
 */
export namespace CardPricing {
  /**
    * @export
    * @enum {string}
    */
  export enum PricingTypeEnum {
    BLENDEDPRICINGFLATRATE = 'BLENDED_PRICING_FLAT_RATE'
    }
}

/**
 * 
 * @export
 * @interface CardRequestDto
 */
export interface CardRequestDto {
    /**
     * A unique identifier of the card.
     * @type {string}
     * @memberof CardRequestDto
     */
    'id'?: string;
    /**
     * The year of card expiry.
     * @type {string}
     * @memberof CardRequestDto
     */
    'expiration_year': string;
    /**
     * The card number mentioned on the card.
     * @type {string}
     * @memberof CardRequestDto
     */
    'number': string;
    /**
     * The month of card expiry.
     * @type {number}
     * @memberof CardRequestDto
     */
    'expiration_month': number;
    /**
     * The name of the card holder.
     * @type {string}
     * @memberof CardRequestDto
     */
    'card_holder_name': string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CardRequestDto
     */
    'card_holder_address'?: AddressDto;
    /**
     * The security code of the card.
     * @type {string}
     * @memberof CardRequestDto
     */
    'security_code': string;
}

/**
 * 
 * @export
 * @interface CardResponseDto
 */
export interface CardResponseDto {
    /**
     * The last four digit of the card number.
     * @type {string}
     * @memberof CardResponseDto
     */
    'last_four'?: string;
    /**
     * The month of card expiry.
     * @type {number}
     * @memberof CardResponseDto
     */
    'expiration_month'?: number;
    /**
     * The year of card expiry.
     * @type {string}
     * @memberof CardResponseDto
     */
    'expiration_year'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardResponseDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardResponseDto
     */
    'bin'?: string;
    /**
     * 
     * @type {AvsStatus}
     * @memberof CardResponseDto
     */
    'avs'?: AvsStatus;
    /**
     * 
     * @type {CvcStatus}
     * @memberof CardResponseDto
     */
    'cvc'?: CvcStatus;
    /**
     * The name of the card holder.
     * @type {string}
     * @memberof CardResponseDto
     */
    'card_holder_name'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CardResponseDto
     */
    'card_holder_address'?: AddressDto;
}

/**
 * 
 * @export
 * @interface ComplianceFormAttestationDetailsDto
 */
export interface ComplianceFormAttestationDetailsDto {
    /**
     * Name of the person completing the compliance form.
     * @type {string}
     * @memberof ComplianceFormAttestationDetailsDto
     */
    'name'?: string;
    /**
     * IP address of the person attesting to this compliance form.
     * @type {string}
     * @memberof ComplianceFormAttestationDetailsDto
     */
    'ip_address'?: string;
    /**
     * Timestamp of the person attesting to this compliance form.
     * @type {string}
     * @memberof ComplianceFormAttestationDetailsDto
     */
    'signed_at'?: string;
    /**
     * User agent of the person attesting to this compliance form.
     * @type {string}
     * @memberof ComplianceFormAttestationDetailsDto
     */
    'user_agent'?: string;
    /**
     * Title or role of the person completing the compliance form.
     * @type {string}
     * @memberof ComplianceFormAttestationDetailsDto
     */
    'title'?: string;
    /**
     * Merchant submitted all the fields in the form attestation.
     * @type {boolean}
     * @memberof ComplianceFormAttestationDetailsDto
     */
    'is_accepted'?: boolean;
}

/**
 * 
 * @export
 * @interface ComplianceFormResponseDto
 */
export interface ComplianceFormResponseDto {
    /**
     * The unique identifier of the compliance form.
     * @type {string}
     * @memberof ComplianceFormResponseDto
     */
    'id'?: string;
    /**
     * The unique identifier of the merchant for whom the compliance form belongs to.
     * @type {string}
     * @memberof ComplianceFormResponseDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {ComplianceFormStatus}
     * @memberof ComplianceFormResponseDto
     */
    'compliance_form_status'?: ComplianceFormStatus;
    /**
     * 
     * @type {ComplianceFormType}
     * @memberof ComplianceFormResponseDto
     */
    'compliance_form_type'?: ComplianceFormType;
    /**
     * Timestamp of when the compliance form becomes active and valid.
     * @type {Date}
     * @memberof ComplianceFormResponseDto
     */
    'valid_from'?: Date;
    /**
     * Timestamp of when the compliance form is no longer active and valid.
     * @type {Date}
     * @memberof ComplianceFormResponseDto
     */
    'valid_until'?: Date;
    /**
     * Timestamp of when the compliance form must be completed by.
     * @type {Date}
     * @memberof ComplianceFormResponseDto
     */
    'due_at'?: Date;
    /**
     * 
     * @type {ComplianceFormAttestationDetailsDto}
     * @memberof ComplianceFormResponseDto
     */
    'attestation_details'?: ComplianceFormAttestationDetailsDto;
    /**
     * The unique identifier of the unsigned compliance form file.
     * @type {string}
     * @memberof ComplianceFormResponseDto
     */
    'unsigned_file_id'?: string;
    /**
     * The unique identifier of the signed compliance form file.
     * @type {string}
     * @memberof ComplianceFormResponseDto
     */
    'signed_file_id'?: string;
    /**
     * The date of compliance form creation.
     * @type {Date}
     * @memberof ComplianceFormResponseDto
     */
    'created_at'?: Date;
    /**
     * The date of compliance form updation.
     * @type {Date}
     * @memberof ComplianceFormResponseDto
     */
    'updated_at'?: Date;
}

/**
 * 
 * @export
 * @interface ComplianceFormResponseDtoList
 */
export interface ComplianceFormResponseDtoList {
    /**
     * 
     * @type {Array<ComplianceFormResponseDto>}
     * @memberof ComplianceFormResponseDtoList
     */
    'compliance_forms'?: Array<ComplianceFormResponseDto>;
}

/**
 * Signed status of the compliance form.   - SIGNED   - UNSIGNED 
 * @export
 * @enum {string}
 */
export enum ComplianceFormSignedStatus {
  SIGNED = 'SIGNED',
  UNSIGNED = 'UNSIGNED'
}

/**
 * Status of the compliance form.   - COMPLETE   - INVALID   - INCOMPLETE   - OVERDUE   - EXPIRED 
 * @export
 * @enum {string}
 */
export enum ComplianceFormStatus {
  COMPLETE = 'COMPLETE',
  INVALID = 'INVALID',
  INCOMPLETE = 'INCOMPLETE',
  OVERDUE = 'OVERDUE',
  EXPIRED = 'EXPIRED'
}

/**
 * Type of the compliance form.   - PCI_SAQ_A 
 * @export
 * @enum {string}
 */
export enum ComplianceFormType {
  PCISAQA = 'PCI_SAQ_A'
}

/**
 * 
 * @export
 * @interface ConfigurationRequestDto
 */
export interface ConfigurationRequestDto {
    /**
     * 
     * @type {CardPricing}
     * @memberof ConfigurationRequestDto
     */
    'card_pricing'?: CardPricing;
    /**
     * 
     * @type {AchPricing}
     * @memberof ConfigurationRequestDto
     */
    'ach_pricing'?: AchPricing;
    /**
     * 
     * @type {BacsPricing}
     * @memberof ConfigurationRequestDto
     */
    'bacs_pricing'?: BacsPricing;
    /**
     * 
     * @type {SepaPricing}
     * @memberof ConfigurationRequestDto
     */
    'sepa_pricing'?: SepaPricing;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurationRequestDto
     */
    'allow_refund_for_insufficient_balance'?: boolean;
    /**
     * 
     * @type {RollingReserveConfig}
     * @memberof ConfigurationRequestDto
     */
    'rolling_reserve_config'?: RollingReserveConfig;
    /**
     * 
     * @type {UpfrontReserveConfig}
     * @memberof ConfigurationRequestDto
     */
    'upfront_reserve_config'?: UpfrontReserveConfig;
    /**
     * 
     * @type {NegativeBalanceRecoveryConfig}
     * @memberof ConfigurationRequestDto
     */
    'negative_balance_recovery_config'?: NegativeBalanceRecoveryConfig;
    /**
     * Timezone of a merchant
     * @type {string}
     * @memberof ConfigurationRequestDto
     */
    'timezone'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof ConfigurationRequestDto
     */
    'currency'?: Currency;
}

/**
 * 
 * @export
 * @interface ConfigurationResponseDto
 */
export interface ConfigurationResponseDto {
    /**
     * 
     * @type {CardPricing}
     * @memberof ConfigurationResponseDto
     */
    'card_pricing'?: CardPricing;
    /**
     * 
     * @type {AchPricing}
     * @memberof ConfigurationResponseDto
     */
    'ach_pricing'?: AchPricing;
    /**
     * 
     * @type {BacsPricing}
     * @memberof ConfigurationResponseDto
     */
    'bacs_pricing'?: BacsPricing;
    /**
     * 
     * @type {SepaPricing}
     * @memberof ConfigurationResponseDto
     */
    'sepa_pricing'?: SepaPricing;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurationResponseDto
     */
    'allow_refund_for_insufficient_balance'?: boolean;
    /**
     * 
     * @type {RollingReserveConfig}
     * @memberof ConfigurationResponseDto
     */
    'rolling_reserve_config'?: RollingReserveConfig;
    /**
     * 
     * @type {UpfrontReserveConfig}
     * @memberof ConfigurationResponseDto
     */
    'upfront_reserve_config'?: UpfrontReserveConfig;
    /**
     * 
     * @type {NegativeBalanceRecoveryConfig}
     * @memberof ConfigurationResponseDto
     */
    'negative_balance_recovery_config'?: NegativeBalanceRecoveryConfig;
    /**
     * Timezone of a merchant
     * @type {string}
     * @memberof ConfigurationResponseDto
     */
    'timezone'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof ConfigurationResponseDto
     */
    'currency'?: Currency;
}

/**
 * 
 * @export
 * @interface ConsumerDto
 */
export interface ConsumerDto {
    /**
     * The first name of the consumer.
     * @type {string}
     * @memberof ConsumerDto
     */
    'first_name'?: string;
    /**
     * The last name of the consumer.
     * @type {string}
     * @memberof ConsumerDto
     */
    'last_name'?: string;
    /**
     * The phone number of the consumer.
     * @type {string}
     * @memberof ConsumerDto
     */
    'phone'?: string;
    /**
     * The email address of the consumer.
     * @type {string}
     * @memberof ConsumerDto
     */
    'email'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ConsumerDto
     */
    'address'?: AddressDto;
}

/**
 * 
 * @export
 * @interface ConsumerFieldsRequestDto
 */
export interface ConsumerFieldsRequestDto {
    /**
     * The first name of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsRequestDto
     */
    'first_name'?: string;
    /**
     * The last name of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsRequestDto
     */
    'last_name'?: string;
    /**
     * The phone number of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsRequestDto
     */
    'phone'?: string;
    /**
     * The email address of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsRequestDto
     */
    'email'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ConsumerFieldsRequestDto
     */
    'address'?: AddressDto;
}

/**
 * 
 * @export
 * @interface ConsumerFieldsResponseDto
 */
export interface ConsumerFieldsResponseDto {
    /**
     * A unique identifier of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsResponseDto
     */
    'id'?: string;
    /**
     * The identifier of the merchant to which the consumer belongs
     * @type {string}
     * @memberof ConsumerFieldsResponseDto
     */
    'merchant_id'?: string;
    /**
     * The first name of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsResponseDto
     */
    'first_name'?: string;
    /**
     * The last name of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsResponseDto
     */
    'last_name'?: string;
    /**
     * The phone number of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsResponseDto
     */
    'phone'?: string;
    /**
     * The email address of the consumer.
     * @type {string}
     * @memberof ConsumerFieldsResponseDto
     */
    'email'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ConsumerFieldsResponseDto
     */
    'address'?: AddressDto;
    /**
     * The date when the consumer has been created.
     * @type {Date}
     * @memberof ConsumerFieldsResponseDto
     */
    'created_at'?: Date;
    /**
     * The date when the consumer details has been updated.
     * @type {Date}
     * @memberof ConsumerFieldsResponseDto
     */
    'updated_at'?: Date;
}

/**
 * 
 * @export
 * @interface ConsumerRequestDto
 */
export interface ConsumerRequestDto extends ConsumerFieldsRequestDto {
}

/**
 * 
 * @export
 * @interface ConsumerResponseDto
 */
export interface ConsumerResponseDto extends ConsumerFieldsResponseDto {
}

/**
 * The <a href= \"https://www.iso.org/iso- 3166- country- codes.html\" target=\"_blank\"> three-letter country codes</a>.
 * @export
 * @enum {string}
 */
export enum CountryCode {
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALA = 'ALA',
  ALB = 'ALB',
  AND = 'AND',
  ANT = 'ANT',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATF = 'ATF',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  CZE = 'CZE',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  EAC = 'EAC',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESH = 'ESH',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUF = 'GUF',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HMD = 'HMD',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IC = 'IC',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIR = 'NIR',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  PYF = 'PYF',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SGS = 'SGS',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  STP = 'STP',
  SUR = 'SUR',
  SVK = 'SVK',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TWN = 'TWN',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  UMI = 'UMI',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  XKX = 'XKX',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE'
}

/**
 * 
 * @export
 * @interface CreateExportRequestDto
 */
export interface CreateExportRequestDto {
    /**
     * 
     * @type {ResourceType}
     * @memberof CreateExportRequestDto
     */
    'resource_type': ResourceType;
    /**
     * 
     * @type {TransactionFilter}
     * @memberof CreateExportRequestDto
     */
    'transaction_filter'?: TransactionFilter;
    /**
     * 
     * @type {SaleFilter}
     * @memberof CreateExportRequestDto
     */
    'sale_filter'?: SaleFilter;
    /**
     * 
     * @type {RefundFilter}
     * @memberof CreateExportRequestDto
     */
    'refund_filter'?: RefundFilter;
    /**
     * 
     * @type {SettlementFilter}
     * @memberof CreateExportRequestDto
     */
    'deposit_filter'?: SettlementFilter;
    /**
     * 
     * @type {DisputeFilter}
     * @memberof CreateExportRequestDto
     */
    'dispute_filter'?: DisputeFilter;
    /**
     * 
     * @type {AuthorizationFilter}
     * @memberof CreateExportRequestDto
     */
    'authorization_filter'?: AuthorizationFilter;
}

/**
 * 
 * @export
 * @interface CreateSandboxMerchantRequest
 */
export interface CreateSandboxMerchantRequest {
    /**
     * The site name of the merchant.
     * @type {string}
     * @memberof CreateSandboxMerchantRequest
     */
    'site_name': string;
    /**
     * The email address of the principal representative.
     * @type {string}
     * @memberof CreateSandboxMerchantRequest
     */
    'email'?: string;
    /**
     * The country of business organisation.
     * @type {string}
     * @memberof CreateSandboxMerchantRequest
     */
    'country'?: string;
}

/**
 * 
 * @export
 * @interface CreateSessionRequestDto
 */
export interface CreateSessionRequestDto {
    /**
     * Displays the merchant name involved in the session.
     * @type {string}
     * @memberof CreateSessionRequestDto
     */
    'merchant_name'?: string;
    /**
     * Name of the operation intended with this session. - ONBOARDING - STATUS - LOGIN - PAYFAC_INPUT 
     * @type {string}
     * @memberof CreateSessionRequestDto
     */
    'operation': CreateSessionRequestDto.OperationEnum;
    /**
     * A unique external identifier of the onboarding merchant.
     * @type {string}
     * @memberof CreateSessionRequestDto
     */
    'id': string;
    /**
     * External URL to which the app is redirected while exiting the onboarding application.
     * @type {string}
     * @memberof CreateSessionRequestDto
     */
    'callback_url'?: string;
    /**
     * For login operation, merchant_id must be passed for those merchants that are not created via onboarding steps (chargebee-app sandbox site and via postman)
     * @type {string}
     * @memberof CreateSessionRequestDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequestDto
     */
    'redirect_path'?: string;
    /**
     * 
     * @type {PayfacType}
     * @memberof CreateSessionRequestDto
     */
    'payfac'?: PayfacType;
    /**
     * 
     * @type {SessionAdditionalInfo}
     * @memberof CreateSessionRequestDto
     */
    'additional_info'?: SessionAdditionalInfo;
}

/**
 * @export
 * @namespace CreateSessionRequestDto
 */
export namespace CreateSessionRequestDto {
  /**
    * @export
    * @enum {string}
    */
  export enum OperationEnum {
    ONBOARDING = 'ONBOARDING',
    STATUS = 'STATUS',
    LOGIN = 'LOGIN',
    PAYFACINPUT = 'PAYFAC_INPUT'
    }
}

/**
 * 
 * @export
 * @interface CreateSessionResponseDto
 */
export interface CreateSessionResponseDto {
    /**
     * Unique URL that redirects from your application to the payment application.
     * @type {string}
     * @memberof CreateSessionResponseDto
     */
    'session_url'?: string;
    /**
     * Session token
     * @type {string}
     * @memberof CreateSessionResponseDto
     */
    'token'?: string;
    /**
     * 
     * @type {PayfacType}
     * @memberof CreateSessionResponseDto
     */
    'payfac'?: PayfacType;
    /**
     * 
     * @type {SessionAdditionalInfo}
     * @memberof CreateSessionResponseDto
     */
    'additional_info'?: SessionAdditionalInfo;
}

/**
 * 
 * @export
 * @interface CreateTransactionRequestDto
 */
export interface CreateTransactionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequestDto
     */
    'consumer_id'?: string;
    /**
     * 
     * @type {ConsumerFieldsRequestDto}
     * @memberof CreateTransactionRequestDto
     */
    'consumer'?: ConsumerFieldsRequestDto;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequestDto
     */
    'payment_instrument_id'?: string;
    /**
     * 
     * @type {PaymentInstrumentsRequestDto}
     * @memberof CreateTransactionRequestDto
     */
    'payment_instrument'?: PaymentInstrumentsRequestDto;
    /**
     * The total transaction amount will be debited in cents.
     * @type {number}
     * @memberof CreateTransactionRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateTransactionRequestDto
     */
    'currency': Currency;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequestDto
     */
    'fraud_session_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequestDto
     */
    'ip_address'?: string;
    /**
     * 
     * @type {AdditionalPurchaseData}
     * @memberof CreateTransactionRequestDto
     */
    'additional_purchase_data'?: AdditionalPurchaseData;
    /**
     * 
     * @type {Additional3DSData}
     * @memberof CreateTransactionRequestDto
     */
    'additional_3ds_data'?: Additional3DSData;
    /**
     * 
     * @type {PaymentInitiatingType}
     * @memberof CreateTransactionRequestDto
     */
    'initiating_type': PaymentInitiatingType;
}

/**
 * 
 * @export
 * @interface CreateTransactionResponseDto
 */
export interface CreateTransactionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResponseDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {ConsumerFieldsResponseDto}
     * @memberof CreateTransactionResponseDto
     */
    'consumer'?: ConsumerFieldsResponseDto;
    /**
     * 
     * @type {TransactionType}
     * @memberof CreateTransactionResponseDto
     */
    'type'?: TransactionType;
    /**
     * 
     * @type {Currency}
     * @memberof CreateTransactionResponseDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionResponseDto
     */
    'amount'?: number;
    /**
     * 
     * @type {TransactionState}
     * @memberof CreateTransactionResponseDto
     */
    'state'?: TransactionState;
    /**
     * 
     * @type {ThreeDsStatus}
     * @memberof CreateTransactionResponseDto
     */
    'three_ds_status'?: ThreeDsStatus;
    /**
     * 
     * @type {PaymentInstrumentsResponseDto}
     * @memberof CreateTransactionResponseDto
     */
    'payment_instrument'?: PaymentInstrumentsResponseDto;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResponseDto
     */
    'error_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResponseDto
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResponseDto
     */
    'action_for_3ds'?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateTransactionResponseDto
     */
    'created_at'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateTransactionResponseDto
     */
    'updated_at'?: Date;
}

/**
 * [Three-letter ISO](https://www.iso.org/iso-4217-currency-codes.html) currency code. 
 * @export
 * @enum {string}
 */
export enum Currency {
  AED = 'AED',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CZK = 'CZK',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HUF = 'HUF',
  ILS = 'ILS',
  INR = 'INR',
  ISK = 'ISK',
  JMD = 'JMD',
  KES = 'KES',
  KGS = 'KGS',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  MAD = 'MAD',
  MDL = 'MDL',
  MKD = 'MKD',
  MMK = 'MMK',
  MOP = 'MOP',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  SAR = 'SAR',
  SBD = 'SBD',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SOS = 'SOS',
  SRD = 'SRD',
  THB = 'THB',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  WST = 'WST',
  XCD = 'XCD',
  ZAR = 'ZAR',
  ZMW = 'ZMW'
}

/**
 * Cvc status of the card. - VALID - INVALID - UNKNOWN 
 * @export
 * @enum {string}
 */
export enum CvcStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface DashboardResponseData
 */
export interface DashboardResponseData {
    /**
     * Timestamp of the aggregated data
     * @type {Date}
     * @memberof DashboardResponseData
     */
    'time'?: Date;
    /**
     * Value of the aggregated data
     * @type {number}
     * @memberof DashboardResponseData
     */
    'value'?: number;
}

/**
 * 
 * @export
 * @interface DashboardResponseDto
 */
export interface DashboardResponseDto {
    /**
     * The aggregated count
     * @type {number}
     * @memberof DashboardResponseDto
     */
    'total'?: number;
    /**
     * Percentage
     * @type {number}
     * @memberof DashboardResponseDto
     */
    'percentage'?: number;
    /**
     * The data of the aggregated value.
     * @type {Array<DashboardResponseData>}
     * @memberof DashboardResponseDto
     */
    'data'?: Array<DashboardResponseData>;
}

/**
 * 
 * @export
 * @interface DepositDto
 */
export interface DepositDto {
    /**
     * The unique identifier of the deposit.
     * @type {string}
     * @memberof DepositDto
     */
    'id'?: string;
    /**
     * The unique identifier of the merchant for whom the deposit belongs to.
     * @type {string}
     * @memberof DepositDto
     */
    'merchant_id'?: string;
    /**
     * The unique identifier of the settlement for which the deposit belongs to.
     * @type {string}
     * @memberof DepositDto
     */
    'settlement_id'?: string;
    /**
     * 
     * @type {DepositType}
     * @memberof DepositDto
     */
    'type'?: DepositType;
    /**
     * 
     * @type {DepositSubType}
     * @memberof DepositDto
     */
    'subType'?: DepositSubType;
    /**
     * 
     * @type {DepositStatus}
     * @memberof DepositDto
     */
    'status'?: DepositStatus;
    /**
     * The total amount of the deposit.
     * @type {number}
     * @memberof DepositDto
     */
    'amount'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof DepositDto
     */
    'currency'?: Currency;
    /**
     * The transaction failure reason.
     * @type {string}
     * @memberof DepositDto
     */
    'error_reason'?: string;
    /**
     * The transaction failure reason code.
     * @type {string}
     * @memberof DepositDto
     */
    'error_code'?: string;
}

/**
 * 
 * @export
 * @interface DepositDtoList
 */
export interface DepositDtoList {
    /**
     * Total number of deposits available for the given merchant.
     * @type {number}
     * @memberof DepositDtoList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<DepositDto>}
     * @memberof DepositDtoList
     */
    'deposits'?: Array<DepositDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof DepositDtoList
     */
    'cursor'?: string;
}

/**
 * 
 * @export
 * @interface DepositFilter
 */
export interface DepositFilter extends PaginationAndAmountFilter {
    /**
     * Status of the Deposit
     * @type {Array<DepositStatus>}
     * @memberof DepositFilter
     */
    'status'?: Array<DepositStatus>;
    /**
     * Type of the Deposit
     * @type {Array<DepositType>}
     * @memberof DepositFilter
     */
    'type'?: Array<DepositType>;
    /**
     * SubType of the Deposit
     * @type {Array<DepositSubType>}
     * @memberof DepositFilter
     */
    'sub_type'?: Array<DepositSubType>;
    /**
     * Page from which this export is requested
     * @type {string}
     * @memberof DepositFilter
     */
    'requested_from'?: string;
}

/**
 * Status of the deposit.   - PENDING   - FAILED   - SUCCEEDED   - CANCELED 
 * @export
 * @enum {string}
 */
export enum DepositStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  CANCELED = 'CANCELED'
}

/**
 * Subtype of the deposit.   - SETTLEMENT_MERCHANT   - SETTLEMENT_PLATFORM 
 * @export
 * @enum {string}
 */
export enum DepositSubType {
  MERCHANT = 'SETTLEMENT_MERCHANT',
  PLATFORM = 'SETTLEMENT_PLATFORM'
}

/**
 * Type of the deposit.   - DEBIT   - CREDIT 
 * @export
 * @enum {string}
 */
export enum DepositType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}

/**
 * 
 * @export
 * @interface DigitalGoodsDetails
 */
export interface DigitalGoodsDetails {
    /**
     * 
     * @type {Date}
     * @memberof DigitalGoodsDetails
     */
    'purchase_date'?: Date;
    /**
     * 
     * @type {string}
     * @memberof DigitalGoodsDetails
     */
    'purchase_details'?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalGoodsDetails
     */
    'refund_policy_details'?: string;
}

/**
 * 
 * @export
 * @interface DisputeDtoList
 */
export interface DisputeDtoList {
    /**
     * Total number of disputes raised for the given merchant.
     * @type {number}
     * @memberof DisputeDtoList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<DisputeResponseDto>}
     * @memberof DisputeDtoList
     */
    'disputes'?: Array<DisputeResponseDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof DisputeDtoList
     */
    'cursor'?: string;
}

/**
 * 
 * @export
 * @interface DisputeEvidenceDto
 */
export interface DisputeEvidenceDto {
    /**
     * 
     * @type {string}
     * @memberof DisputeEvidenceDto
     */
    'customer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeEvidenceDto
     */
    'customer_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeEvidenceDto
     */
    'type_of_purchase': DisputeEvidenceDto.TypeOfPurchaseEnum;
    /**
     * 
     * @type {PhysicalGoodsDetails}
     * @memberof DisputeEvidenceDto
     */
    'physical_goods_details'?: PhysicalGoodsDetails;
    /**
     * 
     * @type {DigitalGoodsDetails}
     * @memberof DisputeEvidenceDto
     */
    'digital_goods_details'?: DigitalGoodsDetails;
    /**
     * 
     * @type {ServiceDetails}
     * @memberof DisputeEvidenceDto
     */
    'service_details'?: ServiceDetails;
    /**
     * 
     * @type {DisputeFileEvidenceRequestDto}
     * @memberof DisputeEvidenceDto
     */
    'file_evidences': DisputeFileEvidenceRequestDto;
}

/**
 * @export
 * @namespace DisputeEvidenceDto
 */
export namespace DisputeEvidenceDto {
  /**
    * @export
    * @enum {string}
    */
  export enum TypeOfPurchaseEnum {
    PHYSICALGOODS = 'PHYSICAL_GOODS',
    DIGITALGOODS = 'DIGITAL_GOODS',
    SERVICE = 'SERVICE'
    }
}

/**
 * 
 * @export
 * @interface DisputeEvidenceResponseDto
 */
export interface DisputeEvidenceResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DisputeEvidenceResponseDto
     */
    'customer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeEvidenceResponseDto
     */
    'customer_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeEvidenceResponseDto
     */
    'type_of_purchase'?: DisputeEvidenceResponseDto.TypeOfPurchaseEnum;
    /**
     * 
     * @type {PhysicalGoodsDetails}
     * @memberof DisputeEvidenceResponseDto
     */
    'physical_goods_details'?: PhysicalGoodsDetails;
    /**
     * 
     * @type {DigitalGoodsDetails}
     * @memberof DisputeEvidenceResponseDto
     */
    'digital_goods_details'?: DigitalGoodsDetails;
    /**
     * 
     * @type {ServiceDetails}
     * @memberof DisputeEvidenceResponseDto
     */
    'service_details'?: ServiceDetails;
    /**
     * 
     * @type {DisputeFileEvidenceResponseDto}
     * @memberof DisputeEvidenceResponseDto
     */
    'file_evidences'?: DisputeFileEvidenceResponseDto;
}

/**
 * @export
 * @namespace DisputeEvidenceResponseDto
 */
export namespace DisputeEvidenceResponseDto {
  /**
    * @export
    * @enum {string}
    */
  export enum TypeOfPurchaseEnum {
    PHYSICALGOODS = 'PHYSICAL_GOODS',
    DIGITALGOODS = 'DIGITAL_GOODS',
    SERVICE = 'SERVICE'
    }
}

/**
 * 
 * @export
 * @interface DisputeFileEvidence
 */
export interface DisputeFileEvidence {
    /**
     * 
     * @type {string}
     * @memberof DisputeFileEvidence
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeFileEvidence
     */
    'file_id'?: string;
    /**
     * Name of the uploaded file.
     * @type {string}
     * @memberof DisputeFileEvidence
     */
    'file_name'?: string;
    /**
     * 
     * @type {FileUploadStatus}
     * @memberof DisputeFileEvidence
     */
    'status'?: FileUploadStatus;
    /**
     * The date of evidence submission.
     * @type {Date}
     * @memberof DisputeFileEvidence
     */
    'created_at'?: Date;
}

/**
 * 
 * @export
 * @interface DisputeFileEvidenceRequestDto
 */
export interface DisputeFileEvidenceRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DisputeFileEvidenceRequestDto
     */
    'files'?: Array<string>;
}

/**
 * 
 * @export
 * @interface DisputeFileEvidenceResponseDto
 */
export interface DisputeFileEvidenceResponseDto {
    /**
     * 
     * @type {Array<DisputeFileEvidence>}
     * @memberof DisputeFileEvidenceResponseDto
     */
    'evidences'?: Array<DisputeFileEvidence>;
}

/**
 * 
 * @export
 * @interface DisputeFilter
 */
export interface DisputeFilter extends PaginationAndAmountFilter {
    /**
     * ID of the transaction for which dispute is raised
     * @type {string}
     * @memberof DisputeFilter
     */
    'transaction_id'?: string;
    /**
     * Type
     * @type {Array<DisputeType>}
     * @memberof DisputeFilter
     */
    'type'?: Array<DisputeType>;
    /**
     * Status
     * @type {Array<DisputeStatus>}
     * @memberof DisputeFilter
     */
    'status'?: Array<DisputeStatus>;
    /**
     * Payment Method Type
     * @type {Array<PaymentMethodType>}
     * @memberof DisputeFilter
     */
    'payment_method_type'?: Array<PaymentMethodType>;
    /**
     * Page from which this export is requested
     * @type {string}
     * @memberof DisputeFilter
     */
    'requested_from'?: string;
}

/**
 * Reason for the dispute.   - CLERICAL   - FRAUD   - INQUIRY   - QUALITY   - TECHNICAL 
 * @export
 * @enum {string}
 */
export enum DisputeReason {
  CLERICAL = 'CLERICAL',
  FRAUD = 'FRAUD',
  INQUIRY = 'INQUIRY',
  QUALITY = 'QUALITY',
  TECHNICAL = 'TECHNICAL'
}

/**
 * 
 * @export
 * @interface DisputeResponseDto
 */
export interface DisputeResponseDto {
    /**
     * A unique identifier of the dispute.
     * @type {string}
     * @memberof DisputeResponseDto
     */
    'id'?: string;
    /**
     * A unique identifier of the consumer who raised the dispute. performed.
     * @type {string}
     * @memberof DisputeResponseDto
     */
    'consumer_id'?: string;
    /**
     * A unique identifier of the merchant associated with the dispute. performed.
     * @type {string}
     * @memberof DisputeResponseDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof DisputeResponseDto
     */
    'currency'?: Currency;
    /**
     * The total amount in the transactions disputed by the consumer.
     * @type {number}
     * @memberof DisputeResponseDto
     */
    'amount'?: number;
    /**
     * A unique identifier of the source sale transaction.
     * @type {string}
     * @memberof DisputeResponseDto
     */
    'source_transaction_id'?: string;
    /**
     * Additional detail about the dispute.
     * @type {string}
     * @memberof DisputeResponseDto
     */
    'message'?: string;
    /**
     * The next action required to move forward with the dispute.
     * @type {string}
     * @memberof DisputeResponseDto
     */
    'action'?: string;
    /**
     * 
     * @type {DisputeReason}
     * @memberof DisputeResponseDto
     */
    'reason'?: DisputeReason;
    /**
     * 
     * @type {DisputeStatus}
     * @memberof DisputeResponseDto
     */
    'status'?: DisputeStatus;
    /**
     * 
     * @type {DisputeType}
     * @memberof DisputeResponseDto
     */
    'type'?: DisputeType;
    /**
     * The date and time when the dispute was created.
     * @type {Date}
     * @memberof DisputeResponseDto
     */
    'occurred_at'?: Date;
    /**
     * Point in time, the merchant needs to respond to the dispute by.
     * @type {Date}
     * @memberof DisputeResponseDto
     */
    'respond_by'?: Date;
    /**
     * The date and time when the evidence was last uploaded for the dispute.
     * @type {Date}
     * @memberof DisputeResponseDto
     */
    'responded_at'?: Date;
    /**
     * The date and time when the dispute was resolved.
     * @type {Date}
     * @memberof DisputeResponseDto
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {ConsumerResponseDto}
     * @memberof DisputeResponseDto
     */
    'consumer'?: ConsumerResponseDto;
    /**
     * 
     * @type {PaymentInstrumentsResponseDto}
     * @memberof DisputeResponseDto
     */
    'payment_instrument'?: PaymentInstrumentsResponseDto;
    /**
     * 
     * @type {DisputeEvidenceDto}
     * @memberof DisputeResponseDto
     */
    'evidence_info'?: DisputeEvidenceDto;
}

/**
 * Status of the dispute.   - PENDING   - INQUIRY   - RESPONDED   - ACCEPTED   - ARBITRATION   - WON   - LOST   - SUSPECTED_FRAUD 
 * @export
 * @enum {string}
 */
export enum DisputeStatus {
  PENDING = 'PENDING',
  INQUIRY = 'INQUIRY',
  RESPONDED = 'RESPONDED',
  ACCEPTED = 'ACCEPTED',
  ARBITRATION = 'ARBITRATION',
  WON = 'WON',
  LOST = 'LOST',
  SUSPECTEDFRAUD = 'SUSPECTED_FRAUD'
}

/**
 * Type of the dispute   - INQUIRY   - CHARGEBACK 
 * @export
 * @enum {string}
 */
export enum DisputeType {
  INQUIRY = 'INQUIRY',
  CHARGEBACK = 'CHARGEBACK'
}

/**
 * 
 * @export
 * @interface DownloadExportDto
 */
export interface DownloadExportDto {
    /**
     * Pre-signed URL for the download.
     * @type {string}
     * @memberof DownloadExportDto
     */
    'url'?: string;
}

/**
 * 
 * @export
 * @interface DownloadFileDto
 */
export interface DownloadFileDto {
    /**
     * Pre-signed URL for the download.
     * @type {string}
     * @memberof DownloadFileDto
     */
    'url'?: string;
}

/**
 * 
 * @export
 * @interface DraftMerchantRequestDto
 */
export interface DraftMerchantRequestDto extends MerchantRequest {
}

/**
 * 
 * @export
 * @interface DraftMerchantResponseDto
 */
export interface DraftMerchantResponseDto extends DraftMerchantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DraftMerchantResponseDto
     */
    'draft_id'?: string;
}

/**
 * 
 * @export
 * @interface DunningAttemptResponseDto
 */
export interface DunningAttemptResponseDto {
    /**
     * The unique identifier of the transaction.
     * @type {string}
     * @memberof DunningAttemptResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {TransactionState}
     * @memberof DunningAttemptResponseDto
     */
    'state'?: TransactionState;
    /**
     * The transaction failure reason.
     * @type {string}
     * @memberof DunningAttemptResponseDto
     */
    'error_reason'?: string;
    /**
     * The date of transaction creation.
     * @type {Date}
     * @memberof DunningAttemptResponseDto
     */
    'created_at'?: Date;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
  DISPUTEPENDING = 'DISPUTE_PENDING',
  DISPUTERESPONSEREMINDER = 'DISPUTE_RESPONSE_REMINDER',
  DISPUTEADDITIONALEVIDENCEREQUIRED = 'DISPUTE_ADDITIONAL_EVIDENCE_REQUIRED',
  DISPUTEWON = 'DISPUTE_WON',
  DISPUTELOST = 'DISPUTE_LOST',
  SETTLEMENTAPPROVED = 'SETTLEMENT_APPROVED',
  DEPOSITSUCCEEDED = 'DEPOSIT_SUCCEEDED',
  DEPOSITFAILED = 'DEPOSIT_FAILED',
  COMPLIANCEFORMFILEUPLOADED = 'COMPLIANCE_FORM_FILE_UPLOADED',
  MERCHANTBALANCEACCOUNTNEGATIVE = 'MERCHANT_BALANCE_ACCOUNT_NEGATIVE',
  FUNDADDEDTORESERVE = 'FUND_ADDED_TO_RESERVE'
}

/**
 * 
 * @export
 * @interface ExportDto
 */
export interface ExportDto {
    /**
     * A unique identifier of an export.
     * @type {string}
     * @memberof ExportDto
     */
    'id'?: string;
    /**
     * 
     * @type {ResourceType}
     * @memberof ExportDto
     */
    'resource_type'?: ResourceType;
    /**
     * 
     * @type {ExportStatus}
     * @memberof ExportDto
     */
    'status'?: ExportStatus;
    /**
     * The expiry date of export.
     * @type {Date}
     * @memberof ExportDto
     */
    'expires_at'?: Date;
    /**
     * The date of export creation.
     * @type {Date}
     * @memberof ExportDto
     */
    'created_at'?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    'requested_from'?: string;
}

/**
 * 
 * @export
 * @interface ExportDtoList
 */
export interface ExportDtoList {
    /**
     * Total number of records available for the given merchant and filters.
     * @type {number}
     * @memberof ExportDtoList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ExportDto>}
     * @memberof ExportDtoList
     */
    'exports'?: Array<ExportDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof ExportDtoList
     */
    'cursor'?: string;
}

/**
 * Status of an export.   - REQUESTED   - INITIATED   - COMPLETED   - FAILED 
 * @export
 * @enum {string}
 */
export enum ExportStatus {
  REQUESTED = 'REQUESTED',
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

/**
 * Reason code category for merchant provisioning failure. * NEEDS_ATTENTION: Corrections needed * DECLINED: Cannot provision the merchant 
 * @export
 * @enum {string}
 */
export enum FailureReasonCategory {
  NEEDSATTENTION = 'NEEDS_ATTENTION',
  DECLINED = 'DECLINED'
}

/**
 * 
 * @export
 * @interface FeeEstimateRequestDto
 */
export interface FeeEstimateRequestDto {
    /**
     * The total amount in cents used for calculating fees.
     * @type {number}
     * @memberof FeeEstimateRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {Currency}
     * @memberof FeeEstimateRequestDto
     */
    'currency': Currency;
    /**
     * 
     * @type {BankAccountType}
     * @memberof FeeEstimateRequestDto
     */
    'bank_account_type': BankAccountType;
}

/**
 * 
 * @export
 * @interface FeeEstimateResponseDto
 */
export interface FeeEstimateResponseDto {
    /**
     * Primary identifier of the merchant for which fee estimate is given.
     * @type {string}
     * @memberof FeeEstimateResponseDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {BankAccountType}
     * @memberof FeeEstimateResponseDto
     */
    'bank_account_type'?: BankAccountType;
    /**
     * Indicate the calculated estimated fee for the amount in cent.
     * @type {number}
     * @memberof FeeEstimateResponseDto
     */
    'estimated_fee'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof FeeEstimateResponseDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {FeeEstimateResponseDtoFeeSummary}
     * @memberof FeeEstimateResponseDto
     */
    'fee_summary'?: FeeEstimateResponseDtoFeeSummary;
}

/**
 * 
 * @export
 * @interface FeeEstimateResponseDtoFeeSummary
 */
export interface FeeEstimateResponseDtoFeeSummary {
    /**
     * Total fixed fee in cent.
     * @type {number}
     * @memberof FeeEstimateResponseDtoFeeSummary
     */
    'fixed_fee'?: number;
    /**
     * Total basis points fee in cent.
     * @type {number}
     * @memberof FeeEstimateResponseDtoFeeSummary
     */
    'basis_points_fee'?: number;
}

/**
 * 
 * @export
 * @interface FetchSessionResponseDto
 */
export interface FetchSessionResponseDto {
    /**
     * Displays the merchant name involved in the session.
     * @type {string}
     * @memberof FetchSessionResponseDto
     */
    'merchant_name'?: string;
    /**
     * A unique identifier of the merchant involved in this session.
     * @type {string}
     * @memberof FetchSessionResponseDto
     */
    'merchant_id'?: string;
    /**
     * A unique external identifier of the onboarding merchant.
     * @type {string}
     * @memberof FetchSessionResponseDto
     */
    'external_id'?: string;
    /**
     * A unique identifier of the onboarding draft.
     * @type {string}
     * @memberof FetchSessionResponseDto
     */
    'onboarding_draft_id'?: string;
    /**
     * URL to which the application acknowledges on completing the onboarding steps.
     * @type {string}
     * @memberof FetchSessionResponseDto
     */
    'callback_url'?: string;
    /**
     * 
     * @type {PayfacType}
     * @memberof FetchSessionResponseDto
     */
    'payfac'?: PayfacType;
    /**
     * 
     * @type {SessionAdditionalInfo}
     * @memberof FetchSessionResponseDto
     */
    'additional_info'?: SessionAdditionalInfo;
}

/**
 * Type of the verification file.   - IDENTIFICATION_CARD_FRONT   - IDENTIFICATION_CARD_BACK   - PASSPORT   - DRIVERS_LICENSE_FRONT   - DRIVERS_LICENSE_BACK   - TAX_DOCUMENT   - BUSINESS_REGISTRATION   - BUSINESS_ADDRESS_VERIFICATION   - BANK_STATEMENT   - PCI_DOCUMENT   - OTHER 
 * @export
 * @enum {string}
 */
export enum FileType {
  IDENTIFICATIONCARDFRONT = 'IDENTIFICATION_CARD_FRONT',
  IDENTIFICATIONCARDBACK = 'IDENTIFICATION_CARD_BACK',
  PASSPORT = 'PASSPORT',
  DRIVERSLICENSEFRONT = 'DRIVERS_LICENSE_FRONT',
  DRIVERSLICENSEBACK = 'DRIVERS_LICENSE_BACK',
  TAXDOCUMENT = 'TAX_DOCUMENT',
  BUSINESSREGISTRATION = 'BUSINESS_REGISTRATION',
  BUSINESSADDRESSVERIFICATION = 'BUSINESS_ADDRESS_VERIFICATION',
  BANKSTATEMENT = 'BANK_STATEMENT',
  PCIDOCUMENT = 'PCI_DOCUMENT',
  OTHER = 'OTHER'
}

/**
 * 
 * @export
 * @interface FileUploadDto
 */
export interface FileUploadDto {
    /**
     * A unique identifier of the file upload.
     * @type {string}
     * @memberof FileUploadDto
     */
    'id'?: string;
    /**
     * Name of the uploaded file.
     * @type {string}
     * @memberof FileUploadDto
     */
    'file_name'?: string;
    /**
     * 
     * @type {FileUploadStatus}
     * @memberof FileUploadDto
     */
    'status'?: FileUploadStatus;
    /**
     * 
     * @type {FileType}
     * @memberof FileUploadDto
     */
    'file_type'?: FileType;
}

/**
 * 
 * @export
 * @interface FileUploadRequestDto
 */
export interface FileUploadRequestDto {
    /**
     * 
     * @type {ResourceType}
     * @memberof FileUploadRequestDto
     */
    'resource_type': ResourceType;
    /**
     * A unique identifier of the file upload.
     * @type {string}
     * @memberof FileUploadRequestDto
     */
    'resource_id': string;
    /**
     * Name of the file to be uploaded
     * @type {string}
     * @memberof FileUploadRequestDto
     */
    'file_name': string;
}

/**
 * 
 * @export
 * @interface FileUploadResponseDto
 */
export interface FileUploadResponseDto {
    /**
     * A unique identifier of the file upload.
     * @type {string}
     * @memberof FileUploadResponseDto
     */
    'id'?: string;
    /**
     * The url in which file needs to be uploaded.
     * @type {string}
     * @memberof FileUploadResponseDto
     */
    'url'?: string;
}

/**
 * Upload status of the file   - REQUIRES_UPLOAD: A file still needs to be uploaded to the file object.   - PENDING: The file is being reviewed   - INVALID: The file has an error or anomaly detected in it.   - UPLOADED: The file has been uploaded successfully. 
 * @export
 * @enum {string}
 */
export enum FileUploadStatus {
  REQUIRESUPLOAD = 'REQUIRES_UPLOAD',
  PENDING = 'PENDING',
  INVALID = 'INVALID',
  UPLOADED = 'UPLOADED'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {FileType}
     * @memberof InlineObject
     */
    'file_type': FileType;
    /**
     * The verification file to be uploaded. The valid file extentions are `jpeg`, `jpg`, `png`, `tiff`, and `pdf`.
     * @type {any}
     * @memberof InlineObject
     */
    'file': any;
}

/**
 * Interval of the Dashboard Metric.   - DAY   - HOUR 
 * @export
 * @enum {string}
 */
export enum Interval {
  DAY = 'DAY',
  HOUR = 'HOUR'
}

/**
 * 
 * @export
 * @interface ItemData
 */
export interface ItemData {
    /**
     * The number of items purchased.
     * @type {number}
     * @memberof ItemData
     */
    'quantity'?: number;
    /**
     * Description of purchased item
     * @type {string}
     * @memberof ItemData
     */
    'item_description'?: string;
    /**
     * A numeric code representing a particular product or service as defined by the National Institute of Governmental Purchasing.
     * @type {string}
     * @memberof ItemData
     */
    'commodity_code'?: string;
    /**
     * Total cost in cents of the line item excluding tax.
     * @type {number}
     * @memberof ItemData
     */
    'amount_excluding_sales_tax'?: number;
    /**
     * Total cost in cents of the line item including tax.
     * @type {number}
     * @memberof ItemData
     */
    'amount_including_sales_tax'?: number;
    /**
     * The price in cents of one unit of the item purchased.
     * @type {number}
     * @memberof ItemData
     */
    'cost_per_unit'?: number;
    /**
     * Item discount amount in cents.
     * @type {number}
     * @memberof ItemData
     */
    'item_discount_amount'?: number;
    /**
     * Product code associated with the merchant
     * @type {string}
     * @memberof ItemData
     */
    'merchant_product_code'?: string;
    /**
     * The unit of measure of the purchased item.
     * @type {string}
     * @memberof ItemData
     */
    'unit_of_measure'?: string;
}

/**
 * 
 * @export
 * @interface MerchantAddressDto
 */
export interface MerchantAddressDto extends AddressDto {
}

/**
 * 
 * @export
 * @interface MerchantBalanceAccountResponseDto
 */
export interface MerchantBalanceAccountResponseDto {
    /**
     * The unique identifier of the merchant balance account.
     * @type {string}
     * @memberof MerchantBalanceAccountResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {MerchantBalanceAccountType}
     * @memberof MerchantBalanceAccountResponseDto
     */
    'type'?: MerchantBalanceAccountType;
    /**
     * The amount present in the balance account.
     * @type {number}
     * @memberof MerchantBalanceAccountResponseDto
     */
    'amount'?: number;
    /**
     * The amount on the way to the balance account.
     * @type {number}
     * @memberof MerchantBalanceAccountResponseDto
     */
    'pending_amount'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof MerchantBalanceAccountResponseDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {BalanceAccountAdditionalInfo}
     * @memberof MerchantBalanceAccountResponseDto
     */
    'additional_info'?: BalanceAccountAdditionalInfo;
}

/**
 * Type of the merchant balance account.  - CURRENT  - REFUND_RESERVE  - CHARGEBACK_RESERVE  - ROLLING_RESERVE  - UPFRONT_RESERVE 
 * @export
 * @enum {string}
 */
export enum MerchantBalanceAccountType {
  CURRENT = 'CURRENT',
  REFUNDRESERVE = 'REFUND_RESERVE',
  CHARGEBACKRESERVE = 'CHARGEBACK_RESERVE',
  ROLLINGRESERVE = 'ROLLING_RESERVE',
  UPFRONTRESERVE = 'UPFRONT_RESERVE'
}

/**
 * 
 * @export
 * @interface MerchantBalanceAccountsResponseDto
 */
export interface MerchantBalanceAccountsResponseDto {
    /**
     * 
     * @type {Array<MerchantBalanceAccountResponseDto>}
     * @memberof MerchantBalanceAccountsResponseDto
     */
    'merchantBalanceAccounts'?: Array<MerchantBalanceAccountResponseDto>;
}

/**
 * 
 * @export
 * @interface MerchantDtoList
 */
export interface MerchantDtoList {
    /**
     * 
     * @type {string}
     * @memberof MerchantDtoList
     */
    'cursor'?: string;
    /**
     * 
     * @type {Array<MerchantResponseDto>}
     * @memberof MerchantDtoList
     */
    'merchants'?: Array<MerchantResponseDto>;
}

/**
 * 
 * @export
 * @interface MerchantFileUploadRequestDto
 */
export interface MerchantFileUploadRequestDto {
    /**
     * A unique identifier of the file upload.
     * @type {string}
     * @memberof MerchantFileUploadRequestDto
     */
    'id': string;
    /**
     * 
     * @type {FileUploadStatus}
     * @memberof MerchantFileUploadRequestDto
     */
    'status'?: FileUploadStatus;
    /**
     * 
     * @type {FileType}
     * @memberof MerchantFileUploadRequestDto
     */
    'file_type': FileType;
    /**
     * A reference identifier of the file.
     * @type {string}
     * @memberof MerchantFileUploadRequestDto
     */
    'reference'?: string;
}

/**
 * 
 * @export
 * @interface MerchantPatchDocument
 */
export interface MerchantPatchDocument extends PatchDocument {
}

/**
 * @export
 * @namespace MerchantPatchDocument
 */
export namespace MerchantPatchDocument {
}

/**
 * 
 * @export
 * @interface MerchantRequest
 */
export interface MerchantRequest {
    /**
     * The first name of the merchant’s principal representative.
     * @type {string}
     * @memberof MerchantRequest
     */
    'first_name'?: string;
    /**
     * The last name of the merchant’s principal representative.
     * @type {string}
     * @memberof MerchantRequest
     */
    'last_name'?: string;
    /**
     * Corporate designation or role of the principal representative. For example, `Cheif Executive Officer`.
     * @type {string}
     * @memberof MerchantRequest
     */
    'title'?: string;
    /**
     * Percentage of company share owned by the principal representative. For example, Cheif Executive Officer owns `30` percent of the company share.
     * @type {number}
     * @memberof MerchantRequest
     */
    'principal_percentage_ownership'?: number;
    /**
     * 
     * @type {TaxInfo}
     * @memberof MerchantRequest
     */
    'tax_info'?: TaxInfo;
    /**
     * The date of birth of the principal representative.
     * @type {string}
     * @memberof MerchantRequest
     */
    'dob'?: string;
    /**
     * The phone number of the principal representative.
     * @type {string}
     * @memberof MerchantRequest
     */
    'phone'?: string;
    /**
     * The email address of the principal representative.
     * @type {string}
     * @memberof MerchantRequest
     */
    'email'?: string;
    /**
     * 
     * @type {MerchantAddressDto}
     * @memberof MerchantRequest
     */
    'personal_address'?: MerchantAddressDto;
    /**
     * 
     * @type {BusinessInfo}
     * @memberof MerchantRequest
     */
    'business_info'?: BusinessInfo;
    /**
     * 
     * @type {ProcessingInfo}
     * @memberof MerchantRequest
     */
    'processing_info'?: ProcessingInfo;
    /**
     * list of additional beneficiaries associated with the merchant
     * @type {Array<BeneficiaryDto>}
     * @memberof MerchantRequest
     */
    'additional_beneficiaries'?: Array<BeneficiaryDto>;
    /**
     * Indicates if the onboarding draft save action was triggered automatically (true) or explicitly by the user (false)
     * @type {boolean}
     * @memberof MerchantRequest
     */
    'auto_save'?: boolean;
}

/**
 * 
 * @export
 * @interface MerchantRequestDto
 */
export interface MerchantRequestDto extends MerchantRequest {
    /**
     * 
     * @type {PaymentInstrumentsRequestDto}
     * @memberof MerchantRequestDto
     */
    'payment_instrument'?: PaymentInstrumentsRequestDto;
}

/**
 * 
 * @export
 * @interface MerchantResponseDto
 */
export interface MerchantResponseDto {
    /**
     * The unique identifier generated for a merchant.
     * @type {string}
     * @memberof MerchantResponseDto
     */
    'id': string;
    /**
     * The first name of the merchant’s principal representative.
     * @type {string}
     * @memberof MerchantResponseDto
     */
    'first_name': string;
    /**
     * The last name of the merchant’s principal representative.
     * @type {string}
     * @memberof MerchantResponseDto
     */
    'last_name': string;
    /**
     * Corporate designation or role of the principal representative. For example, `Cheif Executive Officer`.
     * @type {string}
     * @memberof MerchantResponseDto
     */
    'title': string;
    /**
     * Percentage of company share owned by the principal representative. For example, Cheif Executive Officer owns `30` percent of the company share.
     * @type {number}
     * @memberof MerchantResponseDto
     */
    'principal_percentage_ownership': number;
    /**
     * 
     * @type {TaxInfo}
     * @memberof MerchantResponseDto
     */
    'tax_info': TaxInfo;
    /**
     * The date of birth of the principal representative.
     * @type {string}
     * @memberof MerchantResponseDto
     */
    'dob': string;
    /**
     * The phone number of the principal representative.
     * @type {string}
     * @memberof MerchantResponseDto
     */
    'phone': string;
    /**
     * The email address of the principal representative.
     * @type {string}
     * @memberof MerchantResponseDto
     */
    'email': string;
    /**
     * 
     * @type {MerchantAddressDto}
     * @memberof MerchantResponseDto
     */
    'personal_address': MerchantAddressDto;
    /**
     * 
     * @type {BusinessInfo}
     * @memberof MerchantResponseDto
     */
    'business_info': BusinessInfo;
    /**
     * 
     * @type {ProcessingInfo}
     * @memberof MerchantResponseDto
     */
    'processing_info': ProcessingInfo;
    /**
     * list of payment instruments associated with the entity
     * @type {Array<PaymentInstrumentsResponseDto>}
     * @memberof MerchantResponseDto
     */
    'payment_instruments'?: Array<PaymentInstrumentsResponseDto>;
    /**
     * list of payfac associated with the merchant
     * @type {Array<PayfacDetailResponseDto>}
     * @memberof MerchantResponseDto
     */
    'payfac_details'?: Array<PayfacDetailResponseDto>;
    /**
     * list of additional beneficiaries associated with the merchant
     * @type {Array<BeneficiaryDto>}
     * @memberof MerchantResponseDto
     */
    'additional_beneficiaries'?: Array<BeneficiaryDto>;
    /**
     * The date of merchant creation.
     * @type {Date}
     * @memberof MerchantResponseDto
     */
    'created_at'?: Date;
    /**
     * The date of merchant updation.
     * @type {Date}
     * @memberof MerchantResponseDto
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {PayfacType}
     * @memberof MerchantResponseDto
     */
    'payfac'?: PayfacType;
    /**
     * 
     * @type {MerchantTags}
     * @memberof MerchantResponseDto
     */
    'tags'?: MerchantTags;
}

/**
 * 
 * @export
 * @interface MerchantTags
 */
export interface MerchantTags {
    /**
     * 
     * @type {string}
     * @memberof MerchantTags
     */
    'cb_merchant_handle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantTags
     */
    'cb_site_name'?: string;
}

/**
 * Metrics of the Dashboard.   - GROSS_VOLUME   - REFUNDS   - CHARGEBACKS   - DEPOSITS   - TRANSACTIONS   - PAYMENT_ACCEPTANCE_RATE   - CHARGEBACK_RATE   - INQUIRIES   - TOTAL_ACTIVE_CUSTOMERS   - NEW_CUSTOMERS 
 * @export
 * @enum {string}
 */
export enum Metric {
  GROSSVOLUME = 'GROSS_VOLUME',
  REFUNDS = 'REFUNDS',
  CHARGEBACKS = 'CHARGEBACKS',
  DEPOSITS = 'DEPOSITS',
  TRANSACTIONS = 'TRANSACTIONS',
  PAYMENTACCEPTANCERATE = 'PAYMENT_ACCEPTANCE_RATE',
  CHARGEBACKRATE = 'CHARGEBACK_RATE',
  INQUIRIES = 'INQUIRIES',
  TOTALACTIVECUSTOMERS = 'TOTAL_ACTIVE_CUSTOMERS',
  NEWCUSTOMERS = 'NEW_CUSTOMERS'
}

/**
 * 
 * @export
 * @interface MobilePhone
 */
export interface MobilePhone {
    /**
     * The country code of mobile phone number.
     * @type {string}
     * @memberof MobilePhone
     */
    'country_code'?: string;
    /**
     * The shopper's mobile phone number.
     * @type {string}
     * @memberof MobilePhone
     */
    'number'?: string;
}

/**
 * negative balance recovery configurations of merchant
 * @export
 * @interface NegativeBalanceRecoveryConfig
 */
export interface NegativeBalanceRecoveryConfig {
    /**
     * number of days for negative balance recovery
     * @type {number}
     * @memberof NegativeBalanceRecoveryConfig
     */
    'auto_recovery_days': number;
}

/**
 * Types of company ownership. PUBLIC company is owned and traded publicly or PRIVATE company is owned and traded privately.
 * @export
 * @enum {string}
 */
export enum OwnershipType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

/**
 * 
 * @export
 * @interface PaginationAndAmountFilter
 */
export interface PaginationAndAmountFilter extends PaginationFilter {
    /**
     * Amount greater than the specified amount
     * @type {number}
     * @memberof PaginationAndAmountFilter
     */
    'amount_gt'?: number;
    /**
     * Amount greater than or equal to the specified amount
     * @type {number}
     * @memberof PaginationAndAmountFilter
     */
    'amount_gte'?: number;
    /**
     * Amount lesser than the specified amount
     * @type {number}
     * @memberof PaginationAndAmountFilter
     */
    'amount_lt'?: number;
    /**
     * Amount lesser than or equal to the specified amount
     * @type {number}
     * @memberof PaginationAndAmountFilter
     */
    'amount_lte'?: number;
}

/**
 * 
 * @export
 * @interface PaginationFilter
 */
export interface PaginationFilter {
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof PaginationFilter
     */
    'cursor'?: string;
    /**
     * 
     * @type {SortBy}
     * @memberof PaginationFilter
     */
    'sort_by'?: SortBy;
    /**
     * 
     * @type {Sort}
     * @memberof PaginationFilter
     */
    'sort'?: Sort;
    /**
     * The number of items to return.
     * @type {number}
     * @memberof PaginationFilter
     */
    'size'?: number;
    /**
     * Start date from which the entities will be listed. For example, 09-11-2022 will be considered as the start date and accept data on or after 09-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof PaginationFilter
     */
    'start_date'?: Date;
    /**
     * End date until which the entities will be listed. For example, 09-11-2022 will be considered as the end date and accept data before 10-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof PaginationFilter
     */
    'end_date'?: Date;
}

/**
 * A JSON Patch document as defined by RFC 6902. A JSON Patch document is just a JSON file containing an array of patch operations.
 * @export
 * @interface PatchDocument
 */
export interface PatchDocument {
    /**
     * The operation to be performed. Use the following operation. - replace 
     * @type {string}
     * @memberof PatchDocument
     */
    'op': PatchDocument.OpEnum;
    /**
     * A JSON Pointer that defines a string syntax for identifying a specific value within a JSON document. example: /failure_reason_code
     * @type {string}
     * @memberof PatchDocument
     */
    'path': string;
    /**
     * The value to be used within the operations.
     * @type {any}
     * @memberof PatchDocument
     */
    'value'?: any;
    /**
     * A string that contains the value of JSON Pointer.
     * @type {string}
     * @memberof PatchDocument
     */
    'from'?: string;
}

/**
 * @export
 * @namespace PatchDocument
 */
export namespace PatchDocument {
  /**
    * @export
    * @enum {string}
    */
  export enum OpEnum {
    Replace = 'replace'
    }
}

/**
 * 
 * @export
 * @interface PayfacDetailResponseDto
 */
export interface PayfacDetailResponseDto {
    /**
     * 
     * @type {PayfacType}
     * @memberof PayfacDetailResponseDto
     */
    'payfac'?: PayfacType;
    /**
     * The provisioning status of the merchant.
     * @type {string}
     * @memberof PayfacDetailResponseDto
     */
    'provisioning_status'?: string;
    /**
     * Reason codes for merchant provisioning failure.
     * @type {string}
     * @memberof PayfacDetailResponseDto
     */
    'failure_reason_code'?: string;
    /**
     * 
     * @type {FailureReasonCategory}
     * @memberof PayfacDetailResponseDto
     */
    'failure_reason_category'?: FailureReasonCategory;
    /**
     * Provisioned merchant ID given by payfac.
     * @type {string}
     * @memberof PayfacDetailResponseDto
     */
    'provisioned_id_at_payfac'?: string;
}

/**
 * Payfac identifier - FINIX - ADYEN 
 * @export
 * @enum {string}
 */
export enum PayfacType {
  FINIX = 'FINIX',
  ADYEN = 'ADYEN'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentInitiatingType {
  CIT = 'CIT',
  MIT = 'MIT'
}

/**
 * Type of payment instruments. You can use `bank_account` or `card` as payment instruments.
 * @export
 * @enum {string}
 */
export enum PaymentInstrumentType {
  BankAccount = 'bank_account',
  Card = 'card'
}

/**
 * 
 * @export
 * @interface PaymentInstrumentsRequestDto
 */
export interface PaymentInstrumentsRequestDto {
    /**
     * 
     * @type {PaymentInstrumentType}
     * @memberof PaymentInstrumentsRequestDto
     */
    'type': PaymentInstrumentType;
    /**
     * 
     * @type {BankAccount}
     * @memberof PaymentInstrumentsRequestDto
     */
    'bank_account'?: BankAccount;
    /**
     * 
     * @type {CardRequestDto}
     * @memberof PaymentInstrumentsRequestDto
     */
    'card'?: CardRequestDto;
}

/**
 * 
 * @export
 * @interface PaymentInstrumentsResponseDto
 */
export interface PaymentInstrumentsResponseDto {
    /**
     * A unique identifier of the payment instrument.
     * @type {string}
     * @memberof PaymentInstrumentsResponseDto
     */
    'id': string;
    /**
     * A unique identifier of the merchant.
     * @type {string}
     * @memberof PaymentInstrumentsResponseDto
     */
    'merchant_id'?: string;
    /**
     * A unique identifier of the consumer.
     * @type {string}
     * @memberof PaymentInstrumentsResponseDto
     */
    'consumer_id'?: string;
    /**
     * 
     * @type {PaymentInstrumentType}
     * @memberof PaymentInstrumentsResponseDto
     */
    'type'?: PaymentInstrumentType;
    /**
     * 
     * @type {BankAccountDto}
     * @memberof PaymentInstrumentsResponseDto
     */
    'bank_account'?: BankAccountDto;
    /**
     * 
     * @type {CardResponseDto}
     * @memberof PaymentInstrumentsResponseDto
     */
    'card'?: CardResponseDto;
}

/**
 * Type of payment instruments. You can use `bank_account` or `card` as payment instruments.
 * @export
 * @enum {string}
 */
export enum PaymentMethodType {
  BANKACCOUNT = 'BANK_ACCOUNT',
  CARD = 'CARD'
}

/**
 * 
 * @export
 * @interface PhysicalGoodsDetails
 */
export interface PhysicalGoodsDetails {
    /**
     * 
     * @type {string}
     * @memberof PhysicalGoodsDetails
     */
    'shipping_tracking_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalGoodsDetails
     */
    'delivery_partner'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalGoodsDetails
     */
    'purchase_details'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalGoodsDetails
     */
    'refund_policy_details'?: string;
}

/**
 * 
 * @export
 * @interface ProblemDto
 */
export interface ProblemDto {
    /**
     * 
     * @type {string}
     * @memberof ProblemDto
     */
    'error_id'?: string;
    /**
     * A URI reference that uniquely identifies the problem type only in the context of the provided API. Opposed to the specification in RFC-7807, it is neither recommended to be dereferencable and point to a human-readable documentation nor globally unique for the problem type.
     * @type {string}
     * @memberof ProblemDto
     */
    'type'?: string;
    /**
     * A short summary of the problem type. Written in English and readable for engineers, usually not suited for non technical stakeholders and not localized.
     * @type {string}
     * @memberof ProblemDto
     */
    'title'?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem.
     * @type {number}
     * @memberof ProblemDto
     */
    'status'?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem that is helpful to locate the problem and give advice on how to proceed.
     * @type {string}
     * @memberof ProblemDto
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDto
     */
    'instance'?: string;
    /**
     * A time stamp representation of the time instant of the specific occurance of the problem.
     * @type {Date}
     * @memberof ProblemDto
     */
    'timestamp'?: Date;
}

/**
 * 
 * @export
 * @interface ProcessingInfo
 */
export interface ProcessingInfo {
    /**
     * Billing descriptor is the way a company's name appears on a buyer’s bank or card statement.
     * @type {string}
     * @memberof ProcessingInfo
     */
    'statement_descriptor': string;
    /**
     * Merchant's acceptance to the terms and conditions of the merchant agreement.
     * @type {boolean}
     * @memberof ProcessingInfo
     */
    'merchant_agreement_accepted': boolean;
    /**
     * IP address of the merchant when the merchant accepted the merchant agreement.
     * @type {string}
     * @memberof ProcessingInfo
     */
    'merchant_agreement_ip_address'?: string;
    /**
     * Timestamp when the merchant accepted the merchant agreement.
     * @type {Date}
     * @memberof ProcessingInfo
     */
    'merchant_agreement_timestamp': Date;
    /**
     * The user agent of the browser when the merchant accepted the merchant agreement.
     * @type {string}
     * @memberof ProcessingInfo
     */
    'merchant_agreement_user_agent': string;
}

/**
 * 
 * @export
 * @interface RefundDto
 */
export interface RefundDto {
    /**
     * A unique identifier of the refund.
     * @type {string}
     * @memberof RefundDto
     */
    'id'?: string;
    /**
     * A unique identifier of the consumer for whom the refunds are performed.
     * @type {string}
     * @memberof RefundDto
     */
    'consumer_id'?: string;
    /**
     * A unique identifier of the merchant for whom the refunds are performed.
     * @type {string}
     * @memberof RefundDto
     */
    'merchant_id'?: string;
    /**
     * A unique identifier of the payment instrument that is used for fund transaction.
     * @type {string}
     * @memberof RefundDto
     */
    'payment_instrument_id'?: string;
    /**
     * A unique identifier of the refund transaction.
     * @type {string}
     * @memberof RefundDto
     */
    'transaction_id'?: string;
    /**
     * A unique identifier of the source sale transaction.
     * @type {string}
     * @memberof RefundDto
     */
    'source_transaction_id'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof RefundDto
     */
    'currency'?: Currency;
    /**
     * The total amount that will be refunded in cents.
     * @type {number}
     * @memberof RefundDto
     */
    'amount'?: number;
    /**
     * 
     * @type {RefundStatus}
     * @memberof RefundDto
     */
    'status'?: RefundStatus;
    /**
     * The refund failure reason.
     * @type {string}
     * @memberof RefundDto
     */
    'error_reason'?: string;
    /**
     * The refund failure reason code.
     * @type {string}
     * @memberof RefundDto
     */
    'error_code'?: string;
    /**
     * The date of refund creation.
     * @type {Date}
     * @memberof RefundDto
     */
    'created_at'?: Date;
    /**
     * The date of refund updation.
     * @type {Date}
     * @memberof RefundDto
     */
    'updated_at'?: Date;
}

/**
 * 
 * @export
 * @interface RefundDtoListWithPagination
 */
export interface RefundDtoListWithPagination {
    /**
     * Total number of refunds available for the given merchant.
     * @type {number}
     * @memberof RefundDtoListWithPagination
     */
    'count'?: number;
    /**
     * 
     * @type {Array<RefundResponseDto>}
     * @memberof RefundDtoListWithPagination
     */
    'refunds'?: Array<RefundResponseDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof RefundDtoListWithPagination
     */
    'cursor'?: string;
}

/**
 * 
 * @export
 * @interface RefundFilter
 */
export interface RefundFilter extends PaginationAndAmountFilter {
    /**
     * Status
     * @type {Array<RefundStatus>}
     * @memberof RefundFilter
     */
    'status'?: Array<RefundStatus>;
    /**
     * Type
     * @type {Array<RefundType>}
     * @memberof RefundFilter
     */
    'type'?: Array<RefundType>;
    /**
     * Payment Method Type
     * @type {Array<PaymentMethodType>}
     * @memberof RefundFilter
     */
    'payment_method_type'?: Array<PaymentMethodType>;
    /**
     * Page from which this export is requested
     * @type {string}
     * @memberof RefundFilter
     */
    'requested_from'?: string;
}

/**
 * 
 * @export
 * @interface RefundResponseDto
 */
export interface RefundResponseDto {
    /**
     * A unique identifier of the refund.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'id'?: string;
    /**
     * A unique identifier of the consumer for whom the refunds are performed.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'consumer_id'?: string;
    /**
     * 
     * @type {RefundType}
     * @memberof RefundResponseDto
     */
    'type'?: RefundType;
    /**
     * A unique identifier of the merchant for whom the refunds are performed.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'merchant_id'?: string;
    /**
     * A unique identifier of the payment instrument that is used for fund transaction.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'payment_instrument_id'?: string;
    /**
     * A unique identifier of the refund transaction.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'transaction_id'?: string;
    /**
     * A unique identifier of the source sale transaction.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'source_transaction_id'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof RefundResponseDto
     */
    'currency'?: Currency;
    /**
     * The total amount that will be refunded in cents.
     * @type {number}
     * @memberof RefundResponseDto
     */
    'amount'?: number;
    /**
     * 
     * @type {RefundStatus}
     * @memberof RefundResponseDto
     */
    'status'?: RefundStatus;
    /**
     * The refund failure reason.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'error_reason'?: string;
    /**
     * The refund failure reason code.
     * @type {string}
     * @memberof RefundResponseDto
     */
    'error_code'?: string;
    /**
     * The date of refund creation.
     * @type {Date}
     * @memberof RefundResponseDto
     */
    'created_at'?: Date;
    /**
     * The date of refund updation.
     * @type {Date}
     * @memberof RefundResponseDto
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {ConsumerResponseDto}
     * @memberof RefundResponseDto
     */
    'consumer'?: ConsumerResponseDto;
    /**
     * 
     * @type {PaymentInstrumentsResponseDto}
     * @memberof RefundResponseDto
     */
    'payment_instrument'?: PaymentInstrumentsResponseDto;
}

/**
 * 
 * @export
 * @interface RefundResponseDtoList
 */
export interface RefundResponseDtoList {
    /**
     * 
     * @type {Array<RefundResponseDto>}
     * @memberof RefundResponseDtoList
     */
    'refunds'?: Array<RefundResponseDto>;
}

/**
 * Status of the refund.   - SUCCEEDED   - FAILED 
 * @export
 * @enum {string}
 */
export enum RefundStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

/**
 * 
 * @export
 * @interface RefundTransactionRequestDto
 */
export interface RefundTransactionRequestDto {
    /**
     * The total refund amount will be credited in cents.
     * @type {number}
     * @memberof RefundTransactionRequestDto
     */
    'amount': number;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RefundType {
  REFUND = 'REFUND',
  ACHRETURN = 'ACH_RETURN',
  PAYMENTRETURN = 'PAYMENT_RETURN',
  PAYMENTREVERSAL = 'PAYMENT_REVERSAL'
}

/**
 * 
 * @export
 * @interface ReportDataDto
 */
export interface ReportDataDto {
    /**
     * The summary report URL for the merchant
     * @type {string}
     * @memberof ReportDataDto
     */
    'summary_url'?: string;
    /**
     * The transactions report URL for the merchant
     * @type {string}
     * @memberof ReportDataDto
     */
    'transactions_url'?: string;
    /**
     * The payouts report URL for the merchant
     * @type {string}
     * @memberof ReportDataDto
     */
    'payouts_url'?: string;
    /**
     * The disputes report URL for the merchant
     * @type {string}
     * @memberof ReportDataDto
     */
    'disputes_url'?: string;
}

/**
 * 
 * @export
 * @interface ReportDto
 */
export interface ReportDto {
    /**
     * The unique identifier of the settlement.
     * @type {string}
     * @memberof ReportDto
     */
    'id'?: string;
    /**
     * The unique identifier of the merchant for whom the settlement belongs to.
     * @type {string}
     * @memberof ReportDto
     */
    'merchant_id'?: string;
    /**
     * The metrics summary for the merchant
     * @type {string}
     * @memberof ReportDto
     */
    'summary'?: string;
    /**
     * 
     * @type {ReportStatus}
     * @memberof ReportDto
     */
    'status'?: ReportStatus;
}

/**
 * 
 * @export
 * @interface ReportListDto
 */
export interface ReportListDto {
    /**
     * 
     * @type {Array<ReportDto>}
     * @memberof ReportListDto
     */
    'new_reports'?: Array<ReportDto>;
    /**
     * 
     * @type {Array<ReportDto>}
     * @memberof ReportListDto
     */
    'existing_reports'?: Array<ReportDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportListDto
     */
    'not_applicable_merchants'?: Array<string>;
}

/**
 * 
 * @export
 * @interface ReportRequestDto
 */
export interface ReportRequestDto {
    /**
     * 
     * @type {ReportType}
     * @memberof ReportRequestDto
     */
    'report_type': ReportType;
    /**
     * Start date from which the entities will be listed. For example, 09-11-2022 will be considered as the start date and accept data on or after 09-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof ReportRequestDto
     */
    'start_date': Date;
    /**
     * End date until which the entities will be listed. For example, 09-11-2022 will be considered as the end date and accept data before 10-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof ReportRequestDto
     */
    'end_date': Date;
    /**
     * List of merchant ids for whom the report needs to be generated. If not provided, report is generated for all merchants in the application.
     * @type {Array<string>}
     * @memberof ReportRequestDto
     */
    'merchant_id'?: Array<string>;
    /**
     * 
     * @type {Shard}
     * @memberof ReportRequestDto
     */
    'shard_id'?: Shard;
    /**
     * 
     * @type {boolean}
     * @memberof ReportRequestDto
     */
    'regenerate'?: boolean;
}

/**
 * State of the report.   - INITIATED   - COMPLETED   - FAILED 
 * @export
 * @enum {string}
 */
export enum ReportStatus {
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

/**
 * Types of report.
 * @export
 * @enum {string}
 */
export enum ReportType {
  CONSOLIDATED = 'CONSOLIDATED'
}

/**
 * 
 * @export
 * @interface ReserveSplitDto
 */
export interface ReserveSplitDto {
    /**
     * 
     * @type {MerchantBalanceAccountType}
     * @memberof ReserveSplitDto
     */
    'balance_account_type'?: MerchantBalanceAccountType;
    /**
     * The total amount that will be credited in cents in Custom funds Account.
     * @type {number}
     * @memberof ReserveSplitDto
     */
    'amount'?: number;
}

/**
 * Supported API resource types.   - TRANSACTION   - SALE   - REFUND   - DISPUTE   - DEPOSIT   - MERCHANT   - COMPLIANCE_FORM   - AUTHORIZATION 
 * @export
 * @enum {string}
 */
export enum ResourceType {
  TRANSACTION = 'TRANSACTION',
  SALE = 'SALE',
  REFUND = 'REFUND',
  DISPUTE = 'DISPUTE',
  DEPOSIT = 'DEPOSIT',
  MERCHANT = 'MERCHANT',
  COMPLIANCEFORM = 'COMPLIANCE_FORM',
  AUTHORIZATION = 'AUTHORIZATION'
}

/**
 * Rolling reserve configuration of merchant
 * @export
 * @interface RollingReserveConfig
 */
export interface RollingReserveConfig {
    /**
     * number of days the funds will be held
     * @type {number}
     * @memberof RollingReserveConfig
     */
    'reserve_window': number;
    /**
     * percentage of daily settlement volume (net fees) to be held
     * @type {number}
     * @memberof RollingReserveConfig
     */
    'daily_settlement_volume_percentage': number;
    /**
     * number of days after which the merchant is reviewed
     * @type {number}
     * @memberof RollingReserveConfig
     */
    'review_period': number;
}

/**
 * 
 * @export
 * @interface SaleDto
 */
export interface SaleDto {
    /**
     * A unique identifier of the sale.
     * @type {string}
     * @memberof SaleDto
     */
    'id'?: string;
    /**
     * A unique identifier of the consumer for whom the sales are performed.
     * @type {string}
     * @memberof SaleDto
     */
    'consumer_id'?: string;
    /**
     * A unique identifier of the merchant for whom the sales are performed.
     * @type {string}
     * @memberof SaleDto
     */
    'merchant_id'?: string;
    /**
     * A unique identifier of the payment instrument that is used for fund transaction.
     * @type {string}
     * @memberof SaleDto
     */
    'payment_instrument_id': string;
    /**
     * A unique identifier of the transaction.
     * @type {string}
     * @memberof SaleDto
     */
    'transaction_id'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof SaleDto
     */
    'currency': Currency;
    /**
     * The total amount that will be debited in cents.
     * @type {number}
     * @memberof SaleDto
     */
    'amount': number;
    /**
     * The fee associated with the sale in cents.
     * @type {number}
     * @memberof SaleDto
     */
    'fee'?: number;
    /**
     * 
     * @type {SaleStatus}
     * @memberof SaleDto
     */
    'status'?: SaleStatus;
    /**
     * The reason for sale failure or cancellation.
     * @type {string}
     * @memberof SaleDto
     */
    'error_reason'?: string;
    /**
     * The reason code for sale failure or cancellation.
     * @type {string}
     * @memberof SaleDto
     */
    'error_code'?: string;
    /**
     * The session ID is to review the fraud during a sale.
     * @type {string}
     * @memberof SaleDto
     */
    'fraud_session_id'?: string;
    /**
     * The date of sale creation.
     * @type {Date}
     * @memberof SaleDto
     */
    'created_at'?: Date;
    /**
     * The date of sale updation.
     * @type {Date}
     * @memberof SaleDto
     */
    'updated_at'?: Date;
}

/**
 * 
 * @export
 * @interface SaleDtoList
 */
export interface SaleDtoList {
    /**
     * Total number of sales available for the given merchant.
     * @type {number}
     * @memberof SaleDtoList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<SaleResponseDto>}
     * @memberof SaleDtoList
     */
    'sales'?: Array<SaleResponseDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof SaleDtoList
     */
    'cursor'?: string;
}

/**
 * 
 * @export
 * @interface SaleFilter
 */
export interface SaleFilter extends PaginationAndAmountFilter {
    /**
     * Type
     * @type {Array<TransactionType>}
     * @memberof SaleFilter
     */
    'type'?: Array<TransactionType>;
    /**
     * Status
     * @type {Array<SaleStatus>}
     * @memberof SaleFilter
     */
    'status'?: Array<SaleStatus>;
    /**
     * Payment Method Type
     * @type {Array<PaymentMethodType>}
     * @memberof SaleFilter
     */
    'payment_method_type'?: Array<PaymentMethodType>;
    /**
     * Page from which this export is requested
     * @type {string}
     * @memberof SaleFilter
     */
    'requested_from'?: string;
}

/**
 * 
 * @export
 * @interface SaleResponseDto
 */
export interface SaleResponseDto {
    /**
     * A unique identifier of the sale.
     * @type {string}
     * @memberof SaleResponseDto
     */
    'id'?: string;
    /**
     * A unique identifier of the merchant for whom the sales are performed.
     * @type {string}
     * @memberof SaleResponseDto
     */
    'merchant_id'?: string;
    /**
     * A unique identifier of the transaction.
     * @type {string}
     * @memberof SaleResponseDto
     */
    'transaction_id'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof SaleResponseDto
     */
    'currency'?: Currency;
    /**
     * The total amount that will be debited in cents.
     * @type {number}
     * @memberof SaleResponseDto
     */
    'amount'?: number;
    /**
     * The fee associated with the sale in cents.
     * @type {number}
     * @memberof SaleResponseDto
     */
    'fee'?: number;
    /**
     * The net amount associated with the sale in cents.
     * @type {number}
     * @memberof SaleResponseDto
     */
    'net_amount'?: number;
    /**
     * 
     * @type {SaleStatus}
     * @memberof SaleResponseDto
     */
    'status'?: SaleStatus;
    /**
     * The reason for sale failure or cancellation.
     * @type {string}
     * @memberof SaleResponseDto
     */
    'error_reason'?: string;
    /**
     * The reason code for sale failure or cancellation.
     * @type {string}
     * @memberof SaleResponseDto
     */
    'error_code'?: string;
    /**
     * The session ID is to review the fraud during a sale.
     * @type {string}
     * @memberof SaleResponseDto
     */
    'fraud_session_id'?: string;
    /**
     * The date of sale creation.
     * @type {Date}
     * @memberof SaleResponseDto
     */
    'created_at'?: Date;
    /**
     * The date of sale updation.
     * @type {Date}
     * @memberof SaleResponseDto
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {ConsumerResponseDto}
     * @memberof SaleResponseDto
     */
    'consumer'?: ConsumerResponseDto;
    /**
     * 
     * @type {PaymentInstrumentsResponseDto}
     * @memberof SaleResponseDto
     */
    'payment_instrument'?: PaymentInstrumentsResponseDto;
    /**
     * 
     * @type {TransactionTags}
     * @memberof SaleResponseDto
     */
    'tags'?: TransactionTags;
    /**
     * 
     * @type {TransactionReserveResponseDto}
     * @memberof SaleResponseDto
     */
    'transaction_reserve'?: TransactionReserveResponseDto;
}

/**
 * Status of the sale.   - CREATED   - INITIATED   - SUCCEEDED   - FAILED   - CANCELED   - RETURNED 
 * @export
 * @enum {string}
 */
export enum SaleStatus {
  CREATED = 'CREATED',
  INITIATED = 'INITIATED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  RETURNED = 'RETURNED'
}

/**
 * SEPA Pricing Model of merchant
 * @export
 * @interface SepaPricing
 */
export interface SepaPricing {
    /**
     * percent charge on every transaction
     * @type {number}
     * @memberof SepaPricing
     */
    'basis_points'?: number;
    /**
     * minimum fee on basis points
     * @type {number}
     * @memberof SepaPricing
     */
    'min_fee'?: number;
    /**
     * maximum fee on basis points
     * @type {number}
     * @memberof SepaPricing
     */
    'max_fee'?: number;
    /**
     * fixed fee on every transaction
     * @type {number}
     * @memberof SepaPricing
     */
    'fixed_rate'?: number;
    /**
     * surcharge for international transactions
     * @type {number}
     * @memberof SepaPricing
     */
    'cross_border_basis_points'?: number;
    /**
     * forex charge if applicable
     * @type {number}
     * @memberof SepaPricing
     */
    'forex_basis_points'?: number;
}

/**
 * 
 * @export
 * @interface ServiceDetails
 */
export interface ServiceDetails {
    /**
     * 
     * @type {Date}
     * @memberof ServiceDetails
     */
    'service_date'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ServiceDetails
     */
    'purchase_date'?: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetails
     */
    'type_of_service'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetails
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetails
     */
    'refund_policy_details'?: string;
}

/**
 * 
 * @export
 * @interface SessionAdditionalInfo
 */
export interface SessionAdditionalInfo {
    /**
     * 
     * @type {CardPricing}
     * @memberof SessionAdditionalInfo
     */
    'card_pricing'?: CardPricing;
    /**
     * 
     * @type {AchPricing}
     * @memberof SessionAdditionalInfo
     */
    'ach_pricing'?: AchPricing;
    /**
     * 
     * @type {BacsPricing}
     * @memberof SessionAdditionalInfo
     */
    'bacs_pricing'?: BacsPricing;
    /**
     * 
     * @type {SepaPricing}
     * @memberof SessionAdditionalInfo
     */
    'sepa_pricing'?: SepaPricing;
    /**
     * whether to allow refund if the current balance account doesn't have the required funds
     * @type {boolean}
     * @memberof SessionAdditionalInfo
     */
    'allow_refund_for_insufficient_balance'?: boolean;
    /**
     * 
     * @type {RollingReserveConfig}
     * @memberof SessionAdditionalInfo
     */
    'rolling_reserve_config'?: RollingReserveConfig;
    /**
     * 
     * @type {UpfrontReserveConfig}
     * @memberof SessionAdditionalInfo
     */
    'upfront_reserve_config'?: UpfrontReserveConfig;
    /**
     * 
     * @type {NegativeBalanceRecoveryConfig}
     * @memberof SessionAdditionalInfo
     */
    'negative_balance_recovery_config'?: NegativeBalanceRecoveryConfig;
    /**
     * Chargebee app merchant handle
     * @type {string}
     * @memberof SessionAdditionalInfo
     */
    'cb_merchant_handle'?: string;
    /**
     * Chargebee app site name
     * @type {string}
     * @memberof SessionAdditionalInfo
     */
    'cb_site_name'?: string;
    /**
     * Chargebee app merchant email
     * @type {string}
     * @memberof SessionAdditionalInfo
     */
    'cb_merchant_email'?: string;
    /**
     * Timezone of merchant from CB App
     * @type {string}
     * @memberof SessionAdditionalInfo
     */
    'timezone'?: string;
}

/**
 * 
 * @export
 * @interface SettleTransactionsRequestDto
 */
export interface SettleTransactionsRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SettleTransactionsRequestDto
     */
    'transaction_id'?: Array<string>;
    /**
     * Start date from which the entities will be listed. For example, 09-11-2022 will be considered as the start date and accept data on or after 09-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof SettleTransactionsRequestDto
     */
    'start_date'?: Date;
    /**
     * End date until which the entities will be listed. For example, 09-11-2022 will be considered as the end date and accept data before 10-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof SettleTransactionsRequestDto
     */
    'end_date'?: Date;
}

/**
 * 
 * @export
 * @interface SettlementBreakdown
 */
export interface SettlementBreakdown {
    /**
     * The total number of payments processed in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'numberOfPayments'?: number;
    /**
     * The sum of all payments in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'paymentsAmount'?: number;
    /**
     * The total number of refunds processed in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'numberOfRefunds'?: number;
    /**
     * The sum of all refunds in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'refundsAmount'?: number;
    /**
     * The total number of all chargeback debit transactions in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'numberOfChargebackDebits'?: number;
    /**
     * The sum of all chargeback debit transactions in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'chargebackDebitsAmount'?: number;
    /**
     * The total number of all chargeback credit transactions in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'numberOfChargebackCredits'?: number;
    /**
     * The sum of all chargeback credit transactions in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'chargebackCreditsAmount'?: number;
    /**
     * The total number of all chargeback fee transactions in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'numberOfChargebackFees'?: number;
    /**
     * The sum of all chargeback fee transactions in the Settlement.
     * @type {number}
     * @memberof SettlementBreakdown
     */
    'chargebackFeesAmount'?: number;
}

/**
 * 
 * @export
 * @interface SettlementDto
 */
export interface SettlementDto {
    /**
     * The unique identifier of the settlement.
     * @type {string}
     * @memberof SettlementDto
     */
    'id'?: string;
    /**
     * The unique identifier of the merchant for whom the settlement belongs to.
     * @type {string}
     * @memberof SettlementDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof SettlementDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {SettlementStatus}
     * @memberof SettlementDto
     */
    'status'?: SettlementStatus;
    /**
     * The total amount of the Settlement.
     * @type {number}
     * @memberof SettlementDto
     */
    'total_amount'?: number;
    /**
     * The sum of the fees in the Settlement.
     * @type {number}
     * @memberof SettlementDto
     */
    'total_fee'?: number;
    /**
     * The amount in cents that will be deposited into the merchant's bank account.
     * @type {number}
     * @memberof SettlementDto
     */
    'net_amount'?: number;
    /**
     * The date of settlement initiated at PayFac.
     * @type {Date}
     * @memberof SettlementDto
     */
    'initiated_at'?: Date;
    /**
     * The date of settlement settled at PayFac.
     * @type {Date}
     * @memberof SettlementDto
     */
    'settled_at'?: Date;
    /**
     * The date of settlement creation.
     * @type {Date}
     * @memberof SettlementDto
     */
    'created_at'?: Date;
    /**
     * The date of settlement updation.
     * @type {Date}
     * @memberof SettlementDto
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {TransactionsTimeline}
     * @memberof SettlementDto
     */
    'transactions_timeline'?: TransactionsTimeline;
    /**
     * 
     * @type {Array<DepositDto>}
     * @memberof SettlementDto
     */
    'deposits'?: Array<DepositDto>;
}

/**
 * 
 * @export
 * @interface SettlementDtoList
 */
export interface SettlementDtoList {
    /**
     * Total number of settlements available for the given merchant.
     * @type {number}
     * @memberof SettlementDtoList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<SettlementDto>}
     * @memberof SettlementDtoList
     */
    'settlements'?: Array<SettlementDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof SettlementDtoList
     */
    'cursor'?: string;
}

/**
 * 
 * @export
 * @interface SettlementFilter
 */
export interface SettlementFilter extends PaginationAndAmountFilter {
    /**
     * Transactions that are settled after this date will be listed. For example, 09-11-2022 will be considered as the start date and accept transactions settled on or after 09-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof SettlementFilter
     */
    'settled_after'?: Date;
    /**
     * Transactions that are settled before this date will be listed. For example, 09-11-2022 will be considered as the end date and accept transactions settled before 10-11-2022 00:00:00 hours
     * @type {Date}
     * @memberof SettlementFilter
     */
    'settled_before'?: Date;
    /**
     * Deposit amount greater than the specified amount
     * @type {number}
     * @memberof SettlementFilter
     */
    'deposit_amount_gt'?: number;
    /**
     * Deposit amount greater than or equal to the specified amount
     * @type {number}
     * @memberof SettlementFilter
     */
    'deposit_amount_gte'?: number;
    /**
     * Deposit amount lesser than the specified amount
     * @type {number}
     * @memberof SettlementFilter
     */
    'deposit_amount_lt'?: number;
    /**
     * Deposit amount lesser than or equal to the specified amount
     * @type {number}
     * @memberof SettlementFilter
     */
    'deposit_amount_lte'?: number;
    /**
     * Status of the Settlement
     * @type {Array<SettlementStatus>}
     * @memberof SettlementFilter
     */
    'settlement_status'?: Array<SettlementStatus>;
    /**
     * Status of the Deposit
     * @type {Array<DepositStatus>}
     * @memberof SettlementFilter
     */
    'deposit_status'?: Array<DepositStatus>;
    /**
     * Type of the Deposit
     * @type {Array<DepositType>}
     * @memberof SettlementFilter
     */
    'deposit_type'?: Array<DepositType>;
    /**
     * SubType of the Deposit
     * @type {Array<DepositSubType>}
     * @memberof SettlementFilter
     */
    'deposit_sub_type'?: Array<DepositSubType>;
    /**
     * Page from which this export is requested
     * @type {string}
     * @memberof SettlementFilter
     */
    'requested_from'?: string;
}

/**
 * Status of the settlement.   - PENDING   - AWAITING_APPROVAL   - APPROVED   - CANCELED 
 * @export
 * @enum {string}
 */
export enum SettlementStatus {
  PENDING = 'PENDING',
  AWAITINGAPPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED'
}

/**
 * 
 * @export
 * @interface SettlementWithBreakdownDto
 */
export interface SettlementWithBreakdownDto extends SettlementDto {
    /**
     * 
     * @type {SettlementBreakdown}
     * @memberof SettlementWithBreakdownDto
     */
    'breakdown'?: SettlementBreakdown;
}

/**
 * Ids of the Database shards.
 * @export
 * @enum {string}
 */
export enum Shard {
  SHARD1 = 'SHARD1'
}

/**
 * Sort order for the list.
 * @export
 * @enum {string}
 */
export enum Sort {
  DESC = 'DESC',
  ASC = 'ASC'
}

/**
 * Column to use for the list order.
 * @export
 * @enum {string}
 */
export enum SortBy {
  ID = 'ID'
}

/**
 * 
 * @export
 * @interface TaxInfo
 */
export interface TaxInfo {
    /**
     * Tax ID of merchant
     * @type {string}
     * @memberof TaxInfo
     */
    'tax_id'?: string;
    /**
     * Registration number of merchant
     * @type {string}
     * @memberof TaxInfo
     */
    'registration_number'?: string;
    /**
     * VAT number of merchant
     * @type {string}
     * @memberof TaxInfo
     */
    'vat_number'?: string;
}

/**
 * State of the 3ds transaction.   - AUTHENTICATION_FINISHED   - AUTHENTICATION_NOT_REQUIRED   - AUTHORISED   - CANCELLED   - CHALLENGE_SHOPPER   - ERROR   - IDENTIFY_SHOPPER   - PARTIALLY_AUTHORISED   - PENDING   - PRESENT_TO_SHOPPER   - RECEIVED   - REDIRECT_SHOPPER   - REFUSED   - SUCCESS 
 * @export
 * @enum {string}
 */
export enum ThreeDsStatus {
  AUTHENTICATIONFINISHED = 'AUTHENTICATION_FINISHED',
  AUTHENTICATIONNOTREQUIRED = 'AUTHENTICATION_NOT_REQUIRED',
  AUTHORISED = 'AUTHORISED',
  CANCELLED = 'CANCELLED',
  CHALLENGESHOPPER = 'CHALLENGE_SHOPPER',
  ERROR = 'ERROR',
  IDENTIFYSHOPPER = 'IDENTIFY_SHOPPER',
  PARTIALLYAUTHORISED = 'PARTIALLY_AUTHORISED',
  PENDING = 'PENDING',
  PRESENTTOSHOPPER = 'PRESENT_TO_SHOPPER',
  RECEIVED = 'RECEIVED',
  REDIRECTSHOPPER = 'REDIRECT_SHOPPER',
  REFUSED = 'REFUSED',
  SUCCESS = 'SUCCESS'
}

/**
 * 
 * @export
 * @interface TransactionAdditionalInfoDto
 */
export interface TransactionAdditionalInfoDto {
    /**
     * 
     * @type {Array<ReserveSplitDto>}
     * @memberof TransactionAdditionalInfoDto
     */
    'reserveSplit'?: Array<ReserveSplitDto>;
}

/**
 * 
 * @export
 * @interface TransactionDetailRequestDto
 */
export interface TransactionDetailRequestDto {
    /**
     * A map containing key-value pairs of additional details.
     * @type {{ [key: string]: string; }}
     * @memberof TransactionDetailRequestDto
     */
    'details'?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface TransactionDto
 */
export interface TransactionDto {
    /**
     * The unique identifier of the sale.
     * @type {string}
     * @memberof TransactionDto
     */
    'id'?: string;
    /**
     * The unique identifier of the merchant for whom the sale is initiated.
     * @type {string}
     * @memberof TransactionDto
     */
    'merchant_id'?: string;
    /**
     * The unique identifier of the consumer for whom the sale is performed.
     * @type {string}
     * @memberof TransactionDto
     */
    'consumer_id'?: string;
    /**
     * The unique identifier of a consumer's payment instrument from which the sale amount will be debited or to which the refunded transaction amount will be credited.
     * @type {string}
     * @memberof TransactionDto
     */
    'payment_instrument_id': string;
    /**
     * 
     * @type {Currency}
     * @memberof TransactionDto
     */
    'currency': Currency;
    /**
     * 
     * @type {TransactionState}
     * @memberof TransactionDto
     */
    'state'?: TransactionState;
    /**
     * 
     * @type {ThreeDsStatus}
     * @memberof TransactionDto
     */
    'three_ds_status'?: ThreeDsStatus;
    /**
     * The transaction failure reason.
     * @type {string}
     * @memberof TransactionDto
     */
    'error_reason'?: string;
    /**
     * The transaction failure reason code.
     * @type {string}
     * @memberof TransactionDto
     */
    'error_code'?: string;
    /**
     * The session ID is to review the fraud during a sale.
     * @type {string}
     * @memberof TransactionDto
     */
    'fraud_session_id'?: string;
    /**
     * The total transaction amount will be debited in cents.
     * @type {number}
     * @memberof TransactionDto
     */
    'amount': number;
    /**
     * The fee associated with the trasaction in cents.
     * @type {number}
     * @memberof TransactionDto
     */
    'fee'?: number;
    /**
     * 
     * @type {AdditionalPurchaseData}
     * @memberof TransactionDto
     */
    'additional_purchase_data'?: AdditionalPurchaseData;
    /**
     * The date of transaction creation.
     * @type {Date}
     * @memberof TransactionDto
     */
    'created_at'?: Date;
    /**
     * The date of transaction updation.
     * @type {Date}
     * @memberof TransactionDto
     */
    'updated_at'?: Date;
    /**
     * A unique identifier of the source transaction.
     * @type {string}
     * @memberof TransactionDto
     */
    'source_transaction_id'?: string;
    /**
     * 
     * @type {TransactionType}
     * @memberof TransactionDto
     */
    'type'?: TransactionType;
    /**
     * 
     * @type {TransactionTags}
     * @memberof TransactionDto
     */
    'tags'?: TransactionTags;
    /**
     * 
     * @type {PaymentInitiatingType}
     * @memberof TransactionDto
     */
    'initiating_type'?: PaymentInitiatingType;
}

/**
 * 
 * @export
 * @interface TransactionFilter
 */
export interface TransactionFilter extends PaginationAndAmountFilter {
    /**
     * Type
     * @type {Array<TransactionType>}
     * @memberof TransactionFilter
     */
    'type'?: Array<TransactionType>;
    /**
     * Status
     * @type {Array<TransactionStatus>}
     * @memberof TransactionFilter
     */
    'status'?: Array<TransactionStatus>;
    /**
     * Payment Method Type
     * @type {Array<PaymentMethodType>}
     * @memberof TransactionFilter
     */
    'payment_method_type'?: Array<PaymentMethodType>;
    /**
     * Page from which this export is requested
     * @type {string}
     * @memberof TransactionFilter
     */
    'requested_from'?: string;
}

/**
 * 
 * @export
 * @interface TransactionReserveResponseDto
 */
export interface TransactionReserveResponseDto {
    /**
     * The total transaction reserve amount in cents.
     * @type {number}
     * @memberof TransactionReserveResponseDto
     */
    'amount'?: number;
    /**
     * 
     * @type {TransactionReserveStatus}
     * @memberof TransactionReserveResponseDto
     */
    'status'?: TransactionReserveStatus;
    /**
     * The date of transaction reserve released.
     * @type {Date}
     * @memberof TransactionReserveResponseDto
     */
    'release_date'?: Date;
}

/**
 * Status of the transaction reserve.  - PENDING  - AVAILABLE  - PARTIALLY_AVAILABLE  - ADJUSTED  - RELEASED 
 * @export
 * @enum {string}
 */
export enum TransactionReserveStatus {
  PENDING = 'PENDING',
  AVAILABLE = 'AVAILABLE',
  PARTIALLYAVAILABLE = 'PARTIALLY_AVAILABLE',
  ADJUSTED = 'ADJUSTED',
  RELEASED = 'RELEASED'
}

/**
 * 
 * @export
 * @interface TransactionResponseDto
 */
export interface TransactionResponseDto {
    /**
     * The unique identifier of the transaction.
     * @type {string}
     * @memberof TransactionResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {ConsumerResponseDto}
     * @memberof TransactionResponseDto
     */
    'consumer'?: ConsumerResponseDto;
    /**
     * 
     * @type {TransactionType}
     * @memberof TransactionResponseDto
     */
    'type'?: TransactionType;
    /**
     * The unique identifier of the merchant.
     * @type {string}
     * @memberof TransactionResponseDto
     */
    'merchant_id'?: string;
    /**
     * 
     * @type {PaymentInstrumentsResponseDto}
     * @memberof TransactionResponseDto
     */
    'payment_instrument'?: PaymentInstrumentsResponseDto;
    /**
     * 
     * @type {Currency}
     * @memberof TransactionResponseDto
     */
    'currency'?: Currency;
    /**
     * The unique identifier of the settlement for which the transaction belongs to.
     * @type {string}
     * @memberof TransactionResponseDto
     */
    'settlement_id'?: string;
    /**
     * A unique identifier of the source sale transaction.
     * @type {string}
     * @memberof TransactionResponseDto
     */
    'source_transaction_id'?: string;
    /**
     * 
     * @type {TransactionState}
     * @memberof TransactionResponseDto
     */
    'state'?: TransactionState;
    /**
     * The transaction failure reason.
     * @type {string}
     * @memberof TransactionResponseDto
     */
    'error_reason'?: string;
    /**
     * The transaction failure reason code.
     * @type {string}
     * @memberof TransactionResponseDto
     */
    'error_code'?: string;
    /**
     * The session ID is to review the fraud during a sale.
     * @type {string}
     * @memberof TransactionResponseDto
     */
    'fraud_session_id'?: string;
    /**
     * The total transaction amount will be debited in cents.
     * @type {number}
     * @memberof TransactionResponseDto
     */
    'amount'?: number;
    /**
     * The fee associated with the trasaction in cents.
     * @type {number}
     * @memberof TransactionResponseDto
     */
    'fee'?: number;
    /**
     * The net amount associated with transaction in cents.
     * @type {number}
     * @memberof TransactionResponseDto
     */
    'net_amount'?: number;
    /**
     * 
     * @type {PayfacType}
     * @memberof TransactionResponseDto
     */
    'payfac'?: PayfacType;
    /**
     * The date of transaction creation.
     * @type {Date}
     * @memberof TransactionResponseDto
     */
    'created_at'?: Date;
    /**
     * The date of transaction updation.
     * @type {Date}
     * @memberof TransactionResponseDto
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {Array<DunningAttemptResponseDto>}
     * @memberof TransactionResponseDto
     */
    'dunning_attempts'?: Array<DunningAttemptResponseDto>;
    /**
     * 
     * @type {TransactionTags}
     * @memberof TransactionResponseDto
     */
    'tags'?: TransactionTags;
    /**
     * 
     * @type {TransactionAdditionalInfoDto}
     * @memberof TransactionResponseDto
     */
    'additional_info'?: TransactionAdditionalInfoDto;
    /**
     * 
     * @type {TransactionReserveResponseDto}
     * @memberof TransactionResponseDto
     */
    'transaction_reserve'?: TransactionReserveResponseDto;
}

/**
 * Status of the transaction.  - INITIATION_STARTED  - QUEUED_ACCEPTED  - QUEUED_FAILED  - QUEUED_SUCCEEDED  - INITIATION_AT_PAYFAC_FAILED  - PENDING  - VERIFICATION_PENDING  - SUCCESS  - FAILED  - CANCELED  - REFUNDED  - RETURNED  - NOT_REFUNDED 
 * @export
 * @enum {string}
 */
export enum TransactionState {
  INITIATIONSTARTED = 'INITIATION_STARTED',
  QUEUEDACCEPTED = 'QUEUED_ACCEPTED',
  QUEUEDFAILED = 'QUEUED_FAILED',
  QUEUEDSUCCEEDED = 'QUEUED_SUCCEEDED',
  INITIATIONATPAYFACFAILED = 'INITIATION_AT_PAYFAC_FAILED',
  PENDING = 'PENDING',
  VERIFICATIONPENDING = 'VERIFICATION_PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  NOTREFUNDED = 'NOT_REFUNDED',
  RETURNED = 'RETURNED'
}

/**
 * Status of the transaction.  - PENDING  - SUCCESS  - FAILED  - CANCELED  - RETURNED 
 * @export
 * @enum {string}
 */
export enum TransactionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  RETURNED = 'RETURNED'
}

/**
 * 
 * @export
 * @interface TransactionTags
 */
export interface TransactionTags {
    /**
     * 
     * @type {string}
     * @memberof TransactionTags
     */
    'cb_transaction_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionTags
     */
    'cb_refunded_transaction_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionTags
     */
    'dispute_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionTags
     */
    'parent_transaction_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionTags
     */
    'is_dunning_attempt'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionTags
     */
    'cb_invoice_ids'?: Array<string>;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionType {
  DEBIT = 'DEBIT',
  REFUND = 'REFUND',
  ACHRETURN = 'ACH_RETURN',
  PAYMENTRETURN = 'PAYMENT_RETURN',
  PAYMENTREVERSAL = 'PAYMENT_REVERSAL',
  CHARGEBACKDEBIT = 'CHARGEBACK_DEBIT',
  CHARGEBACKCREDIT = 'CHARGEBACK_CREDIT',
  CHARGEBACKFEE = 'CHARGEBACK_FEE',
  RESERVE = 'RESERVE'
}

/**
 * 
 * @export
 * @interface TransactionsResponseDto
 */
export interface TransactionsResponseDto {
    /**
     * Total number of transactions available for the given merchant and filters.
     * @type {number}
     * @memberof TransactionsResponseDto
     */
    'count'?: number;
    /**
     * 
     * @type {Array<TransactionResponseDto>}
     * @memberof TransactionsResponseDto
     */
    'transactions'?: Array<TransactionResponseDto>;
    /**
     * The unique identifier of the last item in the current result.
     * @type {string}
     * @memberof TransactionsResponseDto
     */
    'cursor'?: string;
}

/**
 * 
 * @export
 * @interface TransactionsTimeline
 */
export interface TransactionsTimeline {
    /**
     * The date when first transactions was recorded for the settlement
     * @type {Date}
     * @memberof TransactionsTimeline
     */
    'first_transaction_date'?: Date;
    /**
     * The date when last transactions was recorded for the settlement
     * @type {Date}
     * @memberof TransactionsTimeline
     */
    'last_transaction_date'?: Date;
}

/**
 * Upfront reserve configuration of merchant
 * @export
 * @interface UpfrontReserveConfig
 */
export interface UpfrontReserveConfig {
    /**
     * number of days the funds will be held
     * @type {number}
     * @memberof UpfrontReserveConfig
     */
    'reserve_window': number;
    /**
     * amount to be held for upfront reserve
     * @type {number}
     * @memberof UpfrontReserveConfig
     */
    'amount_to_be_held': number;
    /**
     * number of days after which the merchant is reviewed
     * @type {number}
     * @memberof UpfrontReserveConfig
     */
    'review_period': number;
}


/**
 * AuditsApi - fetch parameter creator
 */
const AuditsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Retrieve audit log for a resource
        * @param {string} merchantId The unique identifier of a merchant associated with the audits.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {string} [resourceId] ID of the resource
        * @param {ResourceType} [resourceType] Type of the resource
        * @param {string} [messageToExclude] Value that should not match the content of the message
        * @param {Array<EventType>} [events] Type of Event
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchAudits(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, resourceId?: string, resourceType?: ResourceType, messageToExclude?: string, events?: Array<EventType>, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchAudits.');
        }
        const localVarPath = `/merchants/{merchantId}/audits`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (resourceId !== undefined) {
          localVarQueryParameter['resource_id'] = resourceId;
        }
        if (resourceType !== undefined) {
          localVarQueryParameter['resource_type'] = resourceType;
        }
        if (messageToExclude !== undefined) {
          localVarQueryParameter['messageToExclude'] = messageToExclude;
        }
        if (events) {
          localVarQueryParameter['events'] = events.join(COLLECTION_FORMATS["csv"]);
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * AuditsApi - object-oriented interface
 * @export
 * @class AuditsApi
 */
export class AuditsApiBase {
  /**
    * 
    * @summary Retrieve audit log for a resource
    * @param {string} merchantId The unique identifier of a merchant associated with the audits.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {string} [resourceId] ID of the resource
    * @param {ResourceType} [resourceType] Type of the resource
    * @param {string} [messageToExclude] Value that should not match the content of the message
    * @param {Array<EventType>} [events] Type of Event
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof AuditsApi
    */
  public fetchAudits(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, resourceId?: string, resourceType?: ResourceType, messageToExclude?: string, events?: Array<EventType>, options?: any, additionalProperties?: any): Promise<AuditDtoList> {
    const localVarFetchArgs = AuditsApiFetchParamCreator().fetchAudits(merchantId, cursor, size, sortBy, sort, startDate, endDate, resourceId, resourceType, messageToExclude, events, options);
    return <Promise<AuditDtoList>> cbClient.cbFetch("GET", {operationId: "fetchAudits", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * AuthorizationsApi - fetch parameter creator
 */
const AuthorizationsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Fetch an authorization of a consumer for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the the consumer authorizations are performed.
        * @param {string} consumerId A unique identifier of a consumer for whom a specific authorizations are performed.
        * @param {string} authorizationId The unique identifier of an authorization of a consumer for the merchant.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchAuthorization(merchantId: string, consumerId: string, authorizationId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchAuthorization.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling fetchAuthorization.');
        }
        // verify required parameter 'authorizationId' is not null or undefined
        if (authorizationId === null || authorizationId === undefined) {
          throw new RequiredError('authorizationId','Required parameter authorizationId was null or undefined when calling fetchAuthorization.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/authorizations/{authorizationId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)))
            .replace(`{${"authorizationId"}}`, fixedEncodeURIComponent(String(authorizationId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List authorization of all consumers for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the the consumer authorizations are performed.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {Array<AuthorizationStatus>} [status] Status of the authorizations
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantAuthorizations(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, status?: Array<AuthorizationStatus>, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantAuthorizations.');
        }
        const localVarPath = `/merchants/{merchantId}/authorizations`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (status) {
          localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS["csv"]);
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * AuthorizationsApi - object-oriented interface
 * @export
 * @class AuthorizationsApi
 */
export class AuthorizationsApiBase {
  /**
    * 
    * @summary Fetch an authorization of a consumer for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the the consumer authorizations are performed.
    * @param {string} consumerId A unique identifier of a consumer for whom a specific authorizations are performed.
    * @param {string} authorizationId The unique identifier of an authorization of a consumer for the merchant.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof AuthorizationsApi
    */
  public fetchAuthorization(merchantId: string, consumerId: string, authorizationId: string, options?: any, additionalProperties?: any): Promise<AuthorizationResponseDto> {
    const localVarFetchArgs = AuthorizationsApiFetchParamCreator().fetchAuthorization(merchantId, consumerId, authorizationId, options);
    return <Promise<AuthorizationResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchAuthorization", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List authorization of all consumers for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the the consumer authorizations are performed.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {Array<AuthorizationStatus>} [status] Status of the authorizations
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof AuthorizationsApi
    */
  public listMerchantAuthorizations(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, status?: Array<AuthorizationStatus>, options?: any, additionalProperties?: any): Promise<AuthorizationsResponseDto> {
    const localVarFetchArgs = AuthorizationsApiFetchParamCreator().listMerchantAuthorizations(merchantId, cursor, size, sortBy, sort, startDate, endDate, status, options);
    return <Promise<AuthorizationsResponseDto>> cbClient.cbFetch("GET", {operationId: "listMerchantAuthorizations", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * ComplianceFormsApi - fetch parameter creator
 */
const ComplianceFormsApiFetchParamCreator = function () {
  return {
      /**
        * Download a compliance form for a merchant by signed status.
        * @summary Download a compliance form by signed status
        * @param {string} merchantId The unique identifier of a merchant for whom the compliance form belongs to.
        * @param {string} complianceFormId The unique identifier of the compliance form.
        * @param {ComplianceFormSignedStatus} signedStatus 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      downloadComplianceFormBySignedStatus(merchantId: string, complianceFormId: string, signedStatus: ComplianceFormSignedStatus, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling downloadComplianceFormBySignedStatus.');
        }
        // verify required parameter 'complianceFormId' is not null or undefined
        if (complianceFormId === null || complianceFormId === undefined) {
          throw new RequiredError('complianceFormId','Required parameter complianceFormId was null or undefined when calling downloadComplianceFormBySignedStatus.');
        }
        // verify required parameter 'signedStatus' is not null or undefined
        if (signedStatus === null || signedStatus === undefined) {
          throw new RequiredError('signedStatus','Required parameter signedStatus was null or undefined when calling downloadComplianceFormBySignedStatus.');
        }
        const localVarPath = `/merchants/{merchantId}/compliance-forms/{complianceFormId}/download`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"complianceFormId"}}`, fixedEncodeURIComponent(String(complianceFormId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (signedStatus !== undefined) {
          localVarQueryParameter['signed_status'] = signedStatus;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * Retrieve a compliance form for a merchant using compliance form ID.
        * @summary Retrieve a compliance form
        * @param {string} merchantId The unique identifier of a merchant for whom the compliance form belongs to.
        * @param {string} complianceFormId The unique identifier of the compliance form.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchComplianceFormById(merchantId: string, complianceFormId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchComplianceFormById.');
        }
        // verify required parameter 'complianceFormId' is not null or undefined
        if (complianceFormId === null || complianceFormId === undefined) {
          throw new RequiredError('complianceFormId','Required parameter complianceFormId was null or undefined when calling fetchComplianceFormById.');
        }
        const localVarPath = `/merchants/{merchantId}/compliance-forms/{complianceFormId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"complianceFormId"}}`, fixedEncodeURIComponent(String(complianceFormId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * Retrieve a list of compliance forms for a merchant using merchant ID.
        * @summary Retrieve a list of compliance forms
        * @param {string} merchantId The unique identifier of a merchant for whom the compliance forms belong to.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listComplianceForms(merchantId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listComplianceForms.');
        }
        const localVarPath = `/merchants/{merchantId}/compliance-forms`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * Store compliance form attestation details for a merchant based on compliance form ID.
        * @summary Store attestation details
        * @param {string} merchantId The unique identifier of a merchant for whom the compliance form attestation belongs.
        * @param {string} complianceFormId The unique identifier of the compliance form.
        * @param {ComplianceFormAttestationDetailsDto} complianceFormAttestationDetailsDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      submitComplianceFormAttestation(merchantId: string, complianceFormId: string, complianceFormAttestationDetailsDto: ComplianceFormAttestationDetailsDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling submitComplianceFormAttestation.');
        }
        // verify required parameter 'complianceFormId' is not null or undefined
        if (complianceFormId === null || complianceFormId === undefined) {
          throw new RequiredError('complianceFormId','Required parameter complianceFormId was null or undefined when calling submitComplianceFormAttestation.');
        }
        // verify required parameter 'complianceFormAttestationDetailsDto' is not null or undefined
        if (complianceFormAttestationDetailsDto === null || complianceFormAttestationDetailsDto === undefined) {
          throw new RequiredError('complianceFormAttestationDetailsDto','Required parameter complianceFormAttestationDetailsDto was null or undefined when calling submitComplianceFormAttestation.');
        }
        const localVarPath = `/merchants/{merchantId}/compliance-forms/{complianceFormId}/attestation`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"complianceFormId"}}`, fixedEncodeURIComponent(String(complianceFormId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = complianceFormAttestationDetailsDto
        return output;
      },
  }
};

/**
 * ComplianceFormsApi - object-oriented interface
 * @export
 * @class ComplianceFormsApi
 */
export class ComplianceFormsApiBase {
  /**
    * Download a compliance form for a merchant by signed status.
    * @summary Download a compliance form by signed status
    * @param {string} merchantId The unique identifier of a merchant for whom the compliance form belongs to.
    * @param {string} complianceFormId The unique identifier of the compliance form.
    * @param {ComplianceFormSignedStatus} signedStatus 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ComplianceFormsApi
    */
  public downloadComplianceFormBySignedStatus(merchantId: string, complianceFormId: string, signedStatus: ComplianceFormSignedStatus, options?: any, additionalProperties?: any): Promise<DownloadFileDto> {
    const localVarFetchArgs = ComplianceFormsApiFetchParamCreator().downloadComplianceFormBySignedStatus(merchantId, complianceFormId, signedStatus, options);
    return <Promise<DownloadFileDto>> cbClient.cbFetch("GET", {operationId: "downloadComplianceFormBySignedStatus", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Retrieve a compliance form for a merchant using compliance form ID.
    * @summary Retrieve a compliance form
    * @param {string} merchantId The unique identifier of a merchant for whom the compliance form belongs to.
    * @param {string} complianceFormId The unique identifier of the compliance form.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ComplianceFormsApi
    */
  public fetchComplianceFormById(merchantId: string, complianceFormId: string, options?: any, additionalProperties?: any): Promise<ComplianceFormResponseDto> {
    const localVarFetchArgs = ComplianceFormsApiFetchParamCreator().fetchComplianceFormById(merchantId, complianceFormId, options);
    return <Promise<ComplianceFormResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchComplianceFormById", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Retrieve a list of compliance forms for a merchant using merchant ID.
    * @summary Retrieve a list of compliance forms
    * @param {string} merchantId The unique identifier of a merchant for whom the compliance forms belong to.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ComplianceFormsApi
    */
  public listComplianceForms(merchantId: string, options?: any, additionalProperties?: any): Promise<ComplianceFormResponseDtoList> {
    const localVarFetchArgs = ComplianceFormsApiFetchParamCreator().listComplianceForms(merchantId, options);
    return <Promise<ComplianceFormResponseDtoList>> cbClient.cbFetch("GET", {operationId: "listComplianceForms", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Store compliance form attestation details for a merchant based on compliance form ID.
    * @summary Store attestation details
    * @param {string} merchantId The unique identifier of a merchant for whom the compliance form attestation belongs.
    * @param {string} complianceFormId The unique identifier of the compliance form.
    * @param {ComplianceFormAttestationDetailsDto} complianceFormAttestationDetailsDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ComplianceFormsApi
    */
  public submitComplianceFormAttestation(merchantId: string, complianceFormId: string, complianceFormAttestationDetailsDto: ComplianceFormAttestationDetailsDto, options?: any, additionalProperties?: any): Promise<ComplianceFormResponseDto> {
    const localVarFetchArgs = ComplianceFormsApiFetchParamCreator().submitComplianceFormAttestation(merchantId, complianceFormId, complianceFormAttestationDetailsDto, options);
    return <Promise<ComplianceFormResponseDto>> cbClient.cbFetch("POST", {operationId: "submitComplianceFormAttestation", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * ConsumersApi - fetch parameter creator
 */
const ConsumersApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Create consumer for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the consumer will be created.
        * @param {ConsumerRequestDto} [consumerRequestDto] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createConsumer(merchantId: string, consumerRequestDto?: ConsumerRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createConsumer.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = consumerRequestDto
        return output;
      },
      /**
        * 
        * @summary Fetch consumer details
        * @param {string} merchantId The unique identifier of a merchant for whom the consumer is registered.
        * @param {string} consumerId The unique identifier of the consumer to retrieve a specific consumer details.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchConsumer(merchantId: string, consumerId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchConsumer.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling fetchConsumer.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List all consumers for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom consumers are registered.
        * @param {number} [page] 
        * @param {number} [size] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listConsumersForMerchant(merchantId: string, page?: number, size?: number, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listConsumersForMerchant.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (page !== undefined) {
          localVarQueryParameter['page'] = page;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * ConsumersApi - object-oriented interface
 * @export
 * @class ConsumersApi
 */
export class ConsumersApiBase {
  /**
    * 
    * @summary Create consumer for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the consumer will be created.
    * @param {ConsumerRequestDto} [consumerRequestDto] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ConsumersApi
    */
  public createConsumer(merchantId: string, consumerRequestDto?: ConsumerRequestDto, options?: any, additionalProperties?: any): Promise<ConsumerResponseDto> {
    const localVarFetchArgs = ConsumersApiFetchParamCreator().createConsumer(merchantId, consumerRequestDto, options);
    return <Promise<ConsumerResponseDto>> cbClient.cbFetch("POST", {operationId: "createConsumer", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Fetch consumer details
    * @param {string} merchantId The unique identifier of a merchant for whom the consumer is registered.
    * @param {string} consumerId The unique identifier of the consumer to retrieve a specific consumer details.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ConsumersApi
    */
  public fetchConsumer(merchantId: string, consumerId: string, options?: any, additionalProperties?: any): Promise<ConsumerResponseDto> {
    const localVarFetchArgs = ConsumersApiFetchParamCreator().fetchConsumer(merchantId, consumerId, options);
    return <Promise<ConsumerResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchConsumer", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List all consumers for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom consumers are registered.
    * @param {number} [page] 
    * @param {number} [size] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ConsumersApi
    */
  public listConsumersForMerchant(merchantId: string, page?: number, size?: number, options?: any, additionalProperties?: any): Promise<Array<ConsumerResponseDto>> {
    const localVarFetchArgs = ConsumersApiFetchParamCreator().listConsumersForMerchant(merchantId, page, size, options);
    return <Promise<Array<ConsumerResponseDto>>> cbClient.cbFetch("GET", {operationId: "listConsumersForMerchant", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * DashboardApi - fetch parameter creator
 */
const DashboardApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Retrieve dashboard data for a merchant
        * @param {string} merchantId The unique identifier of a merchant
        * @param {Metric} metric Metric of the dashboard
        * @param {boolean} [detailedData] Retrieves the data in detail if the value is true.
        * @param {Interval} [interval] Interval of the metric
        * @param {string} [timezone] Provide any valid time zone value to represent the time in a specific geographical location.
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchMerchantDashboardData(merchantId: string, metric: Metric, detailedData?: boolean, interval?: Interval, timezone?: string, startDate?: Date, endDate?: Date, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchMerchantDashboardData.');
        }
        // verify required parameter 'metric' is not null or undefined
        if (metric === null || metric === undefined) {
          throw new RequiredError('metric','Required parameter metric was null or undefined when calling fetchMerchantDashboardData.');
        }
        const localVarPath = `/merchants/{merchantId}/dashboard/metrics`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (metric !== undefined) {
          localVarQueryParameter['metric'] = metric;
        }
        if (detailedData !== undefined) {
          localVarQueryParameter['detailed_data'] = detailedData;
        }
        if (interval !== undefined) {
          localVarQueryParameter['interval'] = interval;
        }
        if (timezone !== undefined) {
          localVarQueryParameter['timezone'] = timezone;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 */
export class DashboardApiBase {
  /**
    * 
    * @summary Retrieve dashboard data for a merchant
    * @param {string} merchantId The unique identifier of a merchant
    * @param {Metric} metric Metric of the dashboard
    * @param {boolean} [detailedData] Retrieves the data in detail if the value is true.
    * @param {Interval} [interval] Interval of the metric
    * @param {string} [timezone] Provide any valid time zone value to represent the time in a specific geographical location.
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DashboardApi
    */
  public fetchMerchantDashboardData(merchantId: string, metric: Metric, detailedData?: boolean, interval?: Interval, timezone?: string, startDate?: Date, endDate?: Date, options?: any, additionalProperties?: any): Promise<DashboardResponseDto> {
    const localVarFetchArgs = DashboardApiFetchParamCreator().fetchMerchantDashboardData(merchantId, metric, detailedData, interval, timezone, startDate, endDate, options);
    return <Promise<DashboardResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchMerchantDashboardData", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * DepositsApi - fetch parameter creator
 */
const DepositsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary List deposits for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the deposit belongs to.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {number} [amountGt] 
        * @param {number} [amountGte] 
        * @param {number} [amountLt] 
        * @param {number} [amountLte] 
        * @param {Array<DepositStatus>} [status] Status of the Deposit
        * @param {Array<DepositType>} [type] Type of the Deposit
        * @param {Array<DepositSubType>} [subType] SubType of the Deposit
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantDeposits(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, status?: Array<DepositStatus>, type?: Array<DepositType>, subType?: Array<DepositSubType>, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantDeposits.');
        }
        const localVarPath = `/merchants/{merchantId}/deposits`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (amountGt !== undefined) {
          localVarQueryParameter['amount_gt'] = amountGt;
        }
        if (amountGte !== undefined) {
          localVarQueryParameter['amount_gte'] = amountGte;
        }
        if (amountLt !== undefined) {
          localVarQueryParameter['amount_lt'] = amountLt;
        }
        if (amountLte !== undefined) {
          localVarQueryParameter['amount_lte'] = amountLte;
        }
        if (status) {
          localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS["csv"]);
        }
        if (type) {
          localVarQueryParameter['type'] = type.join(COLLECTION_FORMATS["csv"]);
        }
        if (subType) {
          localVarQueryParameter['sub_type'] = subType.join(COLLECTION_FORMATS["csv"]);
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * DepositsApi - object-oriented interface
 * @export
 * @class DepositsApi
 */
export class DepositsApiBase {
  /**
    * 
    * @summary List deposits for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the deposit belongs to.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {number} [amountGt] 
    * @param {number} [amountGte] 
    * @param {number} [amountLt] 
    * @param {number} [amountLte] 
    * @param {Array<DepositStatus>} [status] Status of the Deposit
    * @param {Array<DepositType>} [type] Type of the Deposit
    * @param {Array<DepositSubType>} [subType] SubType of the Deposit
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DepositsApi
    */
  public listMerchantDeposits(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, status?: Array<DepositStatus>, type?: Array<DepositType>, subType?: Array<DepositSubType>, options?: any, additionalProperties?: any): Promise<DepositDtoList> {
    const localVarFetchArgs = DepositsApiFetchParamCreator().listMerchantDeposits(merchantId, cursor, size, sortBy, sort, startDate, endDate, amountGt, amountGte, amountLt, amountLte, status, type, subType, options);
    return <Promise<DepositDtoList>> cbClient.cbFetch("GET", {operationId: "listMerchantDeposits", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * DisputesApi - fetch parameter creator
 */
const DisputesApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Accept the dispute
        * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
        * @param {string} disputeId The unique identifier of a dispute which is being accepted.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      acceptTheDispute(merchantId: string, disputeId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling acceptTheDispute.');
        }
        // verify required parameter 'disputeId' is not null or undefined
        if (disputeId === null || disputeId === undefined) {
          throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling acceptTheDispute.');
        }
        const localVarPath = `/merchants/{merchantId}/disputes/{disputeId}/accept`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"disputeId"}}`, fixedEncodeURIComponent(String(disputeId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Save dispute file evidence
        * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
        * @param {string} disputeId The unique identifier of a dispute to which file evidence is uploaded.
        * @param {DisputeFileEvidenceRequestDto} disputeFileEvidenceRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createDisputeFileEvidence(merchantId: string, disputeId: string, disputeFileEvidenceRequestDto: DisputeFileEvidenceRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createDisputeFileEvidence.');
        }
        // verify required parameter 'disputeId' is not null or undefined
        if (disputeId === null || disputeId === undefined) {
          throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling createDisputeFileEvidence.');
        }
        // verify required parameter 'disputeFileEvidenceRequestDto' is not null or undefined
        if (disputeFileEvidenceRequestDto === null || disputeFileEvidenceRequestDto === undefined) {
          throw new RequiredError('disputeFileEvidenceRequestDto','Required parameter disputeFileEvidenceRequestDto was null or undefined when calling createDisputeFileEvidence.');
        }
        const localVarPath = `/merchants/{merchantId}/disputes/{disputeId}/file-evidence-upload`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"disputeId"}}`, fixedEncodeURIComponent(String(disputeId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = disputeFileEvidenceRequestDto
        return output;
      },
      /**
        * 
        * @summary Fetch dispute details
        * @param {string} merchantId The unique identifier of a merchant associated with the dispute.
        * @param {string} disputeId The unique identifier of the dispute.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchDisputeDetails(merchantId: string, disputeId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchDisputeDetails.');
        }
        // verify required parameter 'disputeId' is not null or undefined
        if (disputeId === null || disputeId === undefined) {
          throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling fetchDisputeDetails.');
        }
        const localVarPath = `/merchants/{merchantId}/disputes/{disputeId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"disputeId"}}`, fixedEncodeURIComponent(String(disputeId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Get evidences for a dispute
        * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
        * @param {string} disputeId The unique identifier of the dispute.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      getEvidencesForDispute(merchantId: string, disputeId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling getEvidencesForDispute.');
        }
        // verify required parameter 'disputeId' is not null or undefined
        if (disputeId === null || disputeId === undefined) {
          throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling getEvidencesForDispute.');
        }
        const localVarPath = `/merchants/{merchantId}/disputes/{disputeId}/evidences`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"disputeId"}}`, fixedEncodeURIComponent(String(disputeId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List disputes for a merchant
        * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {string} [transactionId] ID of the transaction for which dispute is raised
        * @param {Array<DisputeStatus>} [status] Status
        * @param {Array<DisputeType>} [type] Type
        * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
        * @param {number} [amountGt] 
        * @param {number} [amountGte] 
        * @param {number} [amountLt] 
        * @param {number} [amountLte] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantDisputes(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, transactionId?: string, status?: Array<DisputeStatus>, type?: Array<DisputeType>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantDisputes.');
        }
        const localVarPath = `/merchants/{merchantId}/disputes`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (transactionId !== undefined) {
          localVarQueryParameter['transaction_id'] = transactionId;
        }
        if (status) {
          localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS["csv"]);
        }
        if (type) {
          localVarQueryParameter['type'] = type.join(COLLECTION_FORMATS["csv"]);
        }
        if (paymentMethodType) {
          localVarQueryParameter['payment_method_type'] = paymentMethodType.join(COLLECTION_FORMATS["csv"]);
        }
        if (amountGt !== undefined) {
          localVarQueryParameter['amount_gt'] = amountGt;
        }
        if (amountGte !== undefined) {
          localVarQueryParameter['amount_gte'] = amountGte;
        }
        if (amountLt !== undefined) {
          localVarQueryParameter['amount_lt'] = amountLt;
        }
        if (amountLte !== undefined) {
          localVarQueryParameter['amount_lte'] = amountLte;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Store dispute evidence details
        * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
        * @param {string} disputeId The unique identifier of a dispute to which evidence is submitted.
        * @param {DisputeEvidenceDto} disputeEvidenceDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      storeDisputeEvidenceDetails(merchantId: string, disputeId: string, disputeEvidenceDto: DisputeEvidenceDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling storeDisputeEvidenceDetails.');
        }
        // verify required parameter 'disputeId' is not null or undefined
        if (disputeId === null || disputeId === undefined) {
          throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling storeDisputeEvidenceDetails.');
        }
        // verify required parameter 'disputeEvidenceDto' is not null or undefined
        if (disputeEvidenceDto === null || disputeEvidenceDto === undefined) {
          throw new RequiredError('disputeEvidenceDto','Required parameter disputeEvidenceDto was null or undefined when calling storeDisputeEvidenceDetails.');
        }
        const localVarPath = `/merchants/{merchantId}/disputes/{disputeId}/evidence`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"disputeId"}}`, fixedEncodeURIComponent(String(disputeId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = disputeEvidenceDto
        return output;
      },
  }
};

/**
 * DisputesApi - object-oriented interface
 * @export
 * @class DisputesApi
 */
export class DisputesApiBase {
  /**
    * 
    * @summary Accept the dispute
    * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
    * @param {string} disputeId The unique identifier of a dispute which is being accepted.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DisputesApi
    */
  public acceptTheDispute(merchantId: string, disputeId: string, options?: any, additionalProperties?: any): Promise<DisputeResponseDto> {
    const localVarFetchArgs = DisputesApiFetchParamCreator().acceptTheDispute(merchantId, disputeId, options);
    return <Promise<DisputeResponseDto>> cbClient.cbFetch("POST", {operationId: "acceptTheDispute", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Save dispute file evidence
    * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
    * @param {string} disputeId The unique identifier of a dispute to which file evidence is uploaded.
    * @param {DisputeFileEvidenceRequestDto} disputeFileEvidenceRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DisputesApi
    */
  public createDisputeFileEvidence(merchantId: string, disputeId: string, disputeFileEvidenceRequestDto: DisputeFileEvidenceRequestDto, options?: any, additionalProperties?: any): Promise<DisputeFileEvidenceResponseDto> {
    const localVarFetchArgs = DisputesApiFetchParamCreator().createDisputeFileEvidence(merchantId, disputeId, disputeFileEvidenceRequestDto, options);
    return <Promise<DisputeFileEvidenceResponseDto>> cbClient.cbFetch("POST", {operationId: "createDisputeFileEvidence", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Fetch dispute details
    * @param {string} merchantId The unique identifier of a merchant associated with the dispute.
    * @param {string} disputeId The unique identifier of the dispute.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DisputesApi
    */
  public fetchDisputeDetails(merchantId: string, disputeId: string, options?: any, additionalProperties?: any): Promise<DisputeResponseDto> {
    const localVarFetchArgs = DisputesApiFetchParamCreator().fetchDisputeDetails(merchantId, disputeId, options);
    return <Promise<DisputeResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchDisputeDetails", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Get evidences for a dispute
    * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
    * @param {string} disputeId The unique identifier of the dispute.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DisputesApi
    */
  public getEvidencesForDispute(merchantId: string, disputeId: string, options?: any, additionalProperties?: any): Promise<DisputeEvidenceResponseDto> {
    const localVarFetchArgs = DisputesApiFetchParamCreator().getEvidencesForDispute(merchantId, disputeId, options);
    return <Promise<DisputeEvidenceResponseDto>> cbClient.cbFetch("GET", {operationId: "getEvidencesForDispute", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List disputes for a merchant
    * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {string} [transactionId] ID of the transaction for which dispute is raised
    * @param {Array<DisputeStatus>} [status] Status
    * @param {Array<DisputeType>} [type] Type
    * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
    * @param {number} [amountGt] 
    * @param {number} [amountGte] 
    * @param {number} [amountLt] 
    * @param {number} [amountLte] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DisputesApi
    */
  public listMerchantDisputes(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, transactionId?: string, status?: Array<DisputeStatus>, type?: Array<DisputeType>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options?: any, additionalProperties?: any): Promise<DisputeDtoList> {
    const localVarFetchArgs = DisputesApiFetchParamCreator().listMerchantDisputes(merchantId, cursor, size, sortBy, sort, startDate, endDate, transactionId, status, type, paymentMethodType, amountGt, amountGte, amountLt, amountLte, options);
    return <Promise<DisputeDtoList>> cbClient.cbFetch("GET", {operationId: "listMerchantDisputes", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Store dispute evidence details
    * @param {string} merchantId The unique identifier of a merchant associated with the disputes.
    * @param {string} disputeId The unique identifier of a dispute to which evidence is submitted.
    * @param {DisputeEvidenceDto} disputeEvidenceDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof DisputesApi
    */
  public storeDisputeEvidenceDetails(merchantId: string, disputeId: string, disputeEvidenceDto: DisputeEvidenceDto, options?: any, additionalProperties?: any): Promise<DisputeResponseDto> {
    const localVarFetchArgs = DisputesApiFetchParamCreator().storeDisputeEvidenceDetails(merchantId, disputeId, disputeEvidenceDto, options);
    return <Promise<DisputeResponseDto>> cbClient.cbFetch("POST", {operationId: "storeDisputeEvidenceDetails", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * ExportsApi - fetch parameter creator
 */
const ExportsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Create an export for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the export will be created.
        * @param {CreateExportRequestDto} createExportRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createExport(merchantId: string, createExportRequestDto: CreateExportRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createExport.');
        }
        // verify required parameter 'createExportRequestDto' is not null or undefined
        if (createExportRequestDto === null || createExportRequestDto === undefined) {
          throw new RequiredError('createExportRequestDto','Required parameter createExportRequestDto was null or undefined when calling createExport.');
        }
        const localVarPath = `/merchants/{merchantId}/exports`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = createExportRequestDto
        return output;
      },
      /**
        * 
        * @summary Download export for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom an export will be downloaded.
        * @param {string} exportId The unique identifier of an export.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      downloadExport(merchantId: string, exportId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling downloadExport.');
        }
        // verify required parameter 'exportId' is not null or undefined
        if (exportId === null || exportId === undefined) {
          throw new RequiredError('exportId','Required parameter exportId was null or undefined when calling downloadExport.');
        }
        const localVarPath = `/merchants/{merchantId}/exports/{exportId}/download`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"exportId"}}`, fixedEncodeURIComponent(String(exportId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List exports for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the export list will be retrieved.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantExports(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantExports.');
        }
        const localVarPath = `/merchants/{merchantId}/exports`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * ExportsApi - object-oriented interface
 * @export
 * @class ExportsApi
 */
export class ExportsApiBase {
  /**
    * 
    * @summary Create an export for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the export will be created.
    * @param {CreateExportRequestDto} createExportRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ExportsApi
    */
  public createExport(merchantId: string, createExportRequestDto: CreateExportRequestDto, options?: any, additionalProperties?: any): Promise<ExportDto> {
    const localVarFetchArgs = ExportsApiFetchParamCreator().createExport(merchantId, createExportRequestDto, options);
    return <Promise<ExportDto>> cbClient.cbFetch("POST", {operationId: "createExport", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Download export for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom an export will be downloaded.
    * @param {string} exportId The unique identifier of an export.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ExportsApi
    */
  public downloadExport(merchantId: string, exportId: string, options?: any, additionalProperties?: any): Promise<DownloadExportDto> {
    const localVarFetchArgs = ExportsApiFetchParamCreator().downloadExport(merchantId, exportId, options);
    return <Promise<DownloadExportDto>> cbClient.cbFetch("GET", {operationId: "downloadExport", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List exports for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the export list will be retrieved.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ExportsApi
    */
  public listMerchantExports(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, options?: any, additionalProperties?: any): Promise<ExportDtoList> {
    const localVarFetchArgs = ExportsApiFetchParamCreator().listMerchantExports(merchantId, cursor, size, sortBy, sort, startDate, endDate, options);
    return <Promise<ExportDtoList>> cbClient.cbFetch("GET", {operationId: "listMerchantExports", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * FeeEstimateApi - fetch parameter creator
 */
const FeeEstimateApiFetchParamCreator = function () {
  return {
      /**
        * Provide fee estimate during add funds operation on given the required input.
        * @summary Provide fee estimate for the transaction amount
        * @param {string} merchantId The unique identifier of a merchant for whom the report belongs to.
        * @param {FeeEstimateRequestDto} feeEstimateRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      calculateFeeEstimate(merchantId: string, feeEstimateRequestDto: FeeEstimateRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling calculateFeeEstimate.');
        }
        // verify required parameter 'feeEstimateRequestDto' is not null or undefined
        if (feeEstimateRequestDto === null || feeEstimateRequestDto === undefined) {
          throw new RequiredError('feeEstimateRequestDto','Required parameter feeEstimateRequestDto was null or undefined when calling calculateFeeEstimate.');
        }
        const localVarPath = `/merchants/{merchantId}/fee-estimate`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = feeEstimateRequestDto
        return output;
      },
  }
};

/**
 * FeeEstimateApi - object-oriented interface
 * @export
 * @class FeeEstimateApi
 */
export class FeeEstimateApiBase {
  /**
    * Provide fee estimate during add funds operation on given the required input.
    * @summary Provide fee estimate for the transaction amount
    * @param {string} merchantId The unique identifier of a merchant for whom the report belongs to.
    * @param {FeeEstimateRequestDto} feeEstimateRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof FeeEstimateApi
    */
  public calculateFeeEstimate(merchantId: string, feeEstimateRequestDto: FeeEstimateRequestDto, options?: any, additionalProperties?: any): Promise<FeeEstimateResponseDto> {
    const localVarFetchArgs = FeeEstimateApiFetchParamCreator().calculateFeeEstimate(merchantId, feeEstimateRequestDto, options);
    return <Promise<FeeEstimateResponseDto>> cbClient.cbFetch("POST", {operationId: "calculateFeeEstimate", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * FilesApi - fetch parameter creator
 */
const FilesApiFetchParamCreator = function () {
  return {
      /**
        * Generate a pre-signed url to upload a file
        * @summary Pre-signed url for file upload
        * @param {string} merchantId The unique identifier of a merchant.
        * @param {FileUploadRequestDto} fileUploadRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      getPreSignedURLForFileUpload(merchantId: string, fileUploadRequestDto: FileUploadRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling getPreSignedURLForFileUpload.');
        }
        // verify required parameter 'fileUploadRequestDto' is not null or undefined
        if (fileUploadRequestDto === null || fileUploadRequestDto === undefined) {
          throw new RequiredError('fileUploadRequestDto','Required parameter fileUploadRequestDto was null or undefined when calling getPreSignedURLForFileUpload.');
        }
        const localVarPath = `/merchants/{merchantId}/file-upload`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = fileUploadRequestDto
        return output;
      },
  }
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 */
export class FilesApiBase {
  /**
    * Generate a pre-signed url to upload a file
    * @summary Pre-signed url for file upload
    * @param {string} merchantId The unique identifier of a merchant.
    * @param {FileUploadRequestDto} fileUploadRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof FilesApi
    */
  public getPreSignedURLForFileUpload(merchantId: string, fileUploadRequestDto: FileUploadRequestDto, options?: any, additionalProperties?: any): Promise<FileUploadResponseDto> {
    const localVarFetchArgs = FilesApiFetchParamCreator().getPreSignedURLForFileUpload(merchantId, fileUploadRequestDto, options);
    return <Promise<FileUploadResponseDto>> cbClient.cbFetch("POST", {operationId: "getPreSignedURLForFileUpload", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * MerchantBalanceAccountApi - fetch parameter creator
 */
const MerchantBalanceAccountApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Create a transaction to store in Custom funds Account
        * @param {string} merchantId The unique identifier of a merchant for whom the transaction to be performed.
        * @param {AddFundRequestDto} addFundRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      addFundToBalanceAccount(merchantId: string, addFundRequestDto: AddFundRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling addFundToBalanceAccount.');
        }
        // verify required parameter 'addFundRequestDto' is not null or undefined
        if (addFundRequestDto === null || addFundRequestDto === undefined) {
          throw new RequiredError('addFundRequestDto','Required parameter addFundRequestDto was null or undefined when calling addFundToBalanceAccount.');
        }
        const localVarPath = `/merchants/{merchantId}/add-funds`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = addFundRequestDto
        return output;
      },
      /**
        * 
        * @summary Fetch all merchant balance account for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the balance account needs to be retrieved.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchMerchantBalanceAccount(merchantId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchMerchantBalanceAccount.');
        }
        const localVarPath = `/merchants/{merchantId}/balance-accounts`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * MerchantBalanceAccountApi - object-oriented interface
 * @export
 * @class MerchantBalanceAccountApi
 */
export class MerchantBalanceAccountApiBase {
  /**
    * 
    * @summary Create a transaction to store in Custom funds Account
    * @param {string} merchantId The unique identifier of a merchant for whom the transaction to be performed.
    * @param {AddFundRequestDto} addFundRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantBalanceAccountApi
    */
  public addFundToBalanceAccount(merchantId: string, addFundRequestDto: AddFundRequestDto, options?: any, additionalProperties?: any): Promise<TransactionResponseDto> {
    const localVarFetchArgs = MerchantBalanceAccountApiFetchParamCreator().addFundToBalanceAccount(merchantId, addFundRequestDto, options);
    return <Promise<TransactionResponseDto>> cbClient.cbFetch("POST", {operationId: "addFundToBalanceAccount", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Fetch all merchant balance account for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the balance account needs to be retrieved.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantBalanceAccountApi
    */
  public fetchMerchantBalanceAccount(merchantId: string, options?: any, additionalProperties?: any): Promise<MerchantBalanceAccountsResponseDto> {
    const localVarFetchArgs = MerchantBalanceAccountApiFetchParamCreator().fetchMerchantBalanceAccount(merchantId, options);
    return <Promise<MerchantBalanceAccountsResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchMerchantBalanceAccount", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * MerchantsApi - fetch parameter creator
 */
const MerchantsApiFetchParamCreator = function () {
  return {
      /**
        * Upload files for a merchant as a verification proof.
        * @summary Upload files for a merchant
        * @param {string} merchantId The unique identifier of a merchant.
        * @param {Array<MerchantFileUploadRequestDto>} merchantFileUploadRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      asyncFileUpload(merchantId: string, merchantFileUploadRequestDto: Array<MerchantFileUploadRequestDto>, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling asyncFileUpload.');
        }
        // verify required parameter 'merchantFileUploadRequestDto' is not null or undefined
        if (merchantFileUploadRequestDto === null || merchantFileUploadRequestDto === undefined) {
          throw new RequiredError('merchantFileUploadRequestDto','Required parameter merchantFileUploadRequestDto was null or undefined when calling asyncFileUpload.');
        }
        const localVarPath = `/merchants/{merchantId}/async-file-upload`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = merchantFileUploadRequestDto
        return output;
      },
      /**
        * Create a merchant for an application.
        * @summary Create a merchant
        * @param {MerchantRequestDto} merchantRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createMerchant(merchantRequestDto: MerchantRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantRequestDto' is not null or undefined
        if (merchantRequestDto === null || merchantRequestDto === undefined) {
          throw new RequiredError('merchantRequestDto','Required parameter merchantRequestDto was null or undefined when calling createMerchant.');
        }
        const localVarPath = `/merchants`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = merchantRequestDto
        return output;
      },
      /**
        * Create a sandbox merchant for an application.
        * @summary Create a sandbox merchant
        * @param {CreateSandboxMerchantRequest} createSandboxMerchantRequest 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createSandboxMerchant(createSandboxMerchantRequest: CreateSandboxMerchantRequest, options: any = {}): FetchArgs {
        // verify required parameter 'createSandboxMerchantRequest' is not null or undefined
        if (createSandboxMerchantRequest === null || createSandboxMerchantRequest === undefined) {
          throw new RequiredError('createSandboxMerchantRequest','Required parameter createSandboxMerchantRequest was null or undefined when calling createSandboxMerchant.');
        }
        const localVarPath = `/sandbox-merchant`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = createSandboxMerchantRequest
        return output;
      },
      /**
        * Retrieve a merchant details using merchant ID.
        * @summary Retrieve a merchant
        * @param {string} merchantId The unique identifier of a merchant.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchMerchant(merchantId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchMerchant.');
        }
        const localVarPath = `/merchants/{merchantId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Retrieve configuration for a merchant
        * @param {string} merchantId The unique identifier of a merchant
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchMerchantConfiguration(merchantId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchMerchantConfiguration.');
        }
        const localVarPath = `/merchants/{merchantId}/configuration`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List all merchant files for a merchant
        * @param {string} merchantId The unique identifier of a merchant.
        * @param {number} [page] 
        * @param {number} [size] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listFilesForMerchant(merchantId: string, page?: number, size?: number, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listFilesForMerchant.');
        }
        const localVarPath = `/merchants/{merchantId}/files`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (page !== undefined) {
          localVarQueryParameter['page'] = page;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * Retrieve the list of merchants of an application. By default, the latest merchants are created in descending order.
        * @summary List merchants
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchants(cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, options: any = {}): FetchArgs {
        const localVarPath = `/merchants`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * Update a merchant for an application.
        * @summary Update a merchant
        * @param {string} merchantId The unique identifier of a merchant.
        * @param {MerchantRequestDto} merchantRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      updateMerchant(merchantId: string, merchantRequestDto: MerchantRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling updateMerchant.');
        }
        // verify required parameter 'merchantRequestDto' is not null or undefined
        if (merchantRequestDto === null || merchantRequestDto === undefined) {
          throw new RequiredError('merchantRequestDto','Required parameter merchantRequestDto was null or undefined when calling updateMerchant.');
        }
        const localVarPath = `/merchants/{merchantId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = merchantRequestDto
        return output;
      },
      /**
        * 
        * @summary Update configuration for a merchant
        * @param {string} merchantId The unique identifier of a merchant
        * @param {ConfigurationRequestDto} configurationRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      updateMerchantConfiguration(merchantId: string, configurationRequestDto: ConfigurationRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling updateMerchantConfiguration.');
        }
        // verify required parameter 'configurationRequestDto' is not null or undefined
        if (configurationRequestDto === null || configurationRequestDto === undefined) {
          throw new RequiredError('configurationRequestDto','Required parameter configurationRequestDto was null or undefined when calling updateMerchantConfiguration.');
        }
        const localVarPath = `/merchants/{merchantId}/configuration`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = configurationRequestDto
        return output;
      },
      /**
        * Update a merchant for an application.
        * @summary Update a merchant
        * @param {string} merchantId The unique identifier of a merchant.
        * @param {Array<MerchantPatchDocument>} merchantPatchDocument 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      updateMerchantPartially(merchantId: string, merchantPatchDocument: Array<MerchantPatchDocument>, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling updateMerchantPartially.');
        }
        // verify required parameter 'merchantPatchDocument' is not null or undefined
        if (merchantPatchDocument === null || merchantPatchDocument === undefined) {
          throw new RequiredError('merchantPatchDocument','Required parameter merchantPatchDocument was null or undefined when calling updateMerchantPartially.');
        }
        const localVarPath = `/merchants/{merchantId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = merchantPatchDocument
        return output;
      },
      /**
        * Upload files for a merchant as a verification proof.
        * @summary Upload files for a merchant
        * @param {string} merchantId The unique identifier of a merchant for which files to be upload.
        * @param {FileType} fileType 
        * @param {any} file The verification file to be uploaded. The valid file extentions are &#x60;jpeg&#x60;, &#x60;jpg&#x60;, &#x60;png&#x60;, &#x60;tiff&#x60;, and &#x60;pdf&#x60;.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      uploadFile(merchantId: string, fileType: FileType, file: any, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling uploadFile.');
        }
        // verify required parameter 'fileType' is not null or undefined
        if (fileType === null || fileType === undefined) {
          throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling uploadFile.');
        }
        // verify required parameter 'file' is not null or undefined
        if (file === null || file === undefined) {
          throw new RequiredError('file','Required parameter file was null or undefined when calling uploadFile.');
        }
        const localVarPath = `/merchants/{merchantId}/files`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * MerchantsApi - object-oriented interface
 * @export
 * @class MerchantsApi
 */
export class MerchantsApiBase {
  /**
    * Upload files for a merchant as a verification proof.
    * @summary Upload files for a merchant
    * @param {string} merchantId The unique identifier of a merchant.
    * @param {Array<MerchantFileUploadRequestDto>} merchantFileUploadRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public asyncFileUpload(merchantId: string, merchantFileUploadRequestDto: Array<MerchantFileUploadRequestDto>, options?: any, additionalProperties?: any): Promise<Array<FileUploadDto>> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().asyncFileUpload(merchantId, merchantFileUploadRequestDto, options);
    return <Promise<Array<FileUploadDto>>> cbClient.cbFetch("POST", {operationId: "asyncFileUpload", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Create a merchant for an application.
    * @summary Create a merchant
    * @param {MerchantRequestDto} merchantRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public createMerchant(merchantRequestDto: MerchantRequestDto, options?: any, additionalProperties?: any): Promise<MerchantResponseDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().createMerchant(merchantRequestDto, options);
    return <Promise<MerchantResponseDto>> cbClient.cbFetch("POST", {operationId: "createMerchant", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Create a sandbox merchant for an application.
    * @summary Create a sandbox merchant
    * @param {CreateSandboxMerchantRequest} createSandboxMerchantRequest 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public createSandboxMerchant(createSandboxMerchantRequest: CreateSandboxMerchantRequest, options?: any, additionalProperties?: any): Promise<MerchantResponseDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().createSandboxMerchant(createSandboxMerchantRequest, options);
    return <Promise<MerchantResponseDto>> cbClient.cbFetch("POST", {operationId: "createSandboxMerchant", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Retrieve a merchant details using merchant ID.
    * @summary Retrieve a merchant
    * @param {string} merchantId The unique identifier of a merchant.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public fetchMerchant(merchantId: string, options?: any, additionalProperties?: any): Promise<MerchantResponseDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().fetchMerchant(merchantId, options);
    return <Promise<MerchantResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchMerchant", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve configuration for a merchant
    * @param {string} merchantId The unique identifier of a merchant
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public fetchMerchantConfiguration(merchantId: string, options?: any, additionalProperties?: any): Promise<ConfigurationResponseDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().fetchMerchantConfiguration(merchantId, options);
    return <Promise<ConfigurationResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchMerchantConfiguration", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List all merchant files for a merchant
    * @param {string} merchantId The unique identifier of a merchant.
    * @param {number} [page] 
    * @param {number} [size] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public listFilesForMerchant(merchantId: string, page?: number, size?: number, options?: any, additionalProperties?: any): Promise<Array<FileUploadDto>> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().listFilesForMerchant(merchantId, page, size, options);
    return <Promise<Array<FileUploadDto>>> cbClient.cbFetch("GET", {operationId: "listFilesForMerchant", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Retrieve the list of merchants of an application. By default, the latest merchants are created in descending order.
    * @summary List merchants
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public listMerchants(cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, options?: any, additionalProperties?: any): Promise<MerchantDtoList> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().listMerchants(cursor, size, sortBy, sort, options);
    return <Promise<MerchantDtoList>> cbClient.cbFetch("GET", {operationId: "listMerchants", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Update a merchant for an application.
    * @summary Update a merchant
    * @param {string} merchantId The unique identifier of a merchant.
    * @param {MerchantRequestDto} merchantRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public updateMerchant(merchantId: string, merchantRequestDto: MerchantRequestDto, options?: any, additionalProperties?: any): Promise<MerchantResponseDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().updateMerchant(merchantId, merchantRequestDto, options);
    return <Promise<MerchantResponseDto>> cbClient.cbFetch("PUT", {operationId: "updateMerchant", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Update configuration for a merchant
    * @param {string} merchantId The unique identifier of a merchant
    * @param {ConfigurationRequestDto} configurationRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public updateMerchantConfiguration(merchantId: string, configurationRequestDto: ConfigurationRequestDto, options?: any, additionalProperties?: any): Promise<ConfigurationResponseDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().updateMerchantConfiguration(merchantId, configurationRequestDto, options);
    return <Promise<ConfigurationResponseDto>> cbClient.cbFetch("POST", {operationId: "updateMerchantConfiguration", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Update a merchant for an application.
    * @summary Update a merchant
    * @param {string} merchantId The unique identifier of a merchant.
    * @param {Array<MerchantPatchDocument>} merchantPatchDocument 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public updateMerchantPartially(merchantId: string, merchantPatchDocument: Array<MerchantPatchDocument>, options?: any, additionalProperties?: any): Promise<MerchantResponseDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().updateMerchantPartially(merchantId, merchantPatchDocument, options);
    return <Promise<MerchantResponseDto>> cbClient.cbFetch("PATCH", {operationId: "updateMerchantPartially", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Upload files for a merchant as a verification proof.
    * @summary Upload files for a merchant
    * @param {string} merchantId The unique identifier of a merchant for which files to be upload.
    * @param {FileType} fileType 
    * @param {any} file The verification file to be uploaded. The valid file extentions are &#x60;jpeg&#x60;, &#x60;jpg&#x60;, &#x60;png&#x60;, &#x60;tiff&#x60;, and &#x60;pdf&#x60;.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof MerchantsApi
    */
  public uploadFile(merchantId: string, fileType: FileType, file: any, options?: any, additionalProperties?: any): Promise<FileUploadDto> {
    const localVarFetchArgs = MerchantsApiFetchParamCreator().uploadFile(merchantId, fileType, file, options);
    return <Promise<FileUploadDto>> cbClient.cbFetch("POST", {operationId: "uploadFile", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * OnboardingDraftsApi - fetch parameter creator
 */
const OnboardingDraftsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Retrieve onboarding draft by ID
        * @param {string} id A unique identifier of the onboarding draft
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchOnboardingDraft(id: string, options: any = {}): FetchArgs {
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
          throw new RequiredError('id','Required parameter id was null or undefined when calling fetchOnboardingDraft.');
        }
        const localVarPath = `/onboarding-drafts/{id}`
            .replace(`{${"id"}}`, fixedEncodeURIComponent(String(id)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Save onboarding draft
        * @param {DraftMerchantRequestDto} [draftMerchantRequestDto] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      saveOnboardingDraft(draftMerchantRequestDto?: DraftMerchantRequestDto, options: any = {}): FetchArgs {
        const localVarPath = `/onboarding-drafts`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = draftMerchantRequestDto
        return output;
      },
  }
};

/**
 * OnboardingDraftsApi - object-oriented interface
 * @export
 * @class OnboardingDraftsApi
 */
export class OnboardingDraftsApiBase {
  /**
    * 
    * @summary Retrieve onboarding draft by ID
    * @param {string} id A unique identifier of the onboarding draft
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof OnboardingDraftsApi
    */
  public fetchOnboardingDraft(id: string, options?: any, additionalProperties?: any): Promise<DraftMerchantResponseDto> {
    const localVarFetchArgs = OnboardingDraftsApiFetchParamCreator().fetchOnboardingDraft(id, options);
    return <Promise<DraftMerchantResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchOnboardingDraft", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Save onboarding draft
    * @param {DraftMerchantRequestDto} [draftMerchantRequestDto] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof OnboardingDraftsApi
    */
  public saveOnboardingDraft(draftMerchantRequestDto?: DraftMerchantRequestDto, options?: any, additionalProperties?: any): Promise<DraftMerchantResponseDto> {
    const localVarFetchArgs = OnboardingDraftsApiFetchParamCreator().saveOnboardingDraft(draftMerchantRequestDto, options);
    return <Promise<DraftMerchantResponseDto>> cbClient.cbFetch("POST", {operationId: "saveOnboardingDraft", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * PaymentInstrumentsApi - fetch parameter creator
 */
const PaymentInstrumentsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Create payment instrument for a consumer
        * @param {string} merchantId A unique identifier of the merchant to whom the consumer is registered.
        * @param {string} consumerId A unique identifier of the consumer for whom payment instrument will be created.
        * @param {PaymentInstrumentsRequestDto} paymentInstrumentsRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createConsumerPaymentInstrument(merchantId: string, consumerId: string, paymentInstrumentsRequestDto: PaymentInstrumentsRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createConsumerPaymentInstrument.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling createConsumerPaymentInstrument.');
        }
        // verify required parameter 'paymentInstrumentsRequestDto' is not null or undefined
        if (paymentInstrumentsRequestDto === null || paymentInstrumentsRequestDto === undefined) {
          throw new RequiredError('paymentInstrumentsRequestDto','Required parameter paymentInstrumentsRequestDto was null or undefined when calling createConsumerPaymentInstrument.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/payment-instruments`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = paymentInstrumentsRequestDto
        return output;
      },
      /**
        * 
        * @summary Create payment instrument for merchant
        * @param {string} merchantId A unique identifier of the merchant for whom the payment instrument is created.
        * @param {PaymentInstrumentsRequestDto} paymentInstrumentsRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createMerchantPaymentInstrument(merchantId: string, paymentInstrumentsRequestDto: PaymentInstrumentsRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createMerchantPaymentInstrument.');
        }
        // verify required parameter 'paymentInstrumentsRequestDto' is not null or undefined
        if (paymentInstrumentsRequestDto === null || paymentInstrumentsRequestDto === undefined) {
          throw new RequiredError('paymentInstrumentsRequestDto','Required parameter paymentInstrumentsRequestDto was null or undefined when calling createMerchantPaymentInstrument.');
        }
        const localVarPath = `/merchants/{merchantId}/payment-instruments`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = paymentInstrumentsRequestDto
        return output;
      },
      /**
        * 
        * @summary Delete a specific payment instrument of a consumer
        * @param {string} merchantId A unique identifier of a merchant to whom the consumer is registered.
        * @param {string} consumerId A unique identifier of a consumer for whom a specific payment instrument will be fetched.
        * @param {string} paymentInstrumentId A unique identifier of a payment instrument.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      deleteConsumerPaymentInstrument(merchantId: string, consumerId: string, paymentInstrumentId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling deleteConsumerPaymentInstrument.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling deleteConsumerPaymentInstrument.');
        }
        // verify required parameter 'paymentInstrumentId' is not null or undefined
        if (paymentInstrumentId === null || paymentInstrumentId === undefined) {
          throw new RequiredError('paymentInstrumentId','Required parameter paymentInstrumentId was null or undefined when calling deleteConsumerPaymentInstrument.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/payment-instruments/{paymentInstrumentId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)))
            .replace(`{${"paymentInstrumentId"}}`, fixedEncodeURIComponent(String(paymentInstrumentId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Retrieve a specific payment instruments of a consumer
        * @param {string} merchantId A unique identifier of a merchant to whom the consumer is registered.
        * @param {string} consumerId A unique identifier of a consumer for whom a specific payment instrument will be deleted.
        * @param {string} paymentInstrumentId A unique identifier of a payment instrument.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchConsumerPaymentInstrument(merchantId: string, consumerId: string, paymentInstrumentId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchConsumerPaymentInstrument.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling fetchConsumerPaymentInstrument.');
        }
        // verify required parameter 'paymentInstrumentId' is not null or undefined
        if (paymentInstrumentId === null || paymentInstrumentId === undefined) {
          throw new RequiredError('paymentInstrumentId','Required parameter paymentInstrumentId was null or undefined when calling fetchConsumerPaymentInstrument.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/payment-instruments/{paymentInstrumentId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)))
            .replace(`{${"paymentInstrumentId"}}`, fixedEncodeURIComponent(String(paymentInstrumentId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Retrieve a specific payment instrument of a merchant
        * @param {string} merchantId A unique identifier of the merchant for whom a specific payment instrument will be fetched.
        * @param {string} paymentInstrumentId A unique identifier of a payment instrument.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchMerchantPaymentInstrument(merchantId: string, paymentInstrumentId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchMerchantPaymentInstrument.');
        }
        // verify required parameter 'paymentInstrumentId' is not null or undefined
        if (paymentInstrumentId === null || paymentInstrumentId === undefined) {
          throw new RequiredError('paymentInstrumentId','Required parameter paymentInstrumentId was null or undefined when calling fetchMerchantPaymentInstrument.');
        }
        const localVarPath = `/merchants/{merchantId}/payment-instruments/{paymentInstrumentId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"paymentInstrumentId"}}`, fixedEncodeURIComponent(String(paymentInstrumentId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List all the payment instruments of a consumer
        * @param {string} merchantId A unique identifier of a merchant to whom the consumer is registered.
        * @param {string} consumerId A unique identifier of a consumer for whom all the payment instruments will be listed.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listConsumerPaymentInstruments(merchantId: string, consumerId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listConsumerPaymentInstruments.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling listConsumerPaymentInstruments.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/payment-instruments`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List all the payment instruments of a merchant
        * @param {string} merchantId A unique identifier of the merchant for whom all the payment instruments will be listed.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantPaymentInstruments(merchantId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantPaymentInstruments.');
        }
        const localVarPath = `/merchants/{merchantId}/payment-instruments`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * PaymentInstrumentsApi - object-oriented interface
 * @export
 * @class PaymentInstrumentsApi
 */
export class PaymentInstrumentsApiBase {
  /**
    * 
    * @summary Create payment instrument for a consumer
    * @param {string} merchantId A unique identifier of the merchant to whom the consumer is registered.
    * @param {string} consumerId A unique identifier of the consumer for whom payment instrument will be created.
    * @param {PaymentInstrumentsRequestDto} paymentInstrumentsRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof PaymentInstrumentsApi
    */
  public createConsumerPaymentInstrument(merchantId: string, consumerId: string, paymentInstrumentsRequestDto: PaymentInstrumentsRequestDto, options?: any, additionalProperties?: any): Promise<PaymentInstrumentsResponseDto> {
    const localVarFetchArgs = PaymentInstrumentsApiFetchParamCreator().createConsumerPaymentInstrument(merchantId, consumerId, paymentInstrumentsRequestDto, options);
    return <Promise<PaymentInstrumentsResponseDto>> cbClient.cbFetch("POST", {operationId: "createConsumerPaymentInstrument", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Create payment instrument for merchant
    * @param {string} merchantId A unique identifier of the merchant for whom the payment instrument is created.
    * @param {PaymentInstrumentsRequestDto} paymentInstrumentsRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof PaymentInstrumentsApi
    */
  public createMerchantPaymentInstrument(merchantId: string, paymentInstrumentsRequestDto: PaymentInstrumentsRequestDto, options?: any, additionalProperties?: any): Promise<PaymentInstrumentsResponseDto> {
    const localVarFetchArgs = PaymentInstrumentsApiFetchParamCreator().createMerchantPaymentInstrument(merchantId, paymentInstrumentsRequestDto, options);
    return <Promise<PaymentInstrumentsResponseDto>> cbClient.cbFetch("POST", {operationId: "createMerchantPaymentInstrument", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Delete a specific payment instrument of a consumer
    * @param {string} merchantId A unique identifier of a merchant to whom the consumer is registered.
    * @param {string} consumerId A unique identifier of a consumer for whom a specific payment instrument will be fetched.
    * @param {string} paymentInstrumentId A unique identifier of a payment instrument.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof PaymentInstrumentsApi
    */
  public deleteConsumerPaymentInstrument(merchantId: string, consumerId: string, paymentInstrumentId: string, options?: any, additionalProperties?: any): Promise<any> {
    const localVarFetchArgs = PaymentInstrumentsApiFetchParamCreator().deleteConsumerPaymentInstrument(merchantId, consumerId, paymentInstrumentId, options);
    return <Promise<any>> cbClient.cbFetch("DELETE", {operationId: "deleteConsumerPaymentInstrument", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve a specific payment instruments of a consumer
    * @param {string} merchantId A unique identifier of a merchant to whom the consumer is registered.
    * @param {string} consumerId A unique identifier of a consumer for whom a specific payment instrument will be deleted.
    * @param {string} paymentInstrumentId A unique identifier of a payment instrument.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof PaymentInstrumentsApi
    */
  public fetchConsumerPaymentInstrument(merchantId: string, consumerId: string, paymentInstrumentId: string, options?: any, additionalProperties?: any): Promise<PaymentInstrumentsResponseDto> {
    const localVarFetchArgs = PaymentInstrumentsApiFetchParamCreator().fetchConsumerPaymentInstrument(merchantId, consumerId, paymentInstrumentId, options);
    return <Promise<PaymentInstrumentsResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchConsumerPaymentInstrument", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve a specific payment instrument of a merchant
    * @param {string} merchantId A unique identifier of the merchant for whom a specific payment instrument will be fetched.
    * @param {string} paymentInstrumentId A unique identifier of a payment instrument.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof PaymentInstrumentsApi
    */
  public fetchMerchantPaymentInstrument(merchantId: string, paymentInstrumentId: string, options?: any, additionalProperties?: any): Promise<PaymentInstrumentsResponseDto> {
    const localVarFetchArgs = PaymentInstrumentsApiFetchParamCreator().fetchMerchantPaymentInstrument(merchantId, paymentInstrumentId, options);
    return <Promise<PaymentInstrumentsResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchMerchantPaymentInstrument", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List all the payment instruments of a consumer
    * @param {string} merchantId A unique identifier of a merchant to whom the consumer is registered.
    * @param {string} consumerId A unique identifier of a consumer for whom all the payment instruments will be listed.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof PaymentInstrumentsApi
    */
  public listConsumerPaymentInstruments(merchantId: string, consumerId: string, options?: any, additionalProperties?: any): Promise<Array<PaymentInstrumentsResponseDto>> {
    const localVarFetchArgs = PaymentInstrumentsApiFetchParamCreator().listConsumerPaymentInstruments(merchantId, consumerId, options);
    return <Promise<Array<PaymentInstrumentsResponseDto>>> cbClient.cbFetch("GET", {operationId: "listConsumerPaymentInstruments", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List all the payment instruments of a merchant
    * @param {string} merchantId A unique identifier of the merchant for whom all the payment instruments will be listed.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof PaymentInstrumentsApi
    */
  public listMerchantPaymentInstruments(merchantId: string, options?: any, additionalProperties?: any): Promise<Array<PaymentInstrumentsResponseDto>> {
    const localVarFetchArgs = PaymentInstrumentsApiFetchParamCreator().listMerchantPaymentInstruments(merchantId, options);
    return <Promise<Array<PaymentInstrumentsResponseDto>>> cbClient.cbFetch("GET", {operationId: "listMerchantPaymentInstruments", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * RefundsApi - fetch parameter creator
 */
const RefundsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Create a refund
        * @param {string} merchantId The unique identifier of a merchant for whom the refund is initiated.
        * @param {string} transactionId The unique identifier of a transaction for which refund is initiated.
        * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
        * @param {RefundTransactionRequestDto} refundTransactionRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createRefund(merchantId: string, transactionId: string, xIdempotencyKey: string, refundTransactionRequestDto: RefundTransactionRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createRefund.');
        }
        // verify required parameter 'transactionId' is not null or undefined
        if (transactionId === null || transactionId === undefined) {
          throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling createRefund.');
        }
        // verify required parameter 'xIdempotencyKey' is not null or undefined
        if (xIdempotencyKey === null || xIdempotencyKey === undefined) {
          throw new RequiredError('xIdempotencyKey','Required parameter xIdempotencyKey was null or undefined when calling createRefund.');
        }
        // verify required parameter 'refundTransactionRequestDto' is not null or undefined
        if (refundTransactionRequestDto === null || refundTransactionRequestDto === undefined) {
          throw new RequiredError('refundTransactionRequestDto','Required parameter refundTransactionRequestDto was null or undefined when calling createRefund.');
        }
        const localVarPath = `/merchants/{merchantId}/transactions/{transactionId}/refunds`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"transactionId"}}`, fixedEncodeURIComponent(String(transactionId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
            if (xIdempotencyKey !== undefined && xIdempotencyKey !== null) {
                localVarHeaderParameter['X-Idempotency-Key'] = String(xIdempotencyKey);
            }

        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = refundTransactionRequestDto
        return output;
      },
      /**
        * 
        * @summary Create a reversal
        * @param {string} merchantId The unique identifier of a merchant for whom the reversal is initiated.
        * @param {string} transactionId The unique identifier of a transaction for which reversal is initiated.
        * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createReversals(merchantId: string, transactionId: string, xIdempotencyKey: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createReversals.');
        }
        // verify required parameter 'transactionId' is not null or undefined
        if (transactionId === null || transactionId === undefined) {
          throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling createReversals.');
        }
        // verify required parameter 'xIdempotencyKey' is not null or undefined
        if (xIdempotencyKey === null || xIdempotencyKey === undefined) {
          throw new RequiredError('xIdempotencyKey','Required parameter xIdempotencyKey was null or undefined when calling createReversals.');
        }
        const localVarPath = `/merchants/{merchantId}/transactions/{transactionId}/reversals`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"transactionId"}}`, fixedEncodeURIComponent(String(transactionId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
            if (xIdempotencyKey !== undefined && xIdempotencyKey !== null) {
                localVarHeaderParameter['X-Idempotency-Key'] = String(xIdempotencyKey);
            }


        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Retrieve a refund details
        * @param {string} merchantId The unique identifier of the merchant for whom the refund is performed.
        * @param {string} consumerId The unique identifier of the consumer for whom the refund is initiated.
        * @param {string} refundId The unique identifier of a refund.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchRefund(merchantId: string, consumerId: string, refundId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchRefund.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling fetchRefund.');
        }
        // verify required parameter 'refundId' is not null or undefined
        if (refundId === null || refundId === undefined) {
          throw new RequiredError('refundId','Required parameter refundId was null or undefined when calling fetchRefund.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/refunds/{refundId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)))
            .replace(`{${"refundId"}}`, fixedEncodeURIComponent(String(refundId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Retrieve refunds for a transaction
        * @param {string} merchantId The unique identifier of a merchant for whom the refund is initiated.
        * @param {string} transactionId The unique identifier of a transaction for which refund is initiated.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchRefundsForATransaction(merchantId: string, transactionId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchRefundsForATransaction.');
        }
        // verify required parameter 'transactionId' is not null or undefined
        if (transactionId === null || transactionId === undefined) {
          throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling fetchRefundsForATransaction.');
        }
        const localVarPath = `/merchants/{merchantId}/transactions/{transactionId}/refunds`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"transactionId"}}`, fixedEncodeURIComponent(String(transactionId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List refunds for a consumer
        * @param {string} merchantId The unique identifier of a merchant to retrieve the refund details.
        * @param {string} consumerId The unique identifier of a consumer to retrieve the refund details.
        * @param {number} [page] 
        * @param {number} [size] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listConsumerRefunds(merchantId: string, consumerId: string, page?: number, size?: number, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listConsumerRefunds.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling listConsumerRefunds.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/refunds`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (page !== undefined) {
          localVarQueryParameter['page'] = page;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List refunds for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the refunds are performed.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {Array<RefundStatus>} [status] Status of the refund
        * @param {Array<RefundType>} [type] Type of the refund
        * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
        * @param {number} [amountGt] 
        * @param {number} [amountGte] 
        * @param {number} [amountLt] 
        * @param {number} [amountLte] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantRefunds(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, status?: Array<RefundStatus>, type?: Array<RefundType>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantRefunds.');
        }
        const localVarPath = `/merchants/{merchantId}/refunds`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (status) {
          localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS["csv"]);
        }
        if (type) {
          localVarQueryParameter['type'] = type.join(COLLECTION_FORMATS["csv"]);
        }
        if (paymentMethodType) {
          localVarQueryParameter['payment_method_type'] = paymentMethodType.join(COLLECTION_FORMATS["csv"]);
        }
        if (amountGt !== undefined) {
          localVarQueryParameter['amount_gt'] = amountGt;
        }
        if (amountGte !== undefined) {
          localVarQueryParameter['amount_gte'] = amountGte;
        }
        if (amountLt !== undefined) {
          localVarQueryParameter['amount_lt'] = amountLt;
        }
        if (amountLte !== undefined) {
          localVarQueryParameter['amount_lte'] = amountLte;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * RefundsApi - object-oriented interface
 * @export
 * @class RefundsApi
 */
export class RefundsApiBase {
  /**
    * 
    * @summary Create a refund
    * @param {string} merchantId The unique identifier of a merchant for whom the refund is initiated.
    * @param {string} transactionId The unique identifier of a transaction for which refund is initiated.
    * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
    * @param {RefundTransactionRequestDto} refundTransactionRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof RefundsApi
    */
  public createRefund(merchantId: string, transactionId: string, xIdempotencyKey: string, refundTransactionRequestDto: RefundTransactionRequestDto, options?: any, additionalProperties?: any): Promise<TransactionDto> {
    const localVarFetchArgs = RefundsApiFetchParamCreator().createRefund(merchantId, transactionId, xIdempotencyKey, refundTransactionRequestDto, options);
    return <Promise<TransactionDto>> cbClient.cbFetch("POST", {operationId: "createRefund", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Create a reversal
    * @param {string} merchantId The unique identifier of a merchant for whom the reversal is initiated.
    * @param {string} transactionId The unique identifier of a transaction for which reversal is initiated.
    * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof RefundsApi
    */
  public createReversals(merchantId: string, transactionId: string, xIdempotencyKey: string, options?: any, additionalProperties?: any): Promise<TransactionDto> {
    const localVarFetchArgs = RefundsApiFetchParamCreator().createReversals(merchantId, transactionId, xIdempotencyKey, options);
    return <Promise<TransactionDto>> cbClient.cbFetch("POST", {operationId: "createReversals", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve a refund details
    * @param {string} merchantId The unique identifier of the merchant for whom the refund is performed.
    * @param {string} consumerId The unique identifier of the consumer for whom the refund is initiated.
    * @param {string} refundId The unique identifier of a refund.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof RefundsApi
    */
  public fetchRefund(merchantId: string, consumerId: string, refundId: string, options?: any, additionalProperties?: any): Promise<RefundDto> {
    const localVarFetchArgs = RefundsApiFetchParamCreator().fetchRefund(merchantId, consumerId, refundId, options);
    return <Promise<RefundDto>> cbClient.cbFetch("GET", {operationId: "fetchRefund", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve refunds for a transaction
    * @param {string} merchantId The unique identifier of a merchant for whom the refund is initiated.
    * @param {string} transactionId The unique identifier of a transaction for which refund is initiated.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof RefundsApi
    */
  public fetchRefundsForATransaction(merchantId: string, transactionId: string, options?: any, additionalProperties?: any): Promise<RefundResponseDtoList> {
    const localVarFetchArgs = RefundsApiFetchParamCreator().fetchRefundsForATransaction(merchantId, transactionId, options);
    return <Promise<RefundResponseDtoList>> cbClient.cbFetch("GET", {operationId: "fetchRefundsForATransaction", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List refunds for a consumer
    * @param {string} merchantId The unique identifier of a merchant to retrieve the refund details.
    * @param {string} consumerId The unique identifier of a consumer to retrieve the refund details.
    * @param {number} [page] 
    * @param {number} [size] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof RefundsApi
    */
  public listConsumerRefunds(merchantId: string, consumerId: string, page?: number, size?: number, options?: any, additionalProperties?: any): Promise<RefundDtoListWithPagination> {
    const localVarFetchArgs = RefundsApiFetchParamCreator().listConsumerRefunds(merchantId, consumerId, page, size, options);
    return <Promise<RefundDtoListWithPagination>> cbClient.cbFetch("GET", {operationId: "listConsumerRefunds", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List refunds for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the refunds are performed.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {Array<RefundStatus>} [status] Status of the refund
    * @param {Array<RefundType>} [type] Type of the refund
    * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
    * @param {number} [amountGt] 
    * @param {number} [amountGte] 
    * @param {number} [amountLt] 
    * @param {number} [amountLte] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof RefundsApi
    */
  public listMerchantRefunds(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, status?: Array<RefundStatus>, type?: Array<RefundType>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options?: any, additionalProperties?: any): Promise<RefundDtoListWithPagination> {
    const localVarFetchArgs = RefundsApiFetchParamCreator().listMerchantRefunds(merchantId, cursor, size, sortBy, sort, startDate, endDate, status, type, paymentMethodType, amountGt, amountGte, amountLt, amountLte, options);
    return <Promise<RefundDtoListWithPagination>> cbClient.cbFetch("GET", {operationId: "listMerchantRefunds", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * ReportsApi - fetch parameter creator
 */
const ReportsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Retrieve the report data urls for the merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the report belongs to.
        * @param {string} reportId The unique identifier of a report.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      downloadReport(merchantId: string, reportId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling downloadReport.');
        }
        // verify required parameter 'reportId' is not null or undefined
        if (reportId === null || reportId === undefined) {
          throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling downloadReport.');
        }
        const localVarPath = `/merchants/{merchantId}/reports/{reportId}/download`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"reportId"}}`, fixedEncodeURIComponent(String(reportId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Retrieve the report information for the merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the report belongs to.
        * @param {ReportType} reportType Type of the report
        * @param {Date} startDate 
        * @param {Date} endDate 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchReport(merchantId: string, reportType: ReportType, startDate: Date, endDate: Date, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchReport.');
        }
        // verify required parameter 'reportType' is not null or undefined
        if (reportType === null || reportType === undefined) {
          throw new RequiredError('reportType','Required parameter reportType was null or undefined when calling fetchReport.');
        }
        // verify required parameter 'startDate' is not null or undefined
        if (startDate === null || startDate === undefined) {
          throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling fetchReport.');
        }
        // verify required parameter 'endDate' is not null or undefined
        if (endDate === null || endDate === undefined) {
          throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling fetchReport.');
        }
        const localVarPath = `/merchants/{merchantId}/reports`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (reportType !== undefined) {
          localVarQueryParameter['report_type'] = reportType;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * Generate report for the given merchants based on the given input interval
        * @summary Generate report
        * @param {ReportRequestDto} reportRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      generateReport(reportRequestDto: ReportRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'reportRequestDto' is not null or undefined
        if (reportRequestDto === null || reportRequestDto === undefined) {
          throw new RequiredError('reportRequestDto','Required parameter reportRequestDto was null or undefined when calling generateReport.');
        }
        const localVarPath = `/reports`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = reportRequestDto
        return output;
      },
  }
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 */
export class ReportsApiBase {
  /**
    * 
    * @summary Retrieve the report data urls for the merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the report belongs to.
    * @param {string} reportId The unique identifier of a report.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ReportsApi
    */
  public downloadReport(merchantId: string, reportId: string, options?: any, additionalProperties?: any): Promise<ReportDataDto> {
    const localVarFetchArgs = ReportsApiFetchParamCreator().downloadReport(merchantId, reportId, options);
    return <Promise<ReportDataDto>> cbClient.cbFetch("GET", {operationId: "downloadReport", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve the report information for the merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the report belongs to.
    * @param {ReportType} reportType Type of the report
    * @param {Date} startDate 
    * @param {Date} endDate 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ReportsApi
    */
  public fetchReport(merchantId: string, reportType: ReportType, startDate: Date, endDate: Date, options?: any, additionalProperties?: any): Promise<ReportDto> {
    const localVarFetchArgs = ReportsApiFetchParamCreator().fetchReport(merchantId, reportType, startDate, endDate, options);
    return <Promise<ReportDto>> cbClient.cbFetch("GET", {operationId: "fetchReport", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Generate report for the given merchants based on the given input interval
    * @summary Generate report
    * @param {ReportRequestDto} reportRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof ReportsApi
    */
  public generateReport(reportRequestDto: ReportRequestDto, options?: any, additionalProperties?: any): Promise<ReportListDto> {
    const localVarFetchArgs = ReportsApiFetchParamCreator().generateReport(reportRequestDto, options);
    return <Promise<ReportListDto>> cbClient.cbFetch("POST", {operationId: "generateReport", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * SalesApi - fetch parameter creator
 */
const SalesApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary List sales for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {Array<TransactionType>} [type] Type of transaction
        * @param {Array<SaleStatus>} [status] Status of the sales
        * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
        * @param {number} [amountGt] 
        * @param {number} [amountGte] 
        * @param {number} [amountLt] 
        * @param {number} [amountLte] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantSales(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, type?: Array<TransactionType>, status?: Array<SaleStatus>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantSales.');
        }
        const localVarPath = `/merchants/{merchantId}/sales`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (type) {
          localVarQueryParameter['type'] = type.join(COLLECTION_FORMATS["csv"]);
        }
        if (status) {
          localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS["csv"]);
        }
        if (paymentMethodType) {
          localVarQueryParameter['payment_method_type'] = paymentMethodType.join(COLLECTION_FORMATS["csv"]);
        }
        if (amountGt !== undefined) {
          localVarQueryParameter['amount_gt'] = amountGt;
        }
        if (amountGte !== undefined) {
          localVarQueryParameter['amount_gte'] = amountGte;
        }
        if (amountLt !== undefined) {
          localVarQueryParameter['amount_lt'] = amountLt;
        }
        if (amountLte !== undefined) {
          localVarQueryParameter['amount_lte'] = amountLte;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * SalesApi - object-oriented interface
 * @export
 * @class SalesApi
 */
export class SalesApiBase {
  /**
    * 
    * @summary List sales for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {Array<TransactionType>} [type] Type of transaction
    * @param {Array<SaleStatus>} [status] Status of the sales
    * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
    * @param {number} [amountGt] 
    * @param {number} [amountGte] 
    * @param {number} [amountLt] 
    * @param {number} [amountLte] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof SalesApi
    */
  public listMerchantSales(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, type?: Array<TransactionType>, status?: Array<SaleStatus>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options?: any, additionalProperties?: any): Promise<SaleDtoList> {
    const localVarFetchArgs = SalesApiFetchParamCreator().listMerchantSales(merchantId, cursor, size, sortBy, sort, startDate, endDate, type, status, paymentMethodType, amountGt, amountGte, amountLt, amountLte, options);
    return <Promise<SaleDtoList>> cbClient.cbFetch("GET", {operationId: "listMerchantSales", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * SessionsApi - fetch parameter creator
 */
const SessionsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Create a session
        * @param {CreateSessionRequestDto} createSessionRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createSession(createSessionRequestDto: CreateSessionRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'createSessionRequestDto' is not null or undefined
        if (createSessionRequestDto === null || createSessionRequestDto === undefined) {
          throw new RequiredError('createSessionRequestDto','Required parameter createSessionRequestDto was null or undefined when calling createSession.');
        }
        const localVarPath = `/sessions`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = createSessionRequestDto
        return output;
      },
      /**
        * 
        * @summary Retrieve a session by token
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchSession(options: any = {}): FetchArgs {
        const localVarPath = `/sessions`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 */
export class SessionsApiBase {
  /**
    * 
    * @summary Create a session
    * @param {CreateSessionRequestDto} createSessionRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof SessionsApi
    */
  public createSession(createSessionRequestDto: CreateSessionRequestDto, options?: any, additionalProperties?: any): Promise<CreateSessionResponseDto> {
    const localVarFetchArgs = SessionsApiFetchParamCreator().createSession(createSessionRequestDto, options);
    return <Promise<CreateSessionResponseDto>> cbClient.cbFetch("POST", {operationId: "createSession", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve a session by token
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof SessionsApi
    */
  public fetchSession(options?: any, additionalProperties?: any): Promise<FetchSessionResponseDto> {
    const localVarFetchArgs = SessionsApiFetchParamCreator().fetchSession(options);
    return <Promise<FetchSessionResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchSession", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * SettlementsApi - fetch parameter creator
 */
const SettlementsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Get settlement by ID
        * @param {string} merchantId The unique identifier of a merchant for whom the settlements belongs to.
        * @param {string} settlementId The unique identifier of the settlement.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchSettlementById(merchantId: string, settlementId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchSettlementById.');
        }
        // verify required parameter 'settlementId' is not null or undefined
        if (settlementId === null || settlementId === undefined) {
          throw new RequiredError('settlementId','Required parameter settlementId was null or undefined when calling fetchSettlementById.');
        }
        const localVarPath = `/merchants/{merchantId}/settlements/{settlementId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"settlementId"}}`, fixedEncodeURIComponent(String(settlementId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List settlements for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the settlements belongs to.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {Date} [settledAfter] 
        * @param {Date} [settledBefore] 
        * @param {number} [amountGt] 
        * @param {number} [amountGte] 
        * @param {number} [amountLt] 
        * @param {number} [amountLte] 
        * @param {number} [depositAmountGt] Deposit amount greater than the specified amount
        * @param {number} [depositAmountGte] Deposit amount greater than or equal to the specified amount
        * @param {number} [depositAmountLt] Deposit amount lesser than the specified amount
        * @param {number} [depositAmountLte] Deposit amount lesser than or equal to the specified amount
        * @param {Array<SettlementStatus>} [settlementStatus] Status of the Settlement
        * @param {Array<DepositStatus>} [depositStatus] Status of the Deposit
        * @param {Array<DepositType>} [depositType] Type of the Deposit
        * @param {Array<DepositSubType>} [depositSubType] SubType of the Deposit
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantSettlements(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, settledAfter?: Date, settledBefore?: Date, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, depositAmountGt?: number, depositAmountGte?: number, depositAmountLt?: number, depositAmountLte?: number, settlementStatus?: Array<SettlementStatus>, depositStatus?: Array<DepositStatus>, depositType?: Array<DepositType>, depositSubType?: Array<DepositSubType>, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantSettlements.');
        }
        const localVarPath = `/merchants/{merchantId}/settlements`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (settledAfter !== undefined) {
          localVarQueryParameter['settled_after'] = (settledAfter as any).toISOString();
        }
        if (settledBefore !== undefined) {
          localVarQueryParameter['settled_before'] = (settledBefore as any).toISOString();
        }
        if (amountGt !== undefined) {
          localVarQueryParameter['amount_gt'] = amountGt;
        }
        if (amountGte !== undefined) {
          localVarQueryParameter['amount_gte'] = amountGte;
        }
        if (amountLt !== undefined) {
          localVarQueryParameter['amount_lt'] = amountLt;
        }
        if (amountLte !== undefined) {
          localVarQueryParameter['amount_lte'] = amountLte;
        }
        if (depositAmountGt !== undefined) {
          localVarQueryParameter['deposit_amount_gt'] = depositAmountGt;
        }
        if (depositAmountGte !== undefined) {
          localVarQueryParameter['deposit_amount_gte'] = depositAmountGte;
        }
        if (depositAmountLt !== undefined) {
          localVarQueryParameter['deposit_amount_lt'] = depositAmountLt;
        }
        if (depositAmountLte !== undefined) {
          localVarQueryParameter['deposit_amount_lte'] = depositAmountLte;
        }
        if (settlementStatus) {
          localVarQueryParameter['settlement_status'] = settlementStatus.join(COLLECTION_FORMATS["csv"]);
        }
        if (depositStatus) {
          localVarQueryParameter['deposit_status'] = depositStatus.join(COLLECTION_FORMATS["csv"]);
        }
        if (depositType) {
          localVarQueryParameter['deposit_type'] = depositType.join(COLLECTION_FORMATS["csv"]);
        }
        if (depositSubType) {
          localVarQueryParameter['deposit_sub_type'] = depositSubType.join(COLLECTION_FORMATS["csv"]);
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
  }
};

/**
 * SettlementsApi - object-oriented interface
 * @export
 * @class SettlementsApi
 */
export class SettlementsApiBase {
  /**
    * 
    * @summary Get settlement by ID
    * @param {string} merchantId The unique identifier of a merchant for whom the settlements belongs to.
    * @param {string} settlementId The unique identifier of the settlement.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof SettlementsApi
    */
  public fetchSettlementById(merchantId: string, settlementId: string, options?: any, additionalProperties?: any): Promise<SettlementWithBreakdownDto> {
    const localVarFetchArgs = SettlementsApiFetchParamCreator().fetchSettlementById(merchantId, settlementId, options);
    return <Promise<SettlementWithBreakdownDto>> cbClient.cbFetch("GET", {operationId: "fetchSettlementById", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List settlements for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the settlements belongs to.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {Date} [settledAfter] 
    * @param {Date} [settledBefore] 
    * @param {number} [amountGt] 
    * @param {number} [amountGte] 
    * @param {number} [amountLt] 
    * @param {number} [amountLte] 
    * @param {number} [depositAmountGt] Deposit amount greater than the specified amount
    * @param {number} [depositAmountGte] Deposit amount greater than or equal to the specified amount
    * @param {number} [depositAmountLt] Deposit amount lesser than the specified amount
    * @param {number} [depositAmountLte] Deposit amount lesser than or equal to the specified amount
    * @param {Array<SettlementStatus>} [settlementStatus] Status of the Settlement
    * @param {Array<DepositStatus>} [depositStatus] Status of the Deposit
    * @param {Array<DepositType>} [depositType] Type of the Deposit
    * @param {Array<DepositSubType>} [depositSubType] SubType of the Deposit
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof SettlementsApi
    */
  public listMerchantSettlements(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, settledAfter?: Date, settledBefore?: Date, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, depositAmountGt?: number, depositAmountGte?: number, depositAmountLt?: number, depositAmountLte?: number, settlementStatus?: Array<SettlementStatus>, depositStatus?: Array<DepositStatus>, depositType?: Array<DepositType>, depositSubType?: Array<DepositSubType>, options?: any, additionalProperties?: any): Promise<SettlementDtoList> {
    const localVarFetchArgs = SettlementsApiFetchParamCreator().listMerchantSettlements(merchantId, cursor, size, sortBy, sort, startDate, endDate, settledAfter, settledBefore, amountGt, amountGte, amountLt, amountLte, depositAmountGt, depositAmountGte, depositAmountLt, depositAmountLte, settlementStatus, depositStatus, depositType, depositSubType, options);
    return <Promise<SettlementDtoList>> cbClient.cbFetch("GET", {operationId: "listMerchantSettlements", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}

/**
 * TransactionsApi - fetch parameter creator
 */
const TransactionsApiFetchParamCreator = function () {
  return {
      /**
        * 
        * @summary Create a sale in async process
        * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
        * @param {string} consumerId The unique identifier of a consumer for whom the sales are initiated.
        * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
        * @param {TransactionDto} transactionDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createAsyncSale(merchantId: string, consumerId: string, xIdempotencyKey: string, transactionDto: TransactionDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createAsyncSale.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling createAsyncSale.');
        }
        // verify required parameter 'xIdempotencyKey' is not null or undefined
        if (xIdempotencyKey === null || xIdempotencyKey === undefined) {
          throw new RequiredError('xIdempotencyKey','Required parameter xIdempotencyKey was null or undefined when calling createAsyncSale.');
        }
        // verify required parameter 'transactionDto' is not null or undefined
        if (transactionDto === null || transactionDto === undefined) {
          throw new RequiredError('transactionDto','Required parameter transactionDto was null or undefined when calling createAsyncSale.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/transactions`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
            if (xIdempotencyKey !== undefined && xIdempotencyKey !== null) {
                localVarHeaderParameter['X-Idempotency-Key'] = String(xIdempotencyKey);
            }

        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = transactionDto
        return output;
      },
      /**
        * 
        * @summary Create a transaction
        * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
        * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
        * @param {CreateTransactionRequestDto} createTransactionRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      createTransaction(merchantId: string, xIdempotencyKey: string, createTransactionRequestDto: CreateTransactionRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling createTransaction.');
        }
        // verify required parameter 'xIdempotencyKey' is not null or undefined
        if (xIdempotencyKey === null || xIdempotencyKey === undefined) {
          throw new RequiredError('xIdempotencyKey','Required parameter xIdempotencyKey was null or undefined when calling createTransaction.');
        }
        // verify required parameter 'createTransactionRequestDto' is not null or undefined
        if (createTransactionRequestDto === null || createTransactionRequestDto === undefined) {
          throw new RequiredError('createTransactionRequestDto','Required parameter createTransactionRequestDto was null or undefined when calling createTransaction.');
        }
        const localVarPath = `/merchants/{merchantId}/transactions`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
            if (xIdempotencyKey !== undefined && xIdempotencyKey !== null) {
                localVarHeaderParameter['X-Idempotency-Key'] = String(xIdempotencyKey);
            }

        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = createTransactionRequestDto
        return output;
      },
      /**
        * 
        * @summary Retrieve a consumer's transaction details
        * @param {string} merchantId The unique identifier of the merchant for whom the sales are performed.
        * @param {string} consumerId The unique identifier of the consumer for whom the sales are initiated.
        * @param {string} transactionId The unique identifier of a transaction.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchConsumerTransaction(merchantId: string, consumerId: string, transactionId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchConsumerTransaction.');
        }
        // verify required parameter 'consumerId' is not null or undefined
        if (consumerId === null || consumerId === undefined) {
          throw new RequiredError('consumerId','Required parameter consumerId was null or undefined when calling fetchConsumerTransaction.');
        }
        // verify required parameter 'transactionId' is not null or undefined
        if (transactionId === null || transactionId === undefined) {
          throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling fetchConsumerTransaction.');
        }
        const localVarPath = `/merchants/{merchantId}/consumers/{consumerId}/transactions/{transactionId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"consumerId"}}`, fixedEncodeURIComponent(String(consumerId)))
            .replace(`{${"transactionId"}}`, fixedEncodeURIComponent(String(transactionId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary Retrieve a merchant's transaction details
        * @param {string} merchantId The unique identifier of the merchant for whom the sales are performed.
        * @param {string} transactionId The unique identifier of a transaction.
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      fetchTransaction(merchantId: string, transactionId: string, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling fetchTransaction.');
        }
        // verify required parameter 'transactionId' is not null or undefined
        if (transactionId === null || transactionId === undefined) {
          throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling fetchTransaction.');
        }
        const localVarPath = `/merchants/{merchantId}/transactions/{transactionId}`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"transactionId"}}`, fixedEncodeURIComponent(String(transactionId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * 
        * @summary List transactions for a merchant
        * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
        * @param {string} [cursor] 
        * @param {number} [size] 
        * @param {SortBy} [sortBy] 
        * @param {Sort} [sort] 
        * @param {Date} [startDate] 
        * @param {Date} [endDate] 
        * @param {Array<TransactionType>} [type] Type of transaction
        * @param {Array<TransactionStatus>} [status] Status of the transaction
        * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
        * @param {number} [amountGt] 
        * @param {number} [amountGte] 
        * @param {number} [amountLt] 
        * @param {number} [amountLte] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      listMerchantTransactions(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, type?: Array<TransactionType>, status?: Array<TransactionStatus>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling listMerchantTransactions.');
        }
        const localVarPath = `/merchants/{merchantId}/transactions`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        if (cursor !== undefined) {
          localVarQueryParameter['cursor'] = cursor;
        }
        if (size !== undefined) {
          localVarQueryParameter['size'] = size;
        }
        if (sortBy !== undefined) {
          localVarQueryParameter['sort_by'] = sortBy;
        }
        if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
        }
        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = (startDate as any).toISOString();
        }
        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = (endDate as any).toISOString();
        }
        if (type) {
          localVarQueryParameter['type'] = type.join(COLLECTION_FORMATS["csv"]);
        }
        if (status) {
          localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS["csv"]);
        }
        if (paymentMethodType) {
          localVarQueryParameter['payment_method_type'] = paymentMethodType.join(COLLECTION_FORMATS["csv"]);
        }
        if (amountGt !== undefined) {
          localVarQueryParameter['amount_gt'] = amountGt;
        }
        if (amountGte !== undefined) {
          localVarQueryParameter['amount_gte'] = amountGte;
        }
        if (amountLt !== undefined) {
          localVarQueryParameter['amount_lt'] = amountLt;
        }
        if (amountLte !== undefined) {
          localVarQueryParameter['amount_lte'] = amountLte;
        }

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        return output;
      },
      /**
        * Settle transactions which are in INITIATION_AT_PAYFAC_FAILED, QUEUED_FAILED, QUEUED_ACCEPTED and QUEUED_SUCCEEDED states.
        * @summary Settle trasactions
        * @param {SettleTransactionsRequestDto} settleTransactionsRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      settleTransactions(settleTransactionsRequestDto: SettleTransactionsRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'settleTransactionsRequestDto' is not null or undefined
        if (settleTransactionsRequestDto === null || settleTransactionsRequestDto === undefined) {
          throw new RequiredError('settleTransactionsRequestDto','Required parameter settleTransactionsRequestDto was null or undefined when calling settleTransactions.');
        }
        const localVarPath = `/transactions/settle`;
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = settleTransactionsRequestDto
        return output;
      },
      /**
        * 
        * @summary Submit transaction details
        * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
        * @param {string} transactionId The unique identifier of a transaction.
        * @param {TransactionDetailRequestDto} transactionDetailRequestDto 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
      submitTransactionDetails(merchantId: string, transactionId: string, transactionDetailRequestDto: TransactionDetailRequestDto, options: any = {}): FetchArgs {
        // verify required parameter 'merchantId' is not null or undefined
        if (merchantId === null || merchantId === undefined) {
          throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling submitTransactionDetails.');
        }
        // verify required parameter 'transactionId' is not null or undefined
        if (transactionId === null || transactionId === undefined) {
          throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling submitTransactionDetails.');
        }
        // verify required parameter 'transactionDetailRequestDto' is not null or undefined
        if (transactionDetailRequestDto === null || transactionDetailRequestDto === undefined) {
          throw new RequiredError('transactionDetailRequestDto','Required parameter transactionDetailRequestDto was null or undefined when calling submitTransactionDetails.');
        }
        const localVarPath = `/merchants/{merchantId}/transactions/{transactionId}/details`
            .replace(`{${"merchantId"}}`, fixedEncodeURIComponent(String(merchantId)))
            .replace(`{${"transactionId"}}`, fixedEncodeURIComponent(String(transactionId)));
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;
        localVarHeaderParameter['Content-Type'] = 'application/json';

        if(options.urlencoded) {
          localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        delete options.urlencoded;

        let output = {
          url: localVarPath,
          headers: Object.assign(localVarHeaderParameter, options),
          queryParams: localVarQueryParameter,
        };
        output['body'] = transactionDetailRequestDto
        return output;
      },
  }
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 */
export class TransactionsApiBase {
  /**
    * 
    * @summary Create a sale in async process
    * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
    * @param {string} consumerId The unique identifier of a consumer for whom the sales are initiated.
    * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
    * @param {TransactionDto} transactionDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof TransactionsApi
    */
  public createAsyncSale(merchantId: string, consumerId: string, xIdempotencyKey: string, transactionDto: TransactionDto, options?: any, additionalProperties?: any): Promise<TransactionDto> {
    const localVarFetchArgs = TransactionsApiFetchParamCreator().createAsyncSale(merchantId, consumerId, xIdempotencyKey, transactionDto, options);
    return <Promise<TransactionDto>> cbClient.cbFetch("POST", {operationId: "createAsyncSale", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Create a transaction
    * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
    * @param {string} xIdempotencyKey An idempotency key is a unique value generated by the client which the resource server uses to recognize subsequent retries of the same request.
    * @param {CreateTransactionRequestDto} createTransactionRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof TransactionsApi
    */
  public createTransaction(merchantId: string, xIdempotencyKey: string, createTransactionRequestDto: CreateTransactionRequestDto, options?: any, additionalProperties?: any): Promise<CreateTransactionResponseDto> {
    const localVarFetchArgs = TransactionsApiFetchParamCreator().createTransaction(merchantId, xIdempotencyKey, createTransactionRequestDto, options);
    return <Promise<CreateTransactionResponseDto>> cbClient.cbFetch("POST", {operationId: "createTransaction", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve a consumer's transaction details
    * @param {string} merchantId The unique identifier of the merchant for whom the sales are performed.
    * @param {string} consumerId The unique identifier of the consumer for whom the sales are initiated.
    * @param {string} transactionId The unique identifier of a transaction.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof TransactionsApi
    */
  public fetchConsumerTransaction(merchantId: string, consumerId: string, transactionId: string, options?: any, additionalProperties?: any): Promise<TransactionResponseDto> {
    const localVarFetchArgs = TransactionsApiFetchParamCreator().fetchConsumerTransaction(merchantId, consumerId, transactionId, options);
    return <Promise<TransactionResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchConsumerTransaction", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Retrieve a merchant's transaction details
    * @param {string} merchantId The unique identifier of the merchant for whom the sales are performed.
    * @param {string} transactionId The unique identifier of a transaction.
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof TransactionsApi
    */
  public fetchTransaction(merchantId: string, transactionId: string, options?: any, additionalProperties?: any): Promise<TransactionResponseDto> {
    const localVarFetchArgs = TransactionsApiFetchParamCreator().fetchTransaction(merchantId, transactionId, options);
    return <Promise<TransactionResponseDto>> cbClient.cbFetch("GET", {operationId: "fetchTransaction", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary List transactions for a merchant
    * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
    * @param {string} [cursor] 
    * @param {number} [size] 
    * @param {SortBy} [sortBy] 
    * @param {Sort} [sort] 
    * @param {Date} [startDate] 
    * @param {Date} [endDate] 
    * @param {Array<TransactionType>} [type] Type of transaction
    * @param {Array<TransactionStatus>} [status] Status of the transaction
    * @param {Array<PaymentMethodType>} [paymentMethodType] Type of payment method
    * @param {number} [amountGt] 
    * @param {number} [amountGte] 
    * @param {number} [amountLt] 
    * @param {number} [amountLte] 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof TransactionsApi
    */
  public listMerchantTransactions(merchantId: string, cursor?: string, size?: number, sortBy?: SortBy, sort?: Sort, startDate?: Date, endDate?: Date, type?: Array<TransactionType>, status?: Array<TransactionStatus>, paymentMethodType?: Array<PaymentMethodType>, amountGt?: number, amountGte?: number, amountLt?: number, amountLte?: number, options?: any, additionalProperties?: any): Promise<TransactionsResponseDto> {
    const localVarFetchArgs = TransactionsApiFetchParamCreator().listMerchantTransactions(merchantId, cursor, size, sortBy, sort, startDate, endDate, type, status, paymentMethodType, amountGt, amountGte, amountLt, amountLte, options);
    return <Promise<TransactionsResponseDto>> cbClient.cbFetch("GET", {operationId: "listMerchantTransactions", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * Settle transactions which are in INITIATION_AT_PAYFAC_FAILED, QUEUED_FAILED, QUEUED_ACCEPTED and QUEUED_SUCCEEDED states.
    * @summary Settle trasactions
    * @param {SettleTransactionsRequestDto} settleTransactionsRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof TransactionsApi
    */
  public settleTransactions(settleTransactionsRequestDto: SettleTransactionsRequestDto, options?: any, additionalProperties?: any): Promise<any> {
    const localVarFetchArgs = TransactionsApiFetchParamCreator().settleTransactions(settleTransactionsRequestDto, options);
    return <Promise<any>> cbClient.cbFetch("POST", {operationId: "settleTransactions", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
  /**
    * 
    * @summary Submit transaction details
    * @param {string} merchantId The unique identifier of a merchant for whom the sales are performed.
    * @param {string} transactionId The unique identifier of a transaction.
    * @param {TransactionDetailRequestDto} transactionDetailRequestDto 
    * @param {*} [options] Override http request option.
    * @param {*} [additionalProperties] Additional Properties can be used to transform the data on hitting the API
    * @throws {RequiredError}
    * @memberof TransactionsApi
    */
  public submitTransactionDetails(merchantId: string, transactionId: string, transactionDetailRequestDto: TransactionDetailRequestDto, options?: any, additionalProperties?: any): Promise<CreateTransactionResponseDto> {
    const localVarFetchArgs = TransactionsApiFetchParamCreator().submitTransactionDetails(merchantId, transactionId, transactionDetailRequestDto, options);
    return <Promise<CreateTransactionResponseDto>> cbClient.cbFetch("POST", {operationId: "submitTransactionDetails", isDecimalEnabledByDefault: false,  additionalProperties}, localVarFetchArgs.url, localVarFetchArgs.queryParams, localVarFetchArgs.headers, localVarFetchArgs.body)   
  }
}
