<template>
  <div class="review-and-add-funds-overview">
    <div class="review-and-add-funds-container">
      <div class="review-and-add-funds-title">
        <div class="review-and-add-funds-header">
          {{ $t('addFundsToReserve.tabs.reviewAndConfirm') }}
        </div>
        <div class="review-and-add-funds-sub-header">
          {{ $t('addFundsToReserve.desc.reviewAndConfirm') }}
        </div>
      </div>
      <div class="divider"></div>
      <div class="review-bank-account-details-main-content">
        <div class="review-bad-title-section">
          <span>{{ $t('global.fieldName.bankAccount') }}</span>
          <div class="review-bad-edit-panel" @click="switchTabOnEdit('SELECT_BANK_ACCOUNT')">
            <c-icon name="pencil" :size="14" />
            <span>{{ $t('global.action.change') }}</span>
          </div>
        </div>
        <div class="selected-bank-account-view">
          <div class="selected-bank-account-details">
            <span>{{ getBankAccountName }}</span>
            <span class="account-number-and-amount-container">
              {{ getBankAccountDesc }}
            </span>
          </div>
        </div>
      </div>
      <div class="review-added-amount-main-content">
        <div class="review-added-amount-section">
          <span>{{ $t('global.fieldName.amount') }}</span>
          <div class="review-added-amount-edit-panel" @click="switchTabOnEdit('ENTER_AMOUNT')">
            <c-icon name="pencil" :size="14" />
            <span>{{ $t('global.action.change') }}</span>
          </div>
        </div>
        <div class="added-amount-to-funds-view">
          <div class="added-amount-to-funds-details">
            <span>{{ $t('addFundsToReserve.reserves.refundReserve') }}</span>
            <span class="account-number-and-amount-container">
              {{ getFormattedCurrency(getAmountAddedForRefundReserve) }}
            </span>
          </div>
        </div>
        <div class="added-amount-to-funds-view">
          <div class="added-amount-to-funds-details">
            <span>{{ $t('addFundsToReserve.reserves.chargebackReserve') }}</span>
            <span class="account-number-and-amount-container">
              {{ getFormattedCurrency(getAmountAddedForChargebackReserve) }}
            </span>
          </div>
        </div>
      </div>
      <div class="review-added-amount-total">
        <span> {{ $t('global.fieldName.total') }} </span>
        <div class="sum-added-funds">
          <span>{{ getFormattedCurrency(getTotalOfEnteredAmountToReserve) }} +</span>
          <span>{{ getFormattedCurrency(getDirectDebitFeeSplitsForFundTransfer.getTotalFee()) }}</span>
          <div class="added-funds-fees-label">
            <ToolTip
              placement="top-start"
              content=""
              :arrow="false"
              disabled="true"
            />
            ({{ $t('addFundsToReserve.labels.fees.' + getBankAccountType) }}) =
          </div>
          <span class="sum-added-funds-total">{{ getFormattedCurrency(getTotalOfEnteredAmountToReserveWithFees) }}</span>
        </div>
      </div>
    </div>
    <div class="review-and-funds-banner">
      <c-icon name="information" :size="18" />
      <div class="direct-debit-fund-transfer-info">
        {{ $t('addFundsToReserve.message.directDebitFundTransferInfo', {'bankAccountType' : getFormattedBankAccountType}) }}
      </div>
    </div>
    <div class="confirm-to-add-funds">
      <c-button variant="primary" @click="onSubmit" :loading="loading">
        {{ $t('global.action.confirm') }}
      </c-button>
    </div>
  </div>
</template>

<script>
import Formatter from "@/app/utils/common/functions/formatter";
import {mapGetters} from "vuex";
import {isNullOrEmpty} from "@/app/utils/common/functions/functions";
import ToolTip from "@/components/common/ToolTip.vue";
export default {
  name: "ReviewAndAddFunds",
  components: {ToolTip},
  props: {
    onSubmit: {
      type: Function,
      required: true
    },
    switchTabOnEdit: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters('merchantBalanceReserve', [
        'getAmountAddedForRefundReserve',
        'getAmountAddedForChargebackReserve',
        'getDirectDebitFeeSplitsForFundTransfer',
        'getSelectedPaymentInstrumentForDirectDebitFundTransfer',
        ]),
    ...mapGetters('app', ['getMerchantCurrency']),
    getBankAccountType() {
      return this.getSelectedPaymentInstrumentForDirectDebitFundTransfer.bank_account.type;
    },
    getFormattedBankAccountType() {
      let bankAccountType = this.getBankAccountType.split("_");
      return bankAccountType[0] + " " + bankAccountType[1].charAt(0).toUpperCase() + bankAccountType[1]
          .substring(1).toLowerCase();
    },
    getBankAccountDesc() {
      if (isNullOrEmpty(this.getSelectedPaymentInstrumentForDirectDebitFundTransfer)) return ;
      let paymentInstrument = this.getSelectedPaymentInstrumentForDirectDebitFundTransfer.bank_account;
      return `xxxxxxxx${paymentInstrument.last_four}`;
    },
    getBankAccountName() {
      if (isNullOrEmpty(this.getSelectedPaymentInstrumentForDirectDebitFundTransfer)) return ;
      return this.getSelectedPaymentInstrumentForDirectDebitFundTransfer.bank_account.name
    },
    getTotalOfEnteredAmountToReserve() {
      return this.getAmountAddedForRefundReserve +
          this.getAmountAddedForChargebackReserve;
    },
    getTotalOfEnteredAmountToReserveWithFees() {
      return this.getTotalOfEnteredAmountToReserve + this.getDirectDebitFeeSplitsForFundTransfer.getTotalFee();
    }
  },
  methods: {
    getFormattedCurrency(value) {
      if (isNullOrEmpty(value)) return '-';
      return Formatter.format({
        type: "currency",
        data: value,
        currencyCode: this.getMerchantCurrency
      })
    },
  },
}
</script>

<style lang="scss">
.review-and-add-funds-overview {
  text-align: left;

  & .review-and-add-funds-container {
    margin-bottom: 60px;
    & .review-and-add-funds-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      & .review-and-add-funds-header {
        font-size: 24px;
        font-weight: $weight_bold;
      }

      & .review-and-add-funds-sub-header {
        font-size: 12px;
        font-weight: $weight_normal;
      }
    }

    & .divider {
      width: 100%;
      background: $neutral_200;
      height: 1px;
      margin-top: 20px;
    }

    & .account-number-and-amount-container {
      font-size: 12px;
      color: $neutral_400;
    }

    & .review-bank-account-details-main-content {
      margin-top:20px;

      & .review-bad-title-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        font-size: 16px;
        height: 30px;
        font-weight: $weight_bold;
        color: $neutral_800;

        & .review-bad-edit-panel {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          font-weight: $weight_bold;
          color: $primary_600;
        }

        & .review-bad-edit-panel:hover {
          cursor: pointer;
        }
      }

      & .selected-bank-account-view {
        margin-top: 12px;
        padding: 12px;
        border-radius: 6px;
        border: 1px solid $neutral_100;

        & .selected-bank-account-details{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;
        }
      }
    }

    & .review-added-amount-main-content {
      margin-top: 20px;

      & .review-added-amount-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        font-size: 16px;
        height: 30px;
        font-weight: $weight_bold;
        color: $neutral_800;

        & .review-added-amount-edit-panel {
          display: flex;
          align-items: center;
          font-size: 12px;
          gap: 4px;
          font-weight: $weight_bold;
          color: $primary_600;
        }

        & .review-added-amount-edit-panel:hover {
          cursor: pointer;
        }
      }

      & .added-amount-to-funds-view {
        margin-top: 12px;
        padding: 12px;
        border-radius: 6px;
        border: 1px solid $neutral_100;

        & .added-amount-to-funds-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;
        }
      }
    }

    & .review-added-amount-total {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: $neutral_800;
      font-size: 16px;
      font-weight: $weight_bold;

      & .sum-added-funds {
        font-size: 14px;
        font-weight: $weight_normal;
        display: flex;
        gap: 4px;

          & .added-funds-fees-label {
            display: flex;
            align-items: center;
            gap: 2px;
            color: $neutral_400;
          }

          & .sum-added-funds-total {
            font-weight: $weight_bold;
          }
      }
    }
  }

  & .review-and-funds-banner {
    display: flex;
    align-items: start;
    background: $info_50;
    border: 0.5px solid $info_100;
    border-radius: 4px;
    padding: 6px 8px;
    gap: 10px;
    color: $primary_600;
    line-height: 18px;

    & .direct-debit-fund-transfer-info {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: $weight_normal;
      color: $info-600;
    }
  }

  & .confirm-to-add-funds {
    display: flex;
    align-items: center;
    margin-top: 32px;
    max-width: 120px;

    & .c-button--primary {
      margin-bottom: 0;
      width: 140px;
    }
  }
}
</style>