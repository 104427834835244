import { format, length, presence, regex, strictInt } from "@/app/validators/api/customValidations";
import { Country } from "@/app/utils/common/constants";

export const organisationRegistrationNumber = {
    [Country.FRA]: {
        length: length(14,14), "strictInt": strictInt(), presence: presence()
    },
    [Country.DEU]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$"), presence: presence()
    },
    [Country.GBR]: {
        length: length(8,8), presence: presence()
    },
    [Country.ESP]: {
        regex: regex("^[a-zA-Z][a-zA-Z0-9]{8}$", "start with 1 letter followed by 8 alphanumeric characters"), presence: presence()
    },
    [Country.NLD]: {
        length: length(8,8), "strictInt": strictInt(), presence: presence()
    },
    [Country.BEL]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.ITA]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
}

export const organisationTaxId = {
    [Country.DEU]: {
        length: length(10,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.GBR]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.USA]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    },
    [Country.NLD]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    },
    [Country.BEL]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    }
}

export const organisationVatNumber = {
    [Country.FRA]: {
        regex: regex("^FR\\d{11}$", "start with FR followed by 11 digits"), presence: presence()
    },
    [Country.DEU]: {
        regex: regex("^DE\\d{9}$", "start with DE followed by 9 digits"), presence: presence()
    },
    [Country.GBR]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits"), presence: presence()
    },
    [Country.ESP]: {
        regex: regex("^ES[a-zA-Z0-9]{9}$", "start with ES followed by 9 alphanumeric characters"), presence: presence()
    },
    [Country.NLD]: {
        regex: regex("^NL\\d{9}B\\d{2}$", "start with NL followed by 9 alphanumeric characters and B followed by 2 digits"), presence: presence()
    },
    [Country.ITA]: {
        regex: regex("^IT\\d{11}$", "start with IT followed by 11 digits"), presence: presence()
    },
    [Country.BEL]: {
        regex: regex("^BE\\d{10}$", "start with BE followed by 10 digits"), presence: presence()
    },
}

export const soleProprietorRegistrationNumber = {
    [Country.FRA]: {
        length: length(14,14), "strictInt": strictInt(), presence: presence()
    },
    [Country.DEU]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$"), presence: presence()
    },
    [Country.GBR]: {
        length: length(8,8), "strictInt": strictInt(), presence: presence()
    },
    [Country.NLD]: {
        length: length(8,8), "strictInt": strictInt(), presence: presence()
    },
    [Country.BEL]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.ITA]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
}

export const soleProprietorTaxId = {
    [Country.USA]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    }
}

export const soleProprietorVatNumber = {
    [Country.GBR]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits"), presence: presence()
    },
    [Country.ITA]: {
        regex: regex("^IT\\d{11}$", "start with IT followed by 11 digits"), presence: presence()
    },
}

export const accountNumber = {
    [Country.GBR]: {
        length: length(8,8), "strictInt": strictInt(), presence: presence()
    },
    [Country.USA]: {
        length: length(3,17), "strictInt": strictInt(), presence: presence()
    }
}

export const bankCode = {
    [Country.USA]: {
        length: length(9,10), "strictInt": strictInt(), presence: presence()
    }
}

export const iban = {
    [Country.FRA]: {
       // regex: regex("^FR[A-Za-z0-9]{25}$", "start with FR followed by 25 characters")
        presence: presence()
    },
    [Country.DEU]: {
        // regex: regex("^DE[A-Za-z0-9]{20}$", "start with DE followed by 20 characters")
        presence: presence()
    },
    [Country.ESP]: {
        // regex: regex("^ES[A-Za-z0-9]{22}$", "start with ES followed by 22 characters")
        presence: presence()
    },
    [Country.NLD]: {
        // regex: regex("^NL[A-Za-z0-9]{16}$", "start with ES followed by 22 characters")
        presence: presence()
    },
    [Country.BEL]: {
        presence: presence()
    },
    [Country.ITA]: {
        presence: presence()
    },
}

export const sortCode = {
    [Country.GBR]: {
        length: length(6,6), "strictInt": strictInt(), presence: presence()
    }
}

export const accountType = {
    [Country.USA]: {
        presence: presence()
    }
}

export const beneficiaryTaxId = {
    [Country.USA]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    },
    [Country.GBR]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.DEU]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.FRA]: {
        length: length(13,13), "strictInt": strictInt(), presence: presence()
    },
    [Country.ESP]: {
        regex: regex("^[a-zA-Z0-9]{9,10}$", "have 9-10 alphanumeric characters"), presence: presence()
    },
    [Country.NLD]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    },
    [Country.BEL]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.ITA]: {
        regex: regex("^[a-zA-Z0-9]{16}$", "have 16 alphanumeric characters"), presence: presence()
    },

    [Country.AUT]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    },
    [Country.BGR]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.HRV]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.CYP]: {
        length: length(9,9), presence: presence()
    },
    [Country.CZE]: {
        length: length(9,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.DNK]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.FIN]: {
        length: length(11,11), presence: presence()
    },
    [Country.GRC]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    },
    [Country.HUN]: {
        length: length(10,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.IRL]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.LVA]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.LIE]: {
        length: length(1,12), "strictInt": strictInt(), presence: presence()
    },
    [Country.LTU]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.LUX]: {
        length: length(13,13), "strictInt": strictInt(), presence: presence()
    },
    [Country.MLT]: {
        regex: regex("^(d{7}[a-zA-Z]|d{9})$", "be 7 digits + 1 letter or 9 digits"), presence: presence()
    },
    [Country.NOR]: {
        length: length(11,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.POL]: {
        length: length(10,11), "strictInt": strictInt(), presence: presence()
    },
    [Country.PRT]: {
        length: length(9,9), "strictInt": strictInt(), presence: presence()
    },
    [Country.ROU]: {
        length: length(13,13), "strictInt": strictInt(), presence: presence()
    },
    [Country.SVK]: {
        length: length(9,10), "strictInt": strictInt(), presence: presence()
    },
    [Country.SVN]: {
        length: length(8,8), "strictInt": strictInt(), presence: presence()
    },
    [Country.SWE]: {
        length: length(10,10), presence: presence()
    },

}
export const stateCode = {
    [Country.USA]: {
        presence: presence()
    },
    ["CAN"]: {
        presence: presence()
    },
    ["IND"]: {
        presence: presence()
    }
}
