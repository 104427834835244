<template>
  <div class="multi-select-dropdown-filter-field" :id="entity">
    <c-dropdown
      :class="{'disabled': disabled}"
      :close-on-select="false"
      :disabled="disabled"
      class="filter"
    >
      <c-button
        slot="trigger"
        class="filter-button"
        :id="pendoElementId"
      >
        <div class="filter-name-value-container">
          <div class="filter-name">
            {{
              $t('filters.' + entity + '.label')
            }}{{ selectedList.length === 0 ? "" : ":" }}
          </div>
          <div class="filter-value">
            <div
              v-if="selectedList.length === options.length"
              class="filter-value-item"
            >
              {{ $t('filters.' + entity + '.ALL') }}
            </div>
            <div v-else-if="selectedList.length <= 2" class="filter-value-container">
              <div
                v-for="paymentMethodFilter in selectedList" :key="paymentMethodFilter"
                class="filter-value-item"
              >
                {{ $t('filters.' + entity + '.' + paymentMethodFilter) }}
              </div>
            </div>
            <div v-else class="filter-value-item">
              {{ selectedList.length }}
              {{ $t('filters.' + entity + '.selected') }}
            </div>
          </div>
        </div>
        <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
      </c-button>

      <c-dropdown-item
        v-for="filter in options"
        :key="filter.name"
        :option="filter.name"
        class="filter-date-item-container"
        @click="update(filter.name)"
      >
        <c-checkbox
          :checked="selectedList.includes(filter.name)"
          @click="update(filter.name)"
        >
          {{ $t('filters.' + entity + '.' + filter.name) }}
        </c-checkbox>
      </c-dropdown-item>
    </c-dropdown>
  </div>
</template>

<script>

export default {
  name: 'MultiSelectDropdown',
  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    pendoElementId: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    selectedList: {
      type: Array,
      required: true
    },
    update: {
      type: Function,
      required: true
    },
    entity: {
      type: String,
      required: true
    }
  }
}

</script>