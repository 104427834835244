<template>
  <div class="amount-filter-field">
    <c-dropdown
      :class="{'disabled': disabled}"
      :close-on-select="false"
      :disabled="disabled"
      class="filter"
    >
      <c-button
        slot="trigger"
        class="filter-button"
        :id="pendoElementId"
      >
        <div class="filter-name-value-container">
          <div class="filter-name">
            {{ legend }}
            {{ (amountInput1 === 0 && amountInput2 === 0) ? "" : ":" }}
          </div>
          <div class="filter-value">
            <div
              class="filter-value-item"
              v-if="amountInput1 !== 0 || amountInput2 !== 0"
            >
              {{ $t('filters.amountFilter.operator.label.' + currentOperator) }}
              {{ getAmountInput1WithPrepend }}
              {{ currentOperator === betweenOp ? getAmountInput2WithPrepend : '' }}
            </div>
          </div>
        </div>
        <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
      </c-button>

      <c-dropdown-item
        class="filter-amount-item-container"
      >
        <c-dropdown
          :class="{'disabled': disabled}"
          :disabled="disabled"
          class="sub-filter"
        >
          <c-button
            slot="trigger"
            class="filter-button"
          >
            <div class="filter-name">
              {{
                $t('filters.amountFilter.operator.' + currentOperator)
              }}
            </div>
            <img alt="dropdown" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
          </c-button>

          <c-dropdown-item
            v-for="filter in operatorList"
            :key="filter.name"
            :option="filter.name"
            class="filter-date-item-container"
            @click="onOperatorChange(filter.name)"
          >
            <img
              :class="{'filter-date-item-active': filter.name === currentOperator}"
              alt="check"
              class="filter-date-item-check"
              src="@/app/assets/images/check.svg"
            />
            <div>{{ $t('filters.amountFilter.operator.' + filter.name) }}</div>
          </c-dropdown-item>
        </c-dropdown>
      </c-dropdown-item>
      <div class="amount-input-container">
        <div class="amount-field">
          <InputField
            id="amount-input"
            :set-value="setValue1"
            :value="amountInput1"
            :prepend-slot="inputPrepend"
            type="Number"
            label=""
            :space-over="false"
          />
        </div>

        <div class="between-separator" v-if="currentOperator === betweenOp">
          and
        </div>

        <div class="amount-field" v-if="currentOperator === betweenOp">
          <InputField
            id="amount-input"
            :set-value="setValue2"
            :value="amountInput2"
            :prepend-slot="inputPrepend"
            type="Number"
            label=""
            :space-over="false"
          />
        </div>
      </div>
    </c-dropdown>
  </div>
</template>

<script>
import { AmountFilterOption } from "@/app/utils/common/tableFilterOptions";
import InputField from "@/components/common/InputField.vue";
export default {
  name: 'AmountFilter',
  components: { InputField },
  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    amountInput1: {
      type: Number,
      required: true
    },
    amountInput2: {
      type: Number,
      required: true
    },
    inputPrepend: {
      type: String,
      default: null,
      required: false
    },
    setValue1: {
      type: Function,
      required: true,
    },
    setValue2: {
      type: Function,
      required: true,
    },
    onOperatorChange: {
      type: Function,
      required: true
    },
    currentOperator: {
      type: String,
      required: true
    },
    pendoElementId: {
      type: String,
      required: true
    },
    legend: {
      type: String,
      required: true
    },
    tableLabel: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    operatorList() {
      return AmountFilterOption;
    },
    getAmountInput1WithPrepend() {
      return (this.inputPrepend) ? (this.inputPrepend + " " + this.amountInput1) : this.amountInput1;
    },
    getAmountInput2WithPrepend() {
      return (this.inputPrepend) ? ('and ' + this.inputPrepend + " " + this.amountInput2) : ('and ' + this.amountInput2);
    },
    betweenOp() {
      return this.operatorList.find(op => op.name === 'between').name;
    }
  }
}
</script>

<style lang="scss">

.amount-filter-field {

& .amount-input-container {
  margin: 5px 16px 0 16px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  & .between-separator {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral_800;
    padding: 0 8px;
    display: flex;
    align-items: center;
  }


  & .amount-field {
    margin: 0;
    padding: 0;
    height: fit-content;
    width: 100%;
    position: relative;
  }

  & .c-input__group {
    height: 30px;
    padding: 5px 12px;
    font-weight: $weight_normal;
    font-size: 12px;
  }
}
}
</style>