<template>
  <div class="field">
    <c-label :label="label" />
    <div class="telephone">
      <c-select
        autofocus
        :value="getCountryByCode(countryCode)"
        :disabled="disableCountryCode"
        :searchable="true"
        :clearable="false"
        :options="getCountries()"
        @update="(selectedCountryCode) => setCountryCode(getCountryCodeByCountryAbbreviation(selectedCountryCode))"
        :class="'c-flex-grow-1 phone-select'"
      >
        <template #option="options">
          <div class="dial-code-option">
            <div>{{ options.option.displayText }}</div>
            <div>{{ options.option.countryCode }}</div>
          </div>
        </template>
        <template #value="internalPlaceholder">
          <div class="selected-code">
            {{ getCountryCode(internalPlaceholder.value) }}
          </div>
        </template>
      </c-select>
      <c-input
        :value="value"
        :max-length="14"
        :id="id"
        @input="setPhoneNumber"
        @keyup.enter="onEnter"
        class="c-flex-grow-1 phone-input"
      />
    </div>
    <c-inline-error
      v-if="Boolean(error)"
      :message="error"
    />
  </div>
</template>
<script>
import { CountryCodeListSortedByName } from "@/data/country-code-list";
import {formatPhoneNumber} from "@/app/utils/common/functions/functions";
import {EventBus} from "@/events/event-bus";
import {FormEvents} from "@/app/utils/common/constants";

export default {
  name: "PhoneInput",
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: false,
      default: undefined
    },
    setValue: {
      type: Function,
      required: true
    },
    countryCode: {
      type: String,
      required: true
    },
    disableCountryCode: Boolean
  },
  data: function () {
    return {
      phone: this.value,
      phoneCountryCode: this.countryCode
    }
  },
  methods: {
    getCountries: () => CountryCodeListSortedByName.map(x => ({
      displayText: x.displayText,
      countryCode: x.dialCode,
      value: x.value,
    })),
    getCountryCode: function (name) {
      if (name === undefined) return "";
      return this.getCountries().find(x => x.displayText === name)?.countryCode
    },
    getCountryCodeByCountryAbbreviation: function (country) {
      if (country === undefined) return "";
      return this.getCountries().find(x => x.value === country)?.countryCode
    },
    getCountryByCode: function (code) {
      if (code === undefined) return "";
      return this.getCountries().find(x => x.countryCode === code)?.value
    },
    setCountryCode: function(countryCode) {
      this.phoneCountryCode = countryCode;
      this.setValue(countryCode, formatPhoneNumber(this.phone))
    },
    setPhoneNumber: function (event) {
      this.phone = event.target.value;
      this.setValue(this.phoneCountryCode, formatPhoneNumber(event.target.value))
    },
    onEnter() {
      EventBus.$emit(FormEvents.ENTER);
    },
  },
}
</script>
<style lang="scss">
.telephone {
  display: flex;
  flex-direction: row;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #bcbcd4;
  margin-bottom: 8px;
  align-items: start;

  & .dial-code-option {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &:hover {
    border: 1px solid #0052cc;
  }

  & .phone-select {
    &.c-select {
      margin-top: 0;
      margin-bottom: 0;
    }

    width: 20px;

    & .c-select__trigger {
      &:hover {
        border: none;
      }

      height: 38px;
      border: none;
      padding-left: 4px;
      padding-right: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
      min-width: 40px;

      & .c-select__value {
        width: 100%;
        text-align: center;
        min-width: 45px;
      }
    }

  }

  & .phone-input {
    &.c-input {
      margin-top: 0;
      margin-bottom: 0;
    }

    & .c-input__group {
      &:hover {
        border: none;
      }
      @media screen and (max-width: 950px) {
        padding-left: 55px;
      }
      @supports (-moz-appearance:none) {
        padding-left: 55px;
      }

      @media not all and (min-resolution:.001dpcm) {
        padding-left: 55px;
      }
      border: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none;
    }

    & .c-input__element {
      padding-left: 0;
    }
  }

}
</style>