export const TimeZoneList = [
  { value: 'Asia/Aden', code: 'AST', displayName: 'Asia/Aden (AST)' },
  {
    value: 'America/Cuiaba',
    code: 'AMT',
    displayName: 'America/Cuiaba (AMT)',
  },
  {
    value: 'Africa/Nairobi',
    code: 'EAT',
    displayName: 'Africa/Nairobi (EAT)',
  },
  {
    value: 'America/Marigot',
    code: 'AST',
    displayName: 'America/Marigot (AST)',
  },
  { value: 'Asia/Aqtau', code: 'AQTT', displayName: 'Asia/Aqtau (AQTT)' },
  {
    value: 'Pacific/Kwajalein',
    code: 'MHT',
    displayName: 'Pacific/Kwajalein (MHT)',
  },
  {
    value: 'America/El_Salvador',
    code: 'CST',
    displayName: 'America/El_Salvador (CST)',
  },
  {
    value: 'Asia/Pontianak',
    code: 'WIB',
    displayName: 'Asia/Pontianak (WIB)',
  },
  {
    value: 'Africa/Cairo',
    code: 'EET',
    displayName: 'Africa/Cairo (EET)',
  },
  {
    value: 'Pacific/Pago_Pago',
    code: 'SST',
    displayName: 'Pacific/Pago_Pago (SST)',
  },
  {
    value: 'Africa/Mbabane',
    code: 'SAST',
    displayName: 'Africa/Mbabane (SAST)',
  },
  {
    value: 'Asia/Kuching',
    code: 'MYT',
    displayName: 'Asia/Kuching (MYT)',
  },
  {
    value: 'Pacific/Honolulu',
    code: 'HST',
    displayName: 'Pacific/Honolulu (HST)',
  },
  {
    value: 'Pacific/Rarotonga',
    code: 'CKT',
    displayName: 'Pacific/Rarotonga (CKT)',
  },
  {
    value: 'America/Guatemala',
    code: 'CST',
    displayName: 'America/Guatemala (CST)',
  },
  {
    value: 'Australia/Hobart',
    code: 'AEDT',
    displayName: 'Australia/Hobart (AEDT)',
  },
  {
    value: 'Europe/London',
    code: 'GMT',
    displayName: 'Europe/London (GMT)',
  },
  {
    value: 'America/Belize',
    code: 'CST',
    displayName: 'America/Belize (CST)',
  },
  {
    value: 'America/Panama',
    code: 'EST',
    displayName: 'America/Panama (EST)',
  },
  {
    value: 'Asia/Chungking',
    code: 'CST',
    displayName: 'Asia/Chungking (CST)',
  },
  {
    value: 'America/Managua',
    code: 'CST',
    displayName: 'America/Managua (CST)',
  },
  {
    value: 'America/Indiana/Petersburg',
    code: 'EST',
    displayName: 'America/Indiana/Petersburg (EST)',
  },
  {
    value: 'Asia/Yerevan',
    code: 'AMT',
    displayName: 'Asia/Yerevan (AMT)',
  },
  {
    value: 'Europe/Brussels',
    code: 'CET',
    displayName: 'Europe/Brussels (CET)',
  },
  { value: 'GMT', code: 'GMT', displayName: 'GMT (GMT)' },
  {
    value: 'Europe/Warsaw',
    code: 'CET',
    displayName: 'Europe/Warsaw (CET)',
  },
  {
    value: 'America/Chicago',
    code: 'CST',
    displayName: 'America/Chicago (CST)',
  },
  {
    value: 'Asia/Kashgar',
    code: 'XJT',
    displayName: 'Asia/Kashgar (XJT)',
  },
  {
    value: 'Chile/Continental',
    code: 'CLST',
    displayName: 'Chile/Continental (CLST)',
  },
  {
    value: 'Pacific/Yap',
    code: 'CHUT',
    displayName: 'Pacific/Yap (CHUT)',
  },
  { value: 'CET', code: 'CET', displayName: 'CET (CET)' },
  {
    value: 'Europe/Jersey',
    code: 'GMT',
    displayName: 'Europe/Jersey (GMT)',
  },
  {
    value: 'America/Tegucigalpa',
    code: 'CST',
    displayName: 'America/Tegucigalpa (CST)',
  },
  {
    value: 'Europe/Istanbul',
    code: 'TRT',
    displayName: 'Europe/Istanbul (TRT)',
  },
  {
    value: 'America/Eirunepe',
    code: 'ACT',
    displayName: 'America/Eirunepe (ACT)',
  },
  {
    value: 'America/Miquelon',
    code: 'PMST',
    displayName: 'America/Miquelon (PMST)',
  },
  {
    value: 'Europe/Luxembourg',
    code: 'CET',
    displayName: 'Europe/Luxembourg (CET)',
  },
  {
    value: 'America/Argentina/Catamarca',
    code: 'ART',
    displayName: 'America/Argentina/Catamarca (ART)',
  },
  {
    value: 'Europe/Zaporozhye',
    code: 'EET',
    displayName: 'Europe/Zaporozhye (EET)',
  },
  {
    value: 'Canada/Yukon',
    code: 'MST',
    displayName: 'Canada/Yukon (MST)',
  },
  {
    value: 'Canada/Atlantic',
    code: 'AST',
    displayName: 'Canada/Atlantic (AST)',
  },
  {
    value: 'Atlantic/St_Helena',
    code: 'GMT',
    displayName: 'Atlantic/St_Helena (GMT)',
  },
  {
    value: 'Australia/Tasmania',
    code: 'AEDT',
    displayName: 'Australia/Tasmania (AEDT)',
  },
  { value: 'Libya', code: 'EET', displayName: 'Libya (EET)' },
  {
    value: 'Europe/Guernsey',
    code: 'GMT',
    displayName: 'Europe/Guernsey (GMT)',
  },
  {
    value: 'America/Grand_Turk',
    code: 'EST',
    displayName: 'America/Grand_Turk (EST)',
  },
  {
    value: 'Asia/Samarkand',
    code: 'UZT',
    displayName: 'Asia/Samarkand (UZT)',
  },
  {
    value: 'America/Argentina/Cordoba',
    code: 'ART',
    displayName: 'America/Argentina/Cordoba (ART)',
  },
  {
    value: 'Asia/Phnom_Penh',
    code: 'ICT',
    displayName: 'Asia/Phnom_Penh (ICT)',
  },
  {
    value: 'Africa/Kigali',
    code: 'CAT',
    displayName: 'Africa/Kigali (CAT)',
  },
  {
    value: 'Asia/Almaty',
    code: 'ALMT',
    displayName: 'Asia/Almaty (ALMT)',
  },
  { value: 'US/Alaska', code: 'AKST', displayName: 'US/Alaska (AKST)' },
  { value: 'Asia/Dubai', code: 'GST', displayName: 'Asia/Dubai (GST)' },
  {
    value: 'Europe/Isle_of_Man',
    code: 'GMT',
    displayName: 'Europe/Isle_of_Man (GMT)',
  },
  {
    value: 'America/Araguaina',
    code: 'BRT',
    displayName: 'America/Araguaina (BRT)',
  },
  { value: 'Cuba', code: 'CST', displayName: 'Cuba (CST)' },
  {
    value: 'Asia/Novosibirsk',
    code: 'NOVT',
    displayName: 'Asia/Novosibirsk (NOVT)',
  },
  {
    value: 'America/Argentina/Salta',
    code: 'ART',
    displayName: 'America/Argentina/Salta (ART)',
  },
  {
    value: 'Africa/Tunis',
    code: 'CET',
    displayName: 'Africa/Tunis (CET)',
  },
  {
    value: 'Pacific/Fakaofo',
    code: 'TKT',
    displayName: 'Pacific/Fakaofo (TKT)',
  },
  {
    value: 'Africa/Tripoli',
    code: 'EET',
    displayName: 'Africa/Tripoli (EET)',
  },
  { value: 'Israel', code: 'IST', displayName: 'Israel (IST)' },
  {
    value: 'Africa/Banjul',
    code: 'GMT',
    displayName: 'Africa/Banjul (GMT)',
  },
  {
    value: 'Indian/Comoro',
    code: 'EAT',
    displayName: 'Indian/Comoro (EAT)',
  },
  {
    value: 'Pacific/Port_Moresby',
    code: 'PGT',
    displayName: 'Pacific/Port_Moresby (PGT)',
  },
  { value: 'US/Arizona', code: 'MST', displayName: 'US/Arizona (MST)' },
  {
    value: 'Antarctica/Syowa',
    code: 'SYOT',
    displayName: 'Antarctica/Syowa (SYOT)',
  },
  {
    value: 'Indian/Reunion',
    code: 'RET',
    displayName: 'Indian/Reunion (RET)',
  },
  {
    value: 'Pacific/Palau',
    code: 'PWT',
    displayName: 'Pacific/Palau (PWT)',
  },
  {
    value: 'Europe/Kaliningrad',
    code: 'EET',
    displayName: 'Europe/Kaliningrad (EET)',
  },
  {
    value: 'America/Montevideo',
    code: 'UYT',
    displayName: 'America/Montevideo (UYT)',
  },
  {
    value: 'Africa/Windhoek',
    code: 'CAT',
    displayName: 'Africa/Windhoek (CAT)',
  },
  {
    value: 'Asia/Karachi',
    code: 'PKT',
    displayName: 'Asia/Karachi (PKT)',
  },
  {
    value: 'Africa/Mogadishu',
    code: 'EAT',
    displayName: 'Africa/Mogadishu (EAT)',
  },
  {
    value: 'Australia/Perth',
    code: 'AWST',
    displayName: 'Australia/Perth (AWST)',
  },
  { value: 'Brazil/East', code: 'BRT', displayName: 'Brazil/East (BRT)' },
  { value: 'Asia/Chita', code: 'YAKT', displayName: 'Asia/Chita (YAKT)' },
  {
    value: 'Pacific/Easter',
    code: 'EASST',
    displayName: 'Pacific/Easter (EASST)',
  },
  {
    value: 'Antarctica/Davis',
    code: 'DAVT',
    displayName: 'Antarctica/Davis (DAVT)',
  },
  {
    value: 'Antarctica/McMurdo',
    code: 'NZDT',
    displayName: 'Antarctica/McMurdo (NZDT)',
  },
  { value: 'Asia/Macao', code: 'CST', displayName: 'Asia/Macao (CST)' },
  {
    value: 'America/Manaus',
    code: 'AMT',
    displayName: 'America/Manaus (AMT)',
  },
  {
    value: 'Africa/Freetown',
    code: 'GMT',
    displayName: 'Africa/Freetown (GMT)',
  },
  {
    value: 'Europe/Bucharest',
    code: 'EET',
    displayName: 'Europe/Bucharest (EET)',
  },
  {
    value: 'Asia/Tomsk',
    code: 'Asia/Tomsk',
    displayName: 'Asia/Tomsk (Asia/Tomsk)',
  },
  {
    value: 'America/Argentina/Mendoza',
    code: 'ART',
    displayName: 'America/Argentina/Mendoza (ART)',
  },
  { value: 'Asia/Macau', code: 'CST', displayName: 'Asia/Macau (CST)' },
  {
    value: 'Europe/Malta',
    code: 'CET',
    displayName: 'Europe/Malta (CET)',
  },
  {
    value: 'Mexico/BajaSur',
    code: 'MST',
    displayName: 'Mexico/BajaSur (MST)',
  },
  {
    value: 'Pacific/Tahiti',
    code: 'TAHT',
    displayName: 'Pacific/Tahiti (TAHT)',
  },
  {
    value: 'Africa/Asmera',
    code: 'EAT',
    displayName: 'Africa/Asmera (EAT)',
  },
  {
    value: 'Europe/Busingen',
    code: 'CET',
    displayName: 'Europe/Busingen (CET)',
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    code: 'ART',
    displayName: 'America/Argentina/Rio_Gallegos (ART)',
  },
  {
    value: 'Africa/Malabo',
    code: 'WAT',
    displayName: 'Africa/Malabo (WAT)',
  },
  {
    value: 'Europe/Skopje',
    code: 'CET',
    displayName: 'Europe/Skopje (CET)',
  },
  {
    value: 'America/Catamarca',
    code: 'ART',
    displayName: 'America/Catamarca (ART)',
  },
  {
    value: 'America/Godthab',
    code: 'WGT',
    displayName: 'America/Godthab (WGT)',
  },
  {
    value: 'Europe/Sarajevo',
    code: 'CET',
    displayName: 'Europe/Sarajevo (CET)',
  },
  {
    value: 'Australia/ACT',
    code: 'AEDT',
    displayName: 'Australia/ACT (AEDT)',
  },
  { value: 'GB-Eire', code: 'GMT', displayName: 'GB-Eire (GMT)' },
  {
    value: 'Africa/Lagos',
    code: 'WAT',
    displayName: 'Africa/Lagos (WAT)',
  },
  {
    value: 'America/Cordoba',
    code: 'ART',
    displayName: 'America/Cordoba (ART)',
  },
  { value: 'Europe/Rome', code: 'CET', displayName: 'Europe/Rome (CET)' },
  { value: 'Asia/Dacca', code: 'BDT', displayName: 'Asia/Dacca (BDT)' },
  {
    value: 'Indian/Mauritius',
    code: 'MUT',
    displayName: 'Indian/Mauritius (MUT)',
  },
  {
    value: 'Pacific/Samoa',
    code: 'SST',
    displayName: 'Pacific/Samoa (SST)',
  },
  {
    value: 'America/Regina',
    code: 'CST',
    displayName: 'America/Regina (CST)',
  },
  {
    value: 'America/Fort_Wayne',
    code: 'EST',
    displayName: 'America/Fort_Wayne (EST)',
  },
  {
    value: 'America/Dawson_Creek',
    code: 'MST',
    displayName: 'America/Dawson_Creek (MST)',
  },
  {
    value: 'Africa/Algiers',
    code: 'CET',
    displayName: 'Africa/Algiers (CET)',
  },
  {
    value: 'Europe/Mariehamn',
    code: 'EET',
    displayName: 'Europe/Mariehamn (EET)',
  },
  {
    value: 'America/St_Johns',
    code: 'NST',
    displayName: 'America/St_Johns (NST)',
  },
  {
    value: 'America/St_Thomas',
    code: 'AST',
    displayName: 'America/St_Thomas (AST)',
  },
  {
    value: 'Europe/Zurich',
    code: 'CET',
    displayName: 'Europe/Zurich (CET)',
  },
  {
    value: 'America/Anguilla',
    code: 'AST',
    displayName: 'America/Anguilla (AST)',
  },
  { value: 'Asia/Dili', code: 'TLT', displayName: 'Asia/Dili (TLT)' },
  {
    value: 'America/Denver',
    code: 'MST',
    displayName: 'America/Denver (MST)',
  },
  {
    value: 'Africa/Bamako',
    code: 'GMT',
    displayName: 'Africa/Bamako (GMT)',
  },
  {
    value: 'Europe/Saratov',
    code: 'Europe/Saratov',
    displayName: 'Europe/Saratov (Europe/Saratov)',
  },
  { value: 'GB', code: 'GMT', displayName: 'GB (GMT)' },
  {
    value: 'Mexico/General',
    code: 'CST',
    displayName: 'Mexico/General (CST)',
  },
  {
    value: 'Pacific/Wallis',
    code: 'WFT',
    displayName: 'Pacific/Wallis (WFT)',
  },
  {
    value: 'Europe/Gibraltar',
    code: 'CET',
    displayName: 'Europe/Gibraltar (CET)',
  },
  {
    value: 'Africa/Conakry',
    code: 'GMT',
    displayName: 'Africa/Conakry (GMT)',
  },
  {
    value: 'Africa/Lubumbashi',
    code: 'CAT',
    displayName: 'Africa/Lubumbashi (CAT)',
  },
  {
    value: 'Asia/Istanbul',
    code: 'TRT',
    displayName: 'Asia/Istanbul (TRT)',
  },
  {
    value: 'America/Havana',
    code: 'CST',
    displayName: 'America/Havana (CST)',
  },
  { value: 'NZ-CHAT', code: 'CHADT', displayName: 'NZ-CHAT (CHADT)' },
  {
    value: 'Asia/Choibalsan',
    code: 'CHOT',
    displayName: 'Asia/Choibalsan (CHOT)',
  },
  {
    value: 'America/Porto_Acre',
    code: 'ACT',
    displayName: 'America/Porto_Acre (ACT)',
  },
  { value: 'Asia/Omsk', code: 'OMST', displayName: 'Asia/Omsk (OMST)' },
  {
    value: 'Europe/Vaduz',
    code: 'CET',
    displayName: 'Europe/Vaduz (CET)',
  },
  { value: 'US/Michigan', code: 'EST', displayName: 'US/Michigan (EST)' },
  { value: 'Asia/Dhaka', code: 'BDT', displayName: 'Asia/Dhaka (BDT)' },
  {
    value: 'America/Barbados',
    code: 'AST',
    displayName: 'America/Barbados (AST)',
  },
  {
    value: 'Europe/Tiraspol',
    code: 'EET',
    displayName: 'Europe/Tiraspol (EET)',
  },
  {
    value: 'Atlantic/Cape_Verde',
    code: 'CVT',
    displayName: 'Atlantic/Cape_Verde (CVT)',
  },
  {
    value: 'Asia/Yekaterinburg',
    code: 'YEKT',
    displayName: 'Asia/Yekaterinburg (YEKT)',
  },
  {
    value: 'America/Louisville',
    code: 'EST',
    displayName: 'America/Louisville (EST)',
  },
  {
    value: 'Pacific/Johnston',
    code: 'HST',
    displayName: 'Pacific/Johnston (HST)',
  },
  {
    value: 'Pacific/Chatham',
    code: 'CHADT',
    displayName: 'Pacific/Chatham (CHADT)',
  },
  {
    value: 'Europe/Ljubljana',
    code: 'CET',
    displayName: 'Europe/Ljubljana (CET)',
  },
  {
    value: 'America/Sao_Paulo',
    code: 'BRT',
    displayName: 'America/Sao_Paulo (BRT)',
  },
  {
    value: 'Asia/Jayapura',
    code: 'WIT',
    displayName: 'Asia/Jayapura (WIT)',
  },
  {
    value: 'America/Curacao',
    code: 'AST',
    displayName: 'America/Curacao (AST)',
  },
  {
    value: 'Asia/Dushanbe',
    code: 'TJT',
    displayName: 'Asia/Dushanbe (TJT)',
  },
  {
    value: 'America/Guyana',
    code: 'GYT',
    displayName: 'America/Guyana (GYT)',
  },
  {
    value: 'America/Guayaquil',
    code: 'ECT',
    displayName: 'America/Guayaquil (ECT)',
  },
  {
    value: 'America/Martinique',
    code: 'AST',
    displayName: 'America/Martinique (AST)',
  },
  { value: 'Portugal', code: 'WET', displayName: 'Portugal (WET)' },
  {
    value: 'Europe/Berlin',
    code: 'CET',
    displayName: 'Europe/Berlin (CET)',
  },
  {
    value: 'Europe/Moscow',
    code: 'MSK',
    displayName: 'Europe/Moscow (MSK)',
  },
  {
    value: 'Europe/Chisinau',
    code: 'EET',
    displayName: 'Europe/Chisinau (EET)',
  },
  {
    value: 'America/Puerto_Rico',
    code: 'AST',
    displayName: 'America/Puerto_Rico (AST)',
  },
  {
    value: 'America/Rankin_Inlet',
    code: 'CST',
    displayName: 'America/Rankin_Inlet (CST)',
  },
  {
    value: 'Pacific/Ponape',
    code: 'PONT',
    displayName: 'Pacific/Ponape (PONT)',
  },
  {
    value: 'Europe/Stockholm',
    code: 'CET',
    displayName: 'Europe/Stockholm (CET)',
  },
  {
    value: 'Europe/Budapest',
    code: 'CET',
    displayName: 'Europe/Budapest (CET)',
  },
  {
    value: 'America/Argentina/Jujuy',
    code: 'ART',
    displayName: 'America/Argentina/Jujuy (ART)',
  },
  {
    value: 'Australia/Eucla',
    code: 'ACWST',
    displayName: 'Australia/Eucla (ACWST)',
  },
  {
    value: 'Asia/Shanghai',
    code: 'CST',
    displayName: 'Asia/Shanghai (CST)',
  },
  { value: 'Universal', code: 'UTC', displayName: 'Universal (UTC)' },
  {
    value: 'Europe/Zagreb',
    code: 'CET',
    displayName: 'Europe/Zagreb (CET)',
  },
  {
    value: 'America/Port_of_Spain',
    code: 'AST',
    displayName: 'America/Port_of_Spain (AST)',
  },
  {
    value: 'Europe/Helsinki',
    code: 'EET',
    displayName: 'Europe/Helsinki (EET)',
  },
  { value: 'Asia/Beirut', code: 'EET', displayName: 'Asia/Beirut (EET)' },
  {
    value: 'Asia/Tel_Aviv',
    code: 'IST',
    displayName: 'Asia/Tel_Aviv (IST)',
  },
  {
    value: 'Pacific/Bougainville',
    code: 'BST',
    displayName: 'Pacific/Bougainville (BST)',
  },
  { value: 'US/Central', code: 'CST', displayName: 'US/Central (CST)' },
  {
    value: 'Africa/Sao_Tome',
    code: 'GMT',
    displayName: 'Africa/Sao_Tome (GMT)',
  },
  {
    value: 'Indian/Chagos',
    code: 'IOT',
    displayName: 'Indian/Chagos (IOT)',
  },
  {
    value: 'America/Cayenne',
    code: 'GFT',
    displayName: 'America/Cayenne (GFT)',
  },
  {
    value: 'Asia/Yakutsk',
    code: 'YAKT',
    displayName: 'Asia/Yakutsk (YAKT)',
  },
  {
    value: 'Pacific/Galapagos',
    code: 'GALT',
    displayName: 'Pacific/Galapagos (GALT)',
  },
  {
    value: 'Australia/North',
    code: 'ACST',
    displayName: 'Australia/North (ACST)',
  },
  {
    value: 'Europe/Paris',
    code: 'CET',
    displayName: 'Europe/Paris (CET)',
  },
  {
    value: 'Africa/Ndjamena',
    code: 'WAT',
    displayName: 'Africa/Ndjamena (WAT)',
  },
  {
    value: 'Pacific/Fiji',
    code: 'FJT',
    displayName: 'Pacific/Fiji (FJT)',
  },
  {
    value: 'America/Rainy_River',
    code: 'CST',
    displayName: 'America/Rainy_River (CST)',
  },
  {
    value: 'Indian/Maldives',
    code: 'MVT',
    displayName: 'Indian/Maldives (MVT)',
  },
  {
    value: 'Australia/Yancowinna',
    code: 'ACDT',
    displayName: 'Australia/Yancowinna (ACDT)',
  },
  {
    value: 'America/Yellowknife',
    code: 'MST',
    displayName: 'America/Yellowknife (MST)',
  },
  { value: 'Asia/Oral', code: 'ORAT', displayName: 'Asia/Oral (ORAT)' },
  {
    value: 'Pacific/Enderbury',
    code: 'PHOT',
    displayName: 'Pacific/Enderbury (PHOT)',
  },
  {
    value: 'America/Juneau',
    code: 'AKST',
    displayName: 'America/Juneau (AKST)',
  },
  {
    value: 'Australia/Victoria',
    code: 'AEDT',
    displayName: 'Australia/Victoria (AEDT)',
  },
  {
    value: 'America/Indiana/Vevay',
    code: 'EST',
    displayName: 'America/Indiana/Vevay (EST)',
  },
  {
    value: 'Asia/Tashkent',
    code: 'UZT',
    displayName: 'Asia/Tashkent (UZT)',
  },
  {
    value: 'Asia/Jakarta',
    code: 'WIB',
    displayName: 'Asia/Jakarta (WIB)',
  },
  {
    value: 'Africa/Ceuta',
    code: 'CET',
    displayName: 'Africa/Ceuta (CET)',
  },
  {
    value: 'Asia/Barnaul',
    code: 'Asia/Barnaul',
    displayName: 'Asia/Barnaul (Asia/Barnaul)',
  },
  {
    value: 'America/Recife',
    code: 'BRT',
    displayName: 'America/Recife (BRT)',
  },
  {
    value: 'America/Buenos_Aires',
    code: 'ART',
    displayName: 'America/Buenos_Aires (ART)',
  },
  {
    value: 'America/Noronha',
    code: 'FNT',
    displayName: 'America/Noronha (FNT)',
  },
  {
    value: 'America/Swift_Current',
    code: 'CST',
    displayName: 'America/Swift_Current (CST)',
  },
  {
    value: 'Australia/Adelaide',
    code: 'ACDT',
    displayName: 'Australia/Adelaide (ACDT)',
  },
  {
    value: 'America/Metlakatla',
    code: 'AKST',
    displayName: 'America/Metlakatla (AKST)',
  },
  {
    value: 'Africa/Djibouti',
    code: 'EAT',
    displayName: 'Africa/Djibouti (EAT)',
  },
  {
    value: 'America/Paramaribo',
    code: 'SRT',
    displayName: 'America/Paramaribo (SRT)',
  },
  {
    value: 'Europe/Simferopol',
    code: 'MSK',
    displayName: 'Europe/Simferopol (MSK)',
  },
  {
    value: 'Europe/Sofia',
    code: 'EET',
    displayName: 'Europe/Sofia (EET)',
  },
  {
    value: 'Africa/Nouakchott',
    code: 'GMT',
    displayName: 'Africa/Nouakchott (GMT)',
  },
  {
    value: 'Europe/Prague',
    code: 'CET',
    displayName: 'Europe/Prague (CET)',
  },
  {
    value: 'America/Indiana/Vincennes',
    code: 'EST',
    displayName: 'America/Indiana/Vincennes (EST)',
  },
  {
    value: 'Antarctica/Mawson',
    code: 'MAWT',
    displayName: 'Antarctica/Mawson (MAWT)',
  },
  {
    value: 'America/Kralendijk',
    code: 'AST',
    displayName: 'America/Kralendijk (AST)',
  },
  {
    value: 'Antarctica/Troll',
    code: 'UTC',
    displayName: 'Antarctica/Troll (UTC)',
  },
  {
    value: 'Europe/Samara',
    code: 'SAMT',
    displayName: 'Europe/Samara (SAMT)',
  },
  {
    value: 'Indian/Christmas',
    code: 'CXT',
    displayName: 'Indian/Christmas (CXT)',
  },
  {
    value: 'America/Antigua',
    code: 'AST',
    displayName: 'America/Antigua (AST)',
  },
  {
    value: 'Pacific/Gambier',
    code: 'GAMT',
    displayName: 'Pacific/Gambier (GAMT)',
  },
  {
    value: 'America/Indianapolis',
    code: 'EST',
    displayName: 'America/Indianapolis (EST)',
  },
  {
    value: 'America/Inuvik',
    code: 'MST',
    displayName: 'America/Inuvik (MST)',
  },
  {
    value: 'America/Iqaluit',
    code: 'EST',
    displayName: 'America/Iqaluit (EST)',
  },
  {
    value: 'Pacific/Funafuti',
    code: 'TVT',
    displayName: 'Pacific/Funafuti (TVT)',
  },
  { value: 'UTC', code: 'UTC', displayName: 'UTC (UTC)' },
  {
    value: 'Antarctica/Macquarie',
    code: 'AEDT',
    displayName: 'Antarctica/Macquarie (AEDT)',
  },
  {
    value: 'Canada/Pacific',
    code: 'PST',
    displayName: 'Canada/Pacific (PST)',
  },
  {
    value: 'America/Moncton',
    code: 'AST',
    displayName: 'America/Moncton (AST)',
  },
  {
    value: 'Africa/Gaborone',
    code: 'CAT',
    displayName: 'Africa/Gaborone (CAT)',
  },
  {
    value: 'Pacific/Chuuk',
    code: 'CHUT',
    displayName: 'Pacific/Chuuk (CHUT)',
  },
  {
    value: 'Asia/Pyongyang',
    code: 'KST',
    displayName: 'Asia/Pyongyang (KST)',
  },
  {
    value: 'America/St_Vincent',
    code: 'AST',
    displayName: 'America/St_Vincent (AST)',
  },
  { value: 'Asia/Gaza', code: 'EET', displayName: 'Asia/Gaza (EET)' },
  {
    value: 'PST8PDT',
    canonicalValue: 'America/Los_Angeles',
    code: 'PST',
    displayName: 'PST8PDT (PST)',
  },
  {
    value: 'Atlantic/Faeroe',
    code: 'WET',
    displayName: 'Atlantic/Faeroe (WET)',
  },
  {
    value: 'Asia/Qyzylorda',
    code: 'QYZT',
    displayName: 'Asia/Qyzylorda (QYZT)',
  },
  {
    value: 'Canada/Newfoundland',
    code: 'NST',
    displayName: 'Canada/Newfoundland (NST)',
  },
  {
    value: 'America/Kentucky/Louisville',
    code: 'EST',
    displayName: 'America/Kentucky/Louisville (EST)',
  },
  {
    value: 'America/Yakutat',
    code: 'AKST',
    displayName: 'America/Yakutat (AKST)',
  },
  {
    value: 'Asia/Ho_Chi_Minh',
    code: 'ICT',
    displayName: 'Asia/Ho_Chi_Minh (ICT)',
  },
  {
    value: 'Antarctica/Casey',
    code: 'AWST',
    displayName: 'Antarctica/Casey (AWST)',
  },
  {
    value: 'Europe/Copenhagen',
    code: 'CET',
    displayName: 'Europe/Copenhagen (CET)',
  },
  {
    value: 'Africa/Asmara',
    code: 'EAT',
    displayName: 'Africa/Asmara (EAT)',
  },
  {
    value: 'Atlantic/Azores',
    code: 'AZOT',
    displayName: 'Atlantic/Azores (AZOT)',
  },
  {
    value: 'Europe/Vienna',
    code: 'CET',
    displayName: 'Europe/Vienna (CET)',
  },
  { value: 'ROK', code: 'KST', displayName: 'ROK (KST)' },
  {
    value: 'Pacific/Pitcairn',
    code: 'PST',
    displayName: 'Pacific/Pitcairn (PST)',
  },
  {
    value: 'America/Mazatlan',
    code: 'MST',
    displayName: 'America/Mazatlan (MST)',
  },
  {
    value: 'Australia/Queensland',
    code: 'AEST',
    displayName: 'Australia/Queensland (AEST)',
  },
  {
    value: 'Pacific/Nauru',
    code: 'NRT',
    displayName: 'Pacific/Nauru (NRT)',
  },
  {
    value: 'Europe/Tirane',
    code: 'CET',
    displayName: 'Europe/Tirane (CET)',
  },
  {
    value: 'Asia/Kolkata',
    code: 'IST',
    displayName: 'Asia/Kolkata (IST)',
  },
  {
    value: 'Australia/Canberra',
    code: 'AEDT',
    displayName: 'Australia/Canberra (AEDT)',
  },
  { value: 'MET', code: 'MET', displayName: 'MET (MET)' },
  {
    value: 'Australia/Broken_Hill',
    code: 'ACDT',
    displayName: 'Australia/Broken_Hill (ACDT)',
  },
  { value: 'Europe/Riga', code: 'EET', displayName: 'Europe/Riga (EET)' },
  {
    value: 'America/Dominica',
    code: 'AST',
    displayName: 'America/Dominica (AST)',
  },
  {
    value: 'Africa/Abidjan',
    code: 'GMT',
    displayName: 'Africa/Abidjan (GMT)',
  },
  {
    value: 'America/Mendoza',
    code: 'ART',
    displayName: 'America/Mendoza (ART)',
  },
  {
    value: 'America/Santarem',
    code: 'BRT',
    displayName: 'America/Santarem (BRT)',
  },
  { value: 'Kwajalein', code: 'MHT', displayName: 'Kwajalein (MHT)' },
  {
    value: 'America/Asuncion',
    code: 'PYST',
    displayName: 'America/Asuncion (PYST)',
  },
  {
    value: 'Asia/Ulan_Bator',
    code: 'ULAT',
    displayName: 'Asia/Ulan_Bator (ULAT)',
  },
  { value: 'NZ', code: 'NZDT', displayName: 'NZ (NZDT)' },
  {
    value: 'America/Boise',
    code: 'MST',
    displayName: 'America/Boise (MST)',
  },
  {
    value: 'Australia/Currie',
    code: 'AEDT',
    displayName: 'Australia/Currie (AEDT)',
  },
  {
    value: 'EST5EDT',
    code: 'EST',
    displayName: 'EST5EDT (EST)',
    canonicalValue: 'America/New_York',
  },
  {
    value: 'Pacific/Guam',
    code: 'ChST',
    displayName: 'Pacific/Guam (ChST)',
  },
  {
    value: 'Pacific/Wake',
    code: 'WAKT',
    displayName: 'Pacific/Wake (WAKT)',
  },
  {
    value: 'Atlantic/Bermuda',
    code: 'AST',
    displayName: 'Atlantic/Bermuda (AST)',
  },
  {
    value: 'America/Costa_Rica',
    code: 'CST',
    displayName: 'America/Costa_Rica (CST)',
  },
  {
    value: 'America/Dawson',
    code: 'MST',
    displayName: 'America/Dawson (MST)',
  },
  {
    value: 'Asia/Chongqing',
    code: 'CST',
    displayName: 'Asia/Chongqing (CST)',
  },
  { value: 'Eire', code: 'GMT', displayName: 'Eire (GMT)' },
  {
    value: 'Europe/Amsterdam',
    code: 'CET',
    displayName: 'Europe/Amsterdam (CET)',
  },
  {
    value: 'America/Indiana/Knox',
    code: 'CST',
    displayName: 'America/Indiana/Knox (CST)',
  },
  {
    value: 'America/North_Dakota/Beulah',
    code: 'CST',
    displayName: 'America/North_Dakota/Beulah (CST)',
  },
  {
    value: 'Africa/Accra',
    code: 'GMT',
    displayName: 'Africa/Accra (GMT)',
  },
  {
    value: 'Atlantic/Faroe',
    code: 'WET',
    displayName: 'Atlantic/Faroe (WET)',
  },
  {
    value: 'Mexico/BajaNorte',
    code: 'PST',
    displayName: 'Mexico/BajaNorte (PST)',
  },
  {
    value: 'America/Maceio',
    code: 'BRT',
    displayName: 'America/Maceio (BRT)',
  },
  {
    value: 'Pacific/Apia',
    code: 'WSST',
    displayName: 'Pacific/Apia (WSST)',
  },
  {
    value: 'GMT0',
    code: 'GMT',
    displayName: 'GMT0 (GMT)',
    canonicalValue: 'Etc/GMT',
  },
  {
    value: 'America/Atka',
    code: 'HST',
    displayName: 'America/Atka (HST)',
  },
  {
    value: 'Pacific/Niue',
    code: 'NUT',
    displayName: 'Pacific/Niue (NUT)',
  },
  {
    value: 'Australia/Lord_Howe',
    code: 'LHDT',
    displayName: 'Australia/Lord_Howe (LHDT)',
  },
  {
    value: 'Europe/Dublin',
    code: 'GMT',
    displayName: 'Europe/Dublin (GMT)',
  },
  {
    value: 'Pacific/Truk',
    code: 'CHUT',
    displayName: 'Pacific/Truk (CHUT)',
  },
  {
    value: 'MST7MDT',
    code: 'MST',
    displayName: 'MST7MDT (MST)',
    canonicalValue: 'America/Denver',
  },
  {
    value: 'America/Monterrey',
    code: 'CST',
    displayName: 'America/Monterrey (CST)',
  },
  {
    value: 'America/Nassau',
    code: 'EST',
    displayName: 'America/Nassau (EST)',
  },
  {
    value: 'America/Jamaica',
    code: 'EST',
    displayName: 'America/Jamaica (EST)',
  },
  {
    value: 'Asia/Bishkek',
    code: 'KGT',
    displayName: 'Asia/Bishkek (KGT)',
  },
  {
    value: 'America/Atikokan',
    code: 'EST',
    displayName: 'America/Atikokan (EST)',
  },
  {
    value: 'Atlantic/Stanley',
    code: 'FKT',
    displayName: 'Atlantic/Stanley (FKT)',
  },
  {
    value: 'Australia/NSW',
    code: 'AEDT',
    displayName: 'Australia/NSW (AEDT)',
  },
  { value: 'US/Hawaii', code: 'HST', displayName: 'US/Hawaii (HST)' },
  { value: 'Indian/Mahe', code: 'SCT', displayName: 'Indian/Mahe (SCT)' },
  {
    value: 'Asia/Aqtobe',
    code: 'AQTT',
    displayName: 'Asia/Aqtobe (AQTT)',
  },
  {
    value: 'America/Sitka',
    code: 'AKST',
    displayName: 'America/Sitka (AKST)',
  },
  {
    value: 'Asia/Vladivostok',
    code: 'VLAT',
    displayName: 'Asia/Vladivostok (VLAT)',
  },
  {
    value: 'Africa/Libreville',
    code: 'WAT',
    displayName: 'Africa/Libreville (WAT)',
  },
  {
    value: 'Africa/Maputo',
    code: 'CAT',
    displayName: 'Africa/Maputo (CAT)',
  },
  { value: 'Zulu', code: 'UTC', displayName: 'Zulu (UTC)' },
  {
    value: 'America/Kentucky/Monticello',
    code: 'EST',
    displayName: 'America/Kentucky/Monticello (EST)',
  },
  {
    value: 'Africa/El_Aaiun',
    code: 'WEST',
    displayName: 'Africa/El_Aaiun (WEST)',
  },
  {
    value: 'Africa/Ouagadougou',
    code: 'GMT',
    displayName: 'Africa/Ouagadougou (GMT)',
  },
  {
    value: 'America/Coral_Harbour',
    code: 'EST',
    displayName: 'America/Coral_Harbour (EST)',
  },
  {
    value: 'Pacific/Marquesas',
    code: 'MART',
    displayName: 'Pacific/Marquesas (MART)',
  },
  { value: 'Brazil/West', code: 'AMT', displayName: 'Brazil/West (AMT)' },
  {
    value: 'America/Aruba',
    code: 'AST',
    displayName: 'America/Aruba (AST)',
  },
  {
    value: 'America/North_Dakota/Center',
    code: 'CST',
    displayName: 'America/North_Dakota/Center (CST)',
  },
  {
    value: 'America/Cayman',
    code: 'EST',
    displayName: 'America/Cayman (EST)',
  },
  {
    value: 'Asia/Ulaanbaatar',
    code: 'ULAT',
    displayName: 'Asia/Ulaanbaatar (ULAT)',
  },
  {
    value: 'Asia/Baghdad',
    code: 'AST',
    displayName: 'Asia/Baghdad (AST)',
  },
  {
    value: 'Europe/San_Marino',
    code: 'CET',
    displayName: 'Europe/San_Marino (CET)',
  },
  {
    value: 'America/Indiana/Tell_City',
    code: 'CST',
    displayName: 'America/Indiana/Tell_City (CST)',
  },
  {
    value: 'America/Tijuana',
    code: 'PST',
    displayName: 'America/Tijuana (PST)',
  },
  {
    value: 'Pacific/Saipan',
    code: 'ChST',
    displayName: 'Pacific/Saipan (ChST)',
  },
  {
    value: 'Africa/Douala',
    code: 'WAT',
    displayName: 'Africa/Douala (WAT)',
  },
  {
    value: 'America/Chihuahua',
    code: 'CST',
    displayName: 'America/Chihuahua (CST)',
  },
  {
    value: 'America/Ojinaga',
    code: 'CST',
    displayName: 'America/Ojinaga (CST)',
  },
  { value: 'Asia/Hovd', code: 'HOVT', displayName: 'Asia/Hovd (HOVT)' },
  {
    value: 'America/Anchorage',
    code: 'AKST',
    displayName: 'America/Anchorage (AKST)',
  },
  {
    value: 'Chile/EasterIsland',
    code: 'EASST',
    displayName: 'Chile/EasterIsland (EASST)',
  },
  {
    value: 'America/Halifax',
    code: 'AST',
    displayName: 'America/Halifax (AST)',
  },
  {
    value: 'Antarctica/Rothera',
    code: 'ROTT',
    displayName: 'Antarctica/Rothera (ROTT)',
  },
  {
    value: 'America/Indiana/Indianapolis',
    code: 'EST',
    displayName: 'America/Indiana/Indianapolis (EST)',
  },
  { value: 'US/Mountain', code: 'MST', displayName: 'US/Mountain (MST)' },
  {
    value: 'Asia/Damascus',
    code: 'EET',
    displayName: 'Asia/Damascus (EET)',
  },
  {
    value: 'America/Argentina/San_Luis',
    code: 'ART',
    displayName: 'America/Argentina/San_Luis (ART)',
  },
  {
    value: 'America/Santiago',
    code: 'CLST',
    displayName: 'America/Santiago (CLST)',
  },
  { value: 'Asia/Baku', code: 'AZT', displayName: 'Asia/Baku (AZT)' },
  {
    value: 'America/Argentina/Ushuaia',
    code: 'ART',
    displayName: 'America/Argentina/Ushuaia (ART)',
  },
  {
    value: 'Atlantic/Reykjavik',
    code: 'GMT',
    displayName: 'Atlantic/Reykjavik (GMT)',
  },
  {
    value: 'Africa/Brazzaville',
    code: 'WAT',
    displayName: 'Africa/Brazzaville (WAT)',
  },
  {
    value: 'Africa/Porto-Novo',
    code: 'WAT',
    displayName: 'Africa/Porto-Novo (WAT)',
  },
  {
    value: 'America/La_Paz',
    code: 'BOT',
    displayName: 'America/La_Paz (BOT)',
  },
  {
    value: 'Antarctica/DumontDUrville',
    code: 'DDUT',
    displayName: 'Antarctica/DumontDUrville (DDUT)',
  },
  { value: 'Asia/Taipei', code: 'CST', displayName: 'Asia/Taipei (CST)' },
  {
    value: 'Antarctica/South_Pole',
    code: 'NZDT',
    displayName: 'Antarctica/South_Pole (NZDT)',
  },
  { value: 'Asia/Manila', code: 'PST', displayName: 'Asia/Manila (PST)' },
  {
    value: 'Asia/Bangkok',
    code: 'ICT',
    displayName: 'Asia/Bangkok (ICT)',
  },
  {
    value: 'Africa/Dar_es_Salaam',
    code: 'EAT',
    displayName: 'Africa/Dar_es_Salaam (EAT)',
  },
  { value: 'Poland', code: 'CET', displayName: 'Poland (CET)' },
  {
    value: 'Atlantic/Madeira',
    code: 'WET',
    displayName: 'Atlantic/Madeira (WET)',
  },
  {
    value: 'Antarctica/Palmer',
    code: 'CLT',
    displayName: 'Antarctica/Palmer (CLT)',
  },
  {
    value: 'America/Thunder_Bay',
    code: 'EST',
    displayName: 'America/Thunder_Bay (EST)',
  },
  {
    value: 'Africa/Addis_Ababa',
    code: 'EAT',
    displayName: 'Africa/Addis_Ababa (EAT)',
  },
  { value: 'Asia/Yangon', code: 'MMT', displayName: 'Asia/Yangon (MMT)' },
  {
    value: 'Europe/Uzhgorod',
    code: 'EET',
    displayName: 'Europe/Uzhgorod (EET)',
  },
  {
    value: 'Brazil/DeNoronha',
    code: 'FNT',
    displayName: 'Brazil/DeNoronha (FNT)',
  },
  {
    value: 'Asia/Ashkhabad',
    code: 'TMT',
    displayName: 'Asia/Ashkhabad (TMT)',
  },
  {
    value: 'America/Indiana/Marengo',
    code: 'EST',
    displayName: 'America/Indiana/Marengo (EST)',
  },
  {
    value: 'America/Creston',
    code: 'MST',
    displayName: 'America/Creston (MST)',
  },
  {
    value: 'America/Punta_Arenas',
    code: 'America/Punta_Arenas',
    displayName: 'America/Punta_Arenas (America/Punta_Arenas)',
  },
  {
    value: 'America/Mexico_City',
    code: 'CST',
    displayName: 'America/Mexico_City (CST)',
  },
  {
    value: 'Antarctica/Vostok',
    code: 'VOST',
    displayName: 'Antarctica/Vostok (VOST)',
  },
  {
    value: 'Asia/Jerusalem',
    code: 'IST',
    displayName: 'Asia/Jerusalem (IST)',
  },
  {
    value: 'Europe/Andorra',
    code: 'CET',
    displayName: 'Europe/Andorra (CET)',
  },
  { value: 'US/Samoa', code: 'SST', displayName: 'US/Samoa (SST)' },
  { value: 'PRC', code: 'CST', displayName: 'PRC (CST)' },
  {
    value: 'Asia/Vientiane',
    code: 'ICT',
    displayName: 'Asia/Vientiane (ICT)',
  },
  {
    value: 'Pacific/Kiritimati',
    code: 'LINT',
    displayName: 'Pacific/Kiritimati (LINT)',
  },
  {
    value: 'America/Matamoros',
    code: 'CST',
    displayName: 'America/Matamoros (CST)',
  },
  {
    value: 'America/Blanc-Sablon',
    code: 'AST',
    displayName: 'America/Blanc-Sablon (AST)',
  },
  { value: 'Asia/Riyadh', code: 'AST', displayName: 'Asia/Riyadh (AST)' },
  { value: 'Iceland', code: 'GMT', displayName: 'Iceland (GMT)' },
  {
    value: 'Pacific/Pohnpei',
    code: 'PONT',
    displayName: 'Pacific/Pohnpei (PONT)',
  },
  {
    value: 'Asia/Ujung_Pandang',
    code: 'WITA',
    displayName: 'Asia/Ujung_Pandang (WITA)',
  },
  {
    value: 'Atlantic/South_Georgia',
    code: 'GST',
    displayName: 'Atlantic/South_Georgia (GST)',
  },
  {
    value: 'Europe/Lisbon',
    code: 'WET',
    displayName: 'Europe/Lisbon (WET)',
  },
  { value: 'Asia/Harbin', code: 'CST', displayName: 'Asia/Harbin (CST)' },
  { value: 'Europe/Oslo', code: 'CET', displayName: 'Europe/Oslo (CET)' },
  {
    value: 'Asia/Novokuznetsk',
    code: 'KRAT',
    displayName: 'Asia/Novokuznetsk (KRAT)',
  },
  {
    value: 'CST6CDT',
    code: 'CST',
    displayName: 'CST6CDT (CST)',
    canonicalValue: 'America/Chicago',
  },
  {
    value: 'Atlantic/Canary',
    code: 'WET',
    displayName: 'Atlantic/Canary (WET)',
  },
  {
    value: 'America/Knox_IN',
    code: 'CST',
    displayName: 'America/Knox_IN (CST)',
  },
  { value: 'Asia/Kuwait', code: 'AST', displayName: 'Asia/Kuwait (AST)' },
  {
    value: 'Pacific/Efate',
    code: 'VUT',
    displayName: 'Pacific/Efate (VUT)',
  },
  { value: 'Africa/Lome', code: 'GMT', displayName: 'Africa/Lome (GMT)' },
  {
    value: 'America/Bogota',
    code: 'COT',
    displayName: 'America/Bogota (COT)',
  },
  {
    value: 'America/Menominee',
    code: 'CST',
    displayName: 'America/Menominee (CST)',
  },
  {
    value: 'America/Adak',
    code: 'HST',
    displayName: 'America/Adak (HST)',
  },
  {
    value: 'Pacific/Norfolk',
    code: 'NFST',
    displayName: 'Pacific/Norfolk (NFST)',
  },
  {
    value: 'Europe/Kirov',
    code: 'Europe/Kirov',
    displayName: 'Europe/Kirov (Europe/Kirov)',
  },
  {
    value: 'America/Resolute',
    code: 'CST',
    displayName: 'America/Resolute (CST)',
  },
  {
    value: 'Pacific/Tarawa',
    code: 'GILT',
    displayName: 'Pacific/Tarawa (GILT)',
  },
  {
    value: 'Africa/Kampala',
    code: 'EAT',
    displayName: 'Africa/Kampala (EAT)',
  },
  {
    value: 'Asia/Krasnoyarsk',
    code: 'KRAT',
    displayName: 'Asia/Krasnoyarsk (KRAT)',
  },
  { value: 'Greenwich', code: 'GMT', displayName: 'Greenwich (GMT)' },
  {
    value: 'America/Edmonton',
    code: 'MST',
    displayName: 'America/Edmonton (MST)',
  },
  {
    value: 'Europe/Podgorica',
    code: 'CET',
    displayName: 'Europe/Podgorica (CET)',
  },
  {
    value: 'Australia/South',
    code: 'ACDT',
    displayName: 'Australia/South (ACDT)',
  },
  {
    value: 'Canada/Central',
    code: 'CST',
    displayName: 'Canada/Central (CST)',
  },
  {
    value: 'Africa/Bujumbura',
    code: 'CAT',
    displayName: 'Africa/Bujumbura (CAT)',
  },
  {
    value: 'America/Santo_Domingo',
    code: 'AST',
    displayName: 'America/Santo_Domingo (AST)',
  },
  { value: 'US/Eastern', code: 'EST', displayName: 'US/Eastern (EST)' },
  {
    value: 'Europe/Minsk',
    code: 'MSK',
    displayName: 'Europe/Minsk (MSK)',
  },
  {
    value: 'Pacific/Auckland',
    code: 'NZDT',
    displayName: 'Pacific/Auckland (NZDT)',
  },
  {
    value: 'Africa/Casablanca',
    code: 'WEST', //Applies during Ramadan.
    displayName: 'Africa/Casablanca (WEST)',
  },
  {
    value: 'America/Glace_Bay',
    code: 'AST',
    displayName: 'America/Glace_Bay (AST)',
  },
  {
    value: 'Canada/Eastern',
    code: 'EST',
    displayName: 'Canada/Eastern (EST)',
  },
  { value: 'Asia/Qatar', code: 'AST', displayName: 'Asia/Qatar (AST)' },
  { value: 'Europe/Kiev', code: 'EET', displayName: 'Europe/Kiev (EET)' },
  { value: 'Singapore', code: 'SGT', displayName: 'Singapore (SGT)' },
  {
    value: 'Asia/Magadan',
    code: 'MAGT',
    displayName: 'Asia/Magadan (MAGT)',
  },
  {
    value: 'America/Port-au-Prince',
    code: 'EST',
    displayName: 'America/Port-au-Prince (EST)',
  },
  {
    value: 'Europe/Belfast',
    code: 'GMT',
    displayName: 'Europe/Belfast (GMT)',
  },
  {
    value: 'America/St_Barthelemy',
    code: 'AST',
    displayName: 'America/St_Barthelemy (AST)',
  },
  {
    value: 'Asia/Ashgabat',
    code: 'TMT',
    displayName: 'Asia/Ashgabat (TMT)',
  },
  {
    value: 'Africa/Luanda',
    code: 'WAT',
    displayName: 'Africa/Luanda (WAT)',
  },
  {
    value: 'America/Nipigon',
    code: 'EST',
    displayName: 'America/Nipigon (EST)',
  },
  {
    value: 'Atlantic/Jan_Mayen',
    code: 'CET',
    displayName: 'Atlantic/Jan_Mayen (CET)',
  },
  { value: 'Brazil/Acre', code: 'ACT', displayName: 'Brazil/Acre (ACT)' },
  { value: 'Asia/Muscat', code: 'GST', displayName: 'Asia/Muscat (GST)' },
  {
    value: 'Asia/Bahrain',
    code: 'AST',
    displayName: 'Asia/Bahrain (AST)',
  },
  {
    value: 'Europe/Vilnius',
    code: 'EET',
    displayName: 'Europe/Vilnius (EET)',
  },
  {
    value: 'America/Fortaleza',
    code: 'BRT',
    displayName: 'America/Fortaleza (BRT)',
  },
  {
    value: 'US/East-Indiana',
    code: 'EST',
    displayName: 'US/East-Indiana (EST)',
  },
  {
    value: 'America/Hermosillo',
    code: 'MST',
    displayName: 'America/Hermosillo (MST)',
  },
  {
    value: 'America/Cancun',
    code: 'EST',
    displayName: 'America/Cancun (EST)',
  },
  {
    value: 'Africa/Maseru',
    code: 'SAST',
    displayName: 'Africa/Maseru (SAST)',
  },
  {
    value: 'Pacific/Kosrae',
    code: 'KOST',
    displayName: 'Pacific/Kosrae (KOST)',
  },
  {
    value: 'Africa/Kinshasa',
    code: 'WAT',
    displayName: 'Africa/Kinshasa (WAT)',
  },
  {
    value: 'Asia/Kathmandu',
    code: 'NPT',
    displayName: 'Asia/Kathmandu (NPT)',
  },
  { value: 'Asia/Seoul', code: 'KST', displayName: 'Asia/Seoul (KST)' },
  {
    value: 'Australia/Sydney',
    code: 'AEDT',
    displayName: 'Australia/Sydney (AEDT)',
  },
  {
    value: 'America/Lima',
    code: 'PET',
    displayName: 'America/Lima (PET)',
  },
  {
    value: 'Australia/LHI',
    code: 'LHDT',
    displayName: 'Australia/LHI (LHDT)',
  },
  {
    value: 'America/St_Lucia',
    code: 'AST',
    displayName: 'America/St_Lucia (AST)',
  },
  {
    value: 'Europe/Madrid',
    code: 'CET',
    displayName: 'Europe/Madrid (CET)',
  },
  {
    value: 'America/Bahia_Banderas',
    code: 'CST',
    displayName: 'America/Bahia_Banderas (CST)',
  },
  {
    value: 'America/Montserrat',
    code: 'AST',
    displayName: 'America/Montserrat (AST)',
  },
  { value: 'Asia/Brunei', code: 'BNT', displayName: 'Asia/Brunei (BNT)' },
  {
    value: 'America/Santa_Isabel',
    code: 'PST',
    displayName: 'America/Santa_Isabel (PST)',
  },
  {
    value: 'Canada/Mountain',
    code: 'MST',
    displayName: 'Canada/Mountain (MST)',
  },
  {
    value: 'America/Cambridge_Bay',
    code: 'MST',
    displayName: 'America/Cambridge_Bay (MST)',
  },
  {
    value: 'Asia/Colombo',
    code: 'IST',
    displayName: 'Asia/Colombo (IST)',
  },
  {
    value: 'Australia/West',
    code: 'AWST',
    displayName: 'Australia/West (AWST)',
  },
  {
    value: 'Indian/Antananarivo',
    code: 'EAT',
    displayName: 'Indian/Antananarivo (EAT)',
  },
  {
    value: 'Australia/Brisbane',
    code: 'AEST',
    displayName: 'Australia/Brisbane (AEST)',
  },
  {
    value: 'Indian/Mayotte',
    code: 'EAT',
    displayName: 'Indian/Mayotte (EAT)',
  },
  {
    value: 'US/Indiana-Starke',
    code: 'CST',
    displayName: 'US/Indiana-Starke (CST)',
  },
  { value: 'Asia/Urumqi', code: 'XJT', displayName: 'Asia/Urumqi (XJT)' },
  { value: 'US/Aleutian', code: 'HST', displayName: 'US/Aleutian (HST)' },
  {
    value: 'Europe/Volgograd',
    code: 'MSK',
    displayName: 'Europe/Volgograd (MSK)',
  },
  {
    value: 'America/Lower_Princes',
    code: 'AST',
    displayName: 'America/Lower_Princes (AST)',
  },
  {
    value: 'America/Vancouver',
    code: 'PST',
    displayName: 'America/Vancouver (PST)',
  },
  {
    value: 'Africa/Blantyre',
    code: 'CAT',
    displayName: 'Africa/Blantyre (CAT)',
  },
  {
    value: 'America/Rio_Branco',
    code: 'ACT',
    displayName: 'America/Rio_Branco (ACT)',
  },
  {
    value: 'America/Danmarkshavn',
    code: 'GMT',
    displayName: 'America/Danmarkshavn (GMT)',
  },
  {
    value: 'America/Detroit',
    code: 'EST',
    displayName: 'America/Detroit (EST)',
  },
  {
    value: 'America/Thule',
    code: 'AST',
    displayName: 'America/Thule (AST)',
  },
  {
    value: 'Africa/Lusaka',
    code: 'CAT',
    displayName: 'Africa/Lusaka (CAT)',
  },
  {
    value: 'Asia/Hong_Kong',
    code: 'HKT',
    displayName: 'Asia/Hong_Kong (HKT)',
  },
  { value: 'Iran', code: 'IRST', displayName: 'Iran (IRST)' },
  {
    value: 'America/Argentina/La_Rioja',
    code: 'ART',
    displayName: 'America/Argentina/La_Rioja (ART)',
  },
  {
    value: 'Africa/Dakar',
    code: 'GMT',
    displayName: 'Africa/Dakar (GMT)',
  },
  {
    value: 'America/Tortola',
    code: 'AST',
    displayName: 'America/Tortola (AST)',
  },
  {
    value: 'America/Porto_Velho',
    code: 'AMT',
    displayName: 'America/Porto_Velho (AMT)',
  },
  {
    value: 'Asia/Sakhalin',
    code: 'SAKT',
    displayName: 'Asia/Sakhalin (SAKT)',
  },
  {
    value: 'America/Scoresbysund',
    code: 'EGT',
    displayName: 'America/Scoresbysund (EGT)',
  },
  {
    value: 'Asia/Kamchatka',
    code: 'PETT',
    displayName: 'Asia/Kamchatka (PETT)',
  },
  { value: 'Asia/Thimbu', code: 'BTT', displayName: 'Asia/Thimbu (BTT)' },
  {
    value: 'Africa/Harare',
    code: 'CAT',
    displayName: 'Africa/Harare (CAT)',
  },
  { value: 'Navajo', code: 'MST', displayName: 'Navajo (MST)' },
  {
    value: 'America/Nome',
    code: 'AKST',
    displayName: 'America/Nome (AKST)',
  },
  {
    value: 'Europe/Tallinn',
    code: 'EET',
    displayName: 'Europe/Tallinn (EET)',
  },
  { value: 'Turkey', code: 'TRT', displayName: 'Turkey (TRT)' },
  {
    value: 'Africa/Khartoum',
    code: 'CAT',
    displayName: 'Africa/Khartoum (CAT)',
  },
  {
    value: 'Africa/Johannesburg',
    code: 'SAST',
    displayName: 'Africa/Johannesburg (SAST)',
  },
  {
    value: 'Africa/Bangui',
    code: 'WAT',
    displayName: 'Africa/Bangui (WAT)',
  },
  {
    value: 'Europe/Belgrade',
    code: 'CET',
    displayName: 'Europe/Belgrade (CET)',
  },
  { value: 'Jamaica', code: 'EST', displayName: 'Jamaica (EST)' },
  {
    value: 'Africa/Bissau',
    code: 'GMT',
    displayName: 'Africa/Bissau (GMT)',
  },
  {
    value: 'Asia/Tehran',
    code: 'IRST',
    displayName: 'Asia/Tehran (IRST)',
  },
  { value: 'WET', code: 'WET', displayName: 'WET (WET)' },
  {
    value: 'Europe/Astrakhan',
    code: 'Europe/Astrakhan',
    displayName: 'Europe/Astrakhan (Europe/Astrakhan)',
  },
  { value: 'Africa/Juba', code: 'CAT', displayName: 'Africa/Juba (CAT)' },
  {
    value: 'America/Campo_Grande',
    code: 'AMT',
    displayName: 'America/Campo_Grande (AMT)',
  },
  {
    value: 'America/Belem',
    code: 'BRT',
    displayName: 'America/Belem (BRT)',
  },
  { value: 'Asia/Saigon', code: 'ICT', displayName: 'Asia/Saigon (ICT)' },
  {
    value: 'America/Ensenada',
    code: 'PST',
    displayName: 'America/Ensenada (PST)',
  },
  {
    value: 'Pacific/Midway',
    code: 'SST',
    displayName: 'Pacific/Midway (SST)',
  },
  {
    value: 'America/Jujuy',
    code: 'ART',
    displayName: 'America/Jujuy (ART)',
  },
  {
    value: 'Africa/Timbuktu',
    code: 'GMT',
    displayName: 'Africa/Timbuktu (GMT)',
  },
  {
    value: 'America/Bahia',
    code: 'BRT',
    displayName: 'America/Bahia (BRT)',
  },
  {
    value: 'America/Goose_Bay',
    code: 'AST',
    displayName: 'America/Goose_Bay (AST)',
  },
  {
    value: 'America/Virgin',
    code: 'AST',
    displayName: 'America/Virgin (AST)',
  },
  {
    value: 'America/Pangnirtung',
    code: 'EST',
    displayName: 'America/Pangnirtung (EST)',
  },
  {
    value: 'Asia/Katmandu',
    code: 'NPT',
    displayName: 'Asia/Katmandu (NPT)',
  },
  {
    value: 'America/Phoenix',
    code: 'MST',
    displayName: 'America/Phoenix (MST)',
  },
  {
    value: 'Africa/Niamey',
    code: 'WAT',
    displayName: 'Africa/Niamey (WAT)',
  },
  {
    value: 'America/Whitehorse',
    code: 'MST',
    displayName: 'America/Whitehorse (MST)',
  },
  {
    value: 'Pacific/Noumea',
    code: 'NCT',
    displayName: 'Pacific/Noumea (NCT)',
  },
  {
    value: 'Asia/Tbilisi',
    code: 'GET',
    displayName: 'Asia/Tbilisi (GET)',
  },
  {
    value: 'America/Montreal',
    code: 'EST',
    displayName: 'America/Montreal (EST)',
  },
  {
    value: 'Asia/Makassar',
    code: 'WITA',
    displayName: 'Asia/Makassar (WITA)',
  },
  {
    value: 'America/Argentina/San_Juan',
    code: 'ART',
    displayName: 'America/Argentina/San_Juan (ART)',
  },
  { value: 'Hongkong', code: 'HKT', displayName: 'Hongkong (HKT)' },
  { value: 'UCT', code: 'UTC', displayName: 'UCT (UTC)' },
  {
    value: 'Asia/Nicosia',
    code: 'EET',
    displayName: 'Asia/Nicosia (EET)',
  },
  {
    value: 'America/Indiana/Winamac',
    code: 'EST',
    displayName: 'America/Indiana/Winamac (EST)',
  },
  {
    value: 'America/Argentina/ComodRivadavia',
    code: 'ART',
    displayName: 'America/Argentina/ComodRivadavia (ART)',
  },
  {
    value: 'America/Boa_Vista',
    code: 'AMT',
    displayName: 'America/Boa_Vista (AMT)',
  },
  {
    value: 'America/Grenada',
    code: 'AST',
    displayName: 'America/Grenada (AST)',
  },
  {
    value: 'Asia/Atyrau',
    code: 'Asia/Atyrau',
    displayName: 'Asia/Atyrau (Asia/Atyrau)',
  },
  {
    value: 'Australia/Darwin',
    code: 'ACST',
    displayName: 'Australia/Darwin (ACST)',
  },
  {
    value: 'Asia/Khandyga',
    code: 'YAKT',
    displayName: 'Asia/Khandyga (YAKT)',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    code: 'MYT',
    displayName: 'Asia/Kuala_Lumpur (MYT)',
  },
  {
    value: 'Asia/Famagusta',
    code: 'Asia/Famagusta',
    displayName: 'Asia/Famagusta (Asia/Famagusta)',
  },
  {
    value: 'Asia/Thimphu',
    code: 'BTT',
    displayName: 'Asia/Thimphu (BTT)',
  },
  {
    value: 'Asia/Rangoon',
    code: 'MMT',
    displayName: 'Asia/Rangoon (MMT)',
  },
  {
    value: 'Europe/Bratislava',
    code: 'CET',
    displayName: 'Europe/Bratislava (CET)',
  },
  {
    value: 'Asia/Calcutta',
    code: 'IST',
    displayName: 'Asia/Calcutta (IST)',
  },
  {
    value: 'America/Argentina/Tucuman',
    code: 'ART',
    displayName: 'America/Argentina/Tucuman (ART)',
  },
  { value: 'Asia/Kabul', code: 'AFT', displayName: 'Asia/Kabul (AFT)' },
  {
    value: 'Indian/Cocos',
    code: 'CCT',
    displayName: 'Indian/Cocos (CCT)',
  },
  { value: 'Japan', code: 'JST', displayName: 'Japan (JST)' },
  {
    value: 'Pacific/Tongatapu',
    code: 'TOT',
    displayName: 'Pacific/Tongatapu (TOT)',
  },
  {
    value: 'America/New_York',
    code: 'EST',
    displayName: 'America/New_York (EST)',
  },
  {
    value: 'Europe/Ulyanovsk',
    code: 'Europe/Ulyanovsk',
    displayName: 'Europe/Ulyanovsk (Europe/Ulyanovsk)',
  },
  { value: 'W-SU', code: 'MSK', displayName: 'W-SU (MSK)' },
  {
    value: 'America/Merida',
    code: 'CST',
    displayName: 'America/Merida (CST)',
  },
  { value: 'EET', code: 'EET', displayName: 'EET (EET)' },
  {
    value: 'America/Rosario',
    code: 'ART',
    displayName: 'America/Rosario (ART)',
  },
  {
    value: 'Canada/Saskatchewan',
    code: 'CST',
    displayName: 'Canada/Saskatchewan (CST)',
  },
  {
    value: 'America/St_Kitts',
    code: 'AST',
    displayName: 'America/St_Kitts (AST)',
  },
  {
    value: 'Arctic/Longyearbyen',
    code: 'CET',
    displayName: 'Arctic/Longyearbyen (CET)',
  },
  {
    value: 'America/Fort_Nelson',
    code: 'MST',
    displayName: 'America/Fort_Nelson (MST)',
  },
  {
    value: 'America/Caracas',
    code: 'VET',
    displayName: 'America/Caracas (VET)',
  },
  {
    value: 'America/Guadeloupe',
    code: 'AST',
    displayName: 'America/Guadeloupe (AST)',
  },
  { value: 'Asia/Hebron', code: 'EET', displayName: 'Asia/Hebron (EET)' },
  {
    value: 'Indian/Kerguelen',
    code: 'TFT',
    displayName: 'Indian/Kerguelen (TFT)',
  },
  {
    value: 'Africa/Monrovia',
    code: 'GMT',
    displayName: 'Africa/Monrovia (GMT)',
  },
  {
    value: 'Asia/Ust-Nera',
    code: 'VLAT',
    displayName: 'Asia/Ust-Nera (VLAT)',
  },
  { value: 'Egypt', code: 'EET', displayName: 'Egypt (EET)' },
  {
    value: 'Asia/Srednekolymsk',
    code: 'SRET',
    displayName: 'Asia/Srednekolymsk (SRET)',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    code: 'CST',
    displayName: 'America/North_Dakota/New_Salem (CST)',
  },
  {
    value: 'Asia/Anadyr',
    code: 'ANAT',
    displayName: 'Asia/Anadyr (ANAT)',
  },
  {
    value: 'Australia/Melbourne',
    code: 'AEDT',
    displayName: 'Australia/Melbourne (AEDT)',
  },
  {
    value: 'Asia/Irkutsk',
    code: 'IRKT',
    displayName: 'Asia/Irkutsk (IRKT)',
  },
  {
    value: 'America/Shiprock',
    code: 'MST',
    displayName: 'America/Shiprock (MST)',
  },
  {
    value: 'America/Winnipeg',
    code: 'CST',
    displayName: 'America/Winnipeg (CST)',
  },
  {
    value: 'Europe/Vatican',
    code: 'CET',
    displayName: 'Europe/Vatican (CET)',
  },
  { value: 'Asia/Amman', code: 'EET', displayName: 'Asia/Amman (EET)' },
  { value: 'Asia/Tokyo', code: 'JST', displayName: 'Asia/Tokyo (JST)' },
  {
    value: 'America/Toronto',
    code: 'EST',
    displayName: 'America/Toronto (EST)',
  },
  {
    value: 'Asia/Singapore',
    code: 'SGT',
    displayName: 'Asia/Singapore (SGT)',
  },
  {
    value: 'Australia/Lindeman',
    code: 'AEST',
    displayName: 'Australia/Lindeman (AEST)',
  },
  {
    value: 'America/Los_Angeles',
    code: 'PST',
    displayName: 'America/Los_Angeles (PST)',
  },
  {
    value: 'Pacific/Majuro',
    code: 'MHT',
    displayName: 'Pacific/Majuro (MHT)',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    code: 'ART',
    displayName: 'America/Argentina/Buenos_Aires (ART)',
  },
  {
    value: 'Europe/Nicosia',
    code: 'EET',
    displayName: 'Europe/Nicosia (EET)',
  },
  {
    value: 'Pacific/Guadalcanal',
    code: 'SBT',
    displayName: 'Pacific/Guadalcanal (SBT)',
  },
  {
    value: 'Europe/Athens',
    code: 'EET',
    displayName: 'Europe/Athens (EET)',
  },
  { value: 'US/Pacific', code: 'PST', displayName: 'US/Pacific (PST)' },
  {
    value: 'Europe/Monaco',
    code: 'CET',
    displayName: 'Europe/Monaco (CET)',
  },
];

/* Luxon old library version "1.28.1" < 2.4.0 does not support these timezones:
 - PST8PDT -> America/Los_Angeles
 - MST7MDT -> America/Denver
 - EST5EDT -> America/New_York
 - GMT0    -> Etc/GMT
 - CST6CDT -> America/Chicago
  So mapping these to similar canonical timezones.
 */