<template>
  <div class="add-bank-account-container">
    <BankAccountForPayout :add-bank-account="true" />
    <div class="buttons-container">
      <c-button variant="action" @click="onDismiss" :disabled="loading">
        {{ $t('global.action.dismiss') }}
      </c-button>
      <c-button variant="primary" @click="onAddBankAccount" :loading="loading">
        {{ $t('global.action.add') }}
      </c-button>
    </div>
  </div>
</template>

<script>

import BankAccountForPayout from "@/components/onboarding/BankAccountForPayout.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import paymentInstruments from "@/api/paymentInstruments";
export default {
  name: "AddBankAccount",
  components: {BankAccountForPayout},
  props: {
    onDismiss: {
      type: Function,
      required: true
    },
  },
  data: function() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      errors: state => state.bankAccountForPayouts.errors,
      name: state => state.bankAccountForPayouts.data.name.value,
      accountNumber: state => state.bankAccountForPayouts.data.accountNumber.value,
      accountType: state => state.bankAccountForPayouts.data.accountType.value,
      bankCode: state => state.bankAccountForPayouts.data.bankCode.value,
      sortCode: state => state.bankAccountForPayouts.data.sortCode.value,
      iban: state => state.bankAccountForPayouts.data.iban.value
    }),
    ...mapGetters('app', ['getMerchantId']),
    ...mapGetters('bankAccountForPayouts', {isBankAccountForPayoutsValid: 'isValid',
      bankTransferType: 'getBankTransferType'}),
  },
  methods: {
    ...mapActions('bankAccountForPayouts', {generateBankAccountForPayoutsErrors: 'validate'}),
    async onAddBankAccount() {
      await this.generateBankAccountForPayoutsErrors();
      if (this.isBankAccountForPayoutsValid) {
        this.loading = true;
        try{
          const response = await paymentInstruments.createMerchantPaymentInstrument(this.getMerchantId, {
            type: "bank_account",
            bank_account: this.getRequestBodyForAddingBankAccount()
          })
          this.loading = false;
          this.$emit("merchantPaymentInstrumentUpdated", response);
          this.$success(this.$t('bankAccountForPayouts.message.success'))
          this.onDismiss();
        }catch(error){
          this.$danger(this.$t('bankAccountForPayouts.message.failure', {error: error.source?.detail}),
              {dismissable: true, timeout: 1500})
          this.loading = false;
          this.onDismiss();
        }
      }
    },
    getRequestBodyForAddingBankAccount() {
      return {
        name: this.name,
        bank_code: this.bankCode,
        account_number: this.accountNumber,
        account_type: this.accountType,
        iban: this.iban,
        sort_code: this.sortCode,
        type: this.bankTransferType
      }
    }
  }
}
</script>

<style lang="scss">
.add-bank-account-container {
  padding: 24px 32px;

  .bap-container {
    max-width: 500px !important;
  }

  & .buttons-container {
    margin-top: 40px;
    display: flex;
    justify-content: end;
    align-items: center;

    gap: 24px;
    & .c-button__label {
      padding: 0;
    }

    & .c-button--primary {
      margin-bottom: 0;
      width: 140px;
    }

    & .c-button--action:disabled {
      color: $neutral_300;
    }
  }
}
</style>