<template>
  <div class="address-container">
    <slot name="header"></slot>

    <SelectField
      :label="$t('businessAddress.country.label')"
      :searchable="true"
      :clearable="true"
      :value="getters.getAddressCountry().value"
      :options="getCountries()"
      :on-update="changeCountry"
      :error="getters.getErrors.addressCountry"
      field-classname="country-field"
      select-classname="country-select"
    />

    <InputField
      :label="$t('businessAddress.addressLine1.label')"
      :value="getters.getAddressLine1().value"
      :set-value="setters.setAddressLine1"
      id="address-line-1"
      :error="getters.getErrors.addressLine1"
      :max-length="getMax(getters.getAddressLine1().apiPath)"
      class="fs-mask"
    />

    <InputField
      :label="$t('businessAddress.addressLine2.label')"
      :value="getters.getAddressLine2().value"
      id="address-line-2"
      :set-value="setters.setAddressLine2"
      :error="getters.getErrors.addressLine2"
      :max-length="getMax(getters.getAddressLine2().apiPath)"
      class="fs-mask"
    />

    <div class="city-state-zip">
      <InputField
        :label="$t('businessAddress.city.label')"
        :value="getters.getAddressCity().value"
        id="city"
        :set-value="setters.setAddressCity"
        :error="getters.getErrors.addressCity"
        :max-length="getMax(getters.getAddressCity().apiPath)"
      />

      <InputField
        v-if="stateOptions.length === 0"
        :label="$t('businessAddress.state.label')"
        id="state"
        :value="getters.getAddressState().value"
        :set-value="(event) => setters.setAddressState(event.target.value)"
        :error="getters.getErrors.addressState"
        :max-length="getMax(getters.getAddressState().apiPath)"
      />

      <SelectField
        v-else
        :label="$t('businessAddress.state.label')"
        :searchable="true"
        :clearable="false"
        :options="stateOptions"
        :on-update="setters.setAddressState"
        :error="getters.getErrors.addressState"
        :value="getters.getAddressState().value"
        select-classname="state-select"
      />

      <InputField
        :label="$t('businessAddress.zip.label')"
        :value="getters.getAddressZip().value"
        :set-value="setters.setAddressZip"
        :error="getters.getErrors.addressZip"
        id="zip-code"
        :max-length="10"
        class="fs-mask"
      />
    </div>
  </div>
</template>

<script>
import {CountryCodeListSortedByName} from "@/data/country-code-list";
import StateCodeList from "@/data/state-code-list";
import validations from "@/app/validators/api/openapi.ts"
import InputField from "@/components/common/InputField";
import SelectField from "@/components/common/SelectField";

export default {
  name: 'Address',
  components: {SelectField, InputField},
  props: {
    getters: {
      type: Object,
      required: true
    },
    setters: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    stateOptions: []
  }),
  mounted() {
    if (this.getters.getAddressCountry().value) {
      this.onCountryChange(this.getters.getAddressCountry().value)
    }
  },
  methods: {
    getMax(key) {
      return validations[this.getters.getApi][key].length.maximum;
    },
    getCountries: () => CountryCodeListSortedByName,
    changeCountry: function (countryCode) {
      this.onCountryChange(countryCode)
      this.setters.setAddressState(null);
      this.setters.setTaxId(null);
      // taxid also needs to be reset
    },
    onCountryChange: function (countryCode) {
      this.setters.setAddressCountry(countryCode)
      if (StateCodeList[countryCode]) {
        const statesList = StateCodeList[countryCode];
        this.stateOptions = Object.keys(statesList).map(stateName => ({
          displayText: stateName,
          value: statesList[stateName]
        }));
      } else {
        this.stateOptions = [];
      }
    }
  }
}
</script>

<style lang="scss">
.address-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;

  & .subtitle {
    font-size: 12px;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }

  & .city-state-zip {
    display: flex;
    align-self: normal;
    justify-content: space-between;
    gap: 20px;

    @media screen and (min-width: 801px) {
      flex-direction: row;
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    & .field {
      flex: 1;
    }
  }

  & .country-field {
    width: 300px;
  }

  & .field {
    & .country-select {
      width: 300px;
      display: block;
    }
  }
}


</style>
