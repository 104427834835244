<template>
  <div class="search-bar">
    <SiteStatusBanner v-if="getIsSandbox" />
    <div class="search-bar-container" :class="{'search-bar-sidebar-collapse': sidebarNavCollapsed}">
      <div class="search"></div>
      <div class="right">
        <div class="time-zone">
          {{ $t('global.time.timezone', {displayName: getTimezoneDisplayName}) }}
        </div>
        <div class="info">
          <c-dropdown>
            <img
              slot="trigger" alt="info icon"
              src="@/app/assets/images/question.svg"
              placement="bottom-end"
              :id="getElementId('info_icon')"
            />
            <c-dropdown-item
              option="helpAndDocumentation"
              @click="() => openUrl('https://www.chargebee.com/docs/2.0/cb_payments.html')"
              :id="getElementId('help_doc')"
            >
              <img alt="documentation icon" src="@/app/assets/images/documentation.svg" />
              <span class="span">Help & Documentation</span>
            </c-dropdown-item>
            <c-dropdown-item
              option="support"
              @click="() => openUrl('mailto:support@chargebee.com')"
              :id="getElementId('support')"
            >
              <img alt="support icon" src="@/app/assets/images/support.svg" />
              <span class="span">Support</span>
            </c-dropdown-item>
            <c-dropdown-item
              option="legalAndPrivacyPolicy"
              @click="() => openUrl('https://www.chargebee.com/payments-tou/')"
              :id="getElementId('legal_privacy_policy')"
            >
              <img alt="legal icon" src="@/app/assets/images/legal.svg" />
              <span class="span">Legal & Privacy Policy</span>
            </c-dropdown-item>
          </c-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {PendoPrefixes} from "@/app/utils/common/constants";
import {openUrlInNewTab} from "@/app/utils/common/functions/url-helper";
import {getTimezoneName} from "@/app/utils/common/functions/dateUtils";
import SiteStatusBanner from "@/components/common/SiteStatusBanner.vue";
import {mapGetters} from "vuex";
import { TimeZoneList } from "@/app/utils/common/helpers/TimeZoneList";

export default {
  name: 'SearchBar',
  components: {SiteStatusBanner},
  props: {
    sidebarNavCollapsed: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('app', ['getIsSandbox']),
    getTimezoneDisplayName() {
      return TimeZoneList.find(x => x.value === getTimezoneName()).displayName
    },
  },
  methods: {
    openUrl: function(url) {
      openUrlInNewTab(url)
    },
    getElementId(id) {
      return PendoPrefixes.CBpayHeader + id;
    }
  },
}
</script>
<style lang="scss">
.search-bar {
  position: sticky;
  z-index: 100;
  top: 0;
  width: 100%;
  transition: all 0.2s ease-in;

  & .search-bar-container {
    height: 60px;
    position: sticky;
    background: white;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #E6EAF0;
    left: 236px;
    width: calc(100% - 236px);
    transition: all 0.2s ease-in;

    &.search-bar-sidebar-collapse {
      left: 86px;
      width: calc(100% - 86px);
    }

    & .right {
      display: flex;
      gap: 20px;

      & .info {
        cursor: pointer;

        & .c-dropdown__content {
          width: 250px;

          & .c-dropdown__item {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 9px 0 9px 16px;

            & .span{
              font-size: 14px;
            }
          }
        }
      }
    }

    & .time-zone {
      background: $neutral_50;
      border: 1px solid $neutral_100;
      border-radius: 4px;
      padding: 4px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
</style>